/* eslint-disable no-restricted-syntax */
import { IAPIResponseStatus, IOptionValueGrouped, IOptionValue } from '../interfaces';
import { getRequest } from '../utils/api-request';

const getRoles = async (): Promise<
    { apiStatus: IAPIResponseStatus; roles: IOptionValue[] } | undefined
> => {
    try {
        const rolesRes = await getRequest(`users/roles`);
        if (rolesRes?.apiStatus === 'SUCCESS') {
            if (rolesRes.response.length > 0) {
                const roles: IOptionValue[] = [];
                for (const dep of rolesRes.response) {
                    for (const role of dep.role) {
                        roles.push({
                            label: role.name,
                            value: role.id,
                        });
                    }
                }
                return { apiStatus: 'SUCCESS', roles };
            }
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getRolesForDropDown = async (): Promise<
    { apiStatus: IAPIResponseStatus; roles: IOptionValueGrouped[] } | undefined
> => {
    try {
        const rolesRes = await getRequest(`users/roles`);
        if (rolesRes?.apiStatus === 'SUCCESS') {
            if (rolesRes.response.length > 0) {
                const roles: IOptionValueGrouped[] = [];
                for (const dep of rolesRes.response) {
                    const options: IOptionValue[] = [];
                    for (const role of dep.role) {
                        options.push({
                            label: role.name,
                            value: role.id,
                        });
                    }
                    roles.push({
                        label: dep.name,
                        options,
                    });
                }
                return { apiStatus: 'SUCCESS', roles };
            }
        }
        return undefined;
    } catch (err) {
        // console.log(err)
        return undefined;
    }
};

export { getRoles, getRolesForDropDown };
