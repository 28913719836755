import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { IOwnerChange } from '../../interfaces';

interface Props {
    setAttributeName: (option: any) => void;
    setOperatorName: (option: any) => void;
    setFilterValue: (option: any) => void;
    setDateValue: (date: Date | null) => void;
    setStartDateValue: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
    handleSearch: () => void;
    handleReset: () => void;
    setOpenModal: (value: boolean) => void;
    setModal: (value: boolean) => void;
    selectedAction: { value: string; label: string };
    setSelectedAction: (option: any) => void;
    SelectedDocuments: IOwnerChange[];
    setOpenWithdrawModal: (value: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
const AdminDocTableFilters: React.FC<Props> = ({
    setAttributeName,
    setOperatorName,
    setFilterValue,
    setDateValue,
    setStartDateValue,
    setEndDate,
    handleSearch,
    handleReset,
    setOpenModal,
    setModal,
    selectedAction,
    setSelectedAction,
    SelectedDocuments,
    setOpenWithdrawModal,
}) => {
    const attributeOption = [
        { value: 'any', label: 'Any' },
        { value: 'documentNumber', label: 'Document Number' },
        { value: 'documentTypeName', label: 'Document Type' },
        { value: 'title', label: 'Title' },
        // { value: 'description', label: 'Description' },
        { value: 'owner', label: 'Owner' },
        { value: 'publishedOn', label: 'Published On' },
    ];

    const operatorOption = [
        [
            { value: 'contain', label: 'Contains' },
            { value: 'notContain', label: 'Does Not Contain' },
            { value: 'eq', label: 'Is Equal To' },
            { value: 'neq', label: 'Is Not Equal To' },
            { value: 'startsWith', label: 'Starts With' },
            { value: 'notStartWith', label: 'Does Not Start With' },
        ],
        [
            { value: 'dateeq', label: 'Is Equal To' },
            { value: 'isBefore', label: 'Is Before' },
            { value: 'isAfter', label: 'Is After' },
            { value: 'isBetween', label: 'Is Between' },
            { value: 'isToday', label: 'Is Today' },
            { value: 'isWeek', label: 'Last 7 Days' },
            { value: 'isMonth', label: 'Last 30 Days' },
            { value: 'isTwoMonth', label: 'Last 60 Days' },
            { value: 'isSixMonth', label: 'Last 6 Months' },
            { value: 'isTwelveMonths', label: 'Last 12 Months' },
        ],
    ];

    const actionOption = [
        { value: 'changeOwner', label: 'Change Owner' },
        { value: 'obsolete', label: 'Obsolete' },
        // { value: 'withdraw', label: 'Withdraw' }
    ];
    const [operatorType, setOperatorType] = useState(operatorOption[0]);
    const [dateOption, setDateOption] = useState(false);
    const [value, setValue] = useState('');
    const [isBetween, setIsBetween] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
    const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
    const [operator, setOperator] = useState(null);
    const [noValue, setNoValue] = useState(false);
    const [attribute, setAttribute] = useState<any>(null);
    const [showOperator, setShowOperator] = useState(true);
    useEffect(() => {
        if (attribute && attribute.value === 'publishedOn') {
            setDateValue(new Date());
            setStartDate(new Date());
            setNoValue(false);
        } else if (
            attribute &&
            (attribute.value === 'any' || attribute.value === 'documentTypeName')
        ) {
            setShowOperator(false);
        }
    }, [attribute]);
    const handleChange = (option: any) => {
        if (option && option.value === 'publishedOn') {
            setOperatorType(operatorOption[1]);
            setDateOption(true);
            setShowOperator(true);
            setIsBetween(false);
            setNoValue(false);
            setDateValue(null);
            setFilterValue(null);
        } else if (option && (option.value !== 'any' || option.value !== 'documentTypeName')) {
            setShowOperator(true);
            setOperatorType(operatorOption[0]);
            setDateOption(false);
        } else {
            setOperatorType(operatorOption[0]);
            setDateOption(false);
        }
        setOperator(null);
        setAttribute(option);
        if (option.value === 'owner') {
            setAttributeName('owner.name');
        } else {
            setAttributeName(option && option.value);
        }
        setFilterValue(null);
        setDateValue(null);
        setStartDateValue(null);
        setEndDate(null);
        setOperatorName(null);
        setValue('');
    };
    const dateOperatorArray = [
        'isToday',
        'isWeek',
        'isMonth',
        'isTwoMonth',
        'isSixMonth',
        'isTwelveMonths',
    ];
    const handleOperatorChange = (option: any) => {
        if (option && option.value === 'isBetween') {
            setIsBetween(true);
            setNoValue(false);
            setDateValue(null);
            setFilterValue(null);
        } else if (option && dateOperatorArray.includes(option.value)) {
            setNoValue(true);
            setFilterValue(null);
            setDateValue(null);
        } else {
            setIsBetween(false);
            setNoValue(false);
            if (attribute && attribute.value === 'publishedOn') {
                setDateValue(new Date());
                setStartDate(new Date());
            }
        }
        setOperator(option);
        setOperatorName(option && option.value);
        setStartDateValue(null);
        setEndDate(null);
        setFilterStartDate(null);
        setFilterEndDate(null);
    };

    const handleValueChange = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        setFilterValue(e.currentTarget.value);
    };

    const handleDateChange = (date: Date | null) => {
        setStartDate(date);
        setDateValue(date);
    };

    const handleStartDateChange = (date: Date | null) => {
        setFilterStartDate(date);
        setStartDateValue(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setFilterEndDate(date);
        setEndDate(date);
    };

    const resetHandler = () => {
        setOperator(null);
        setAttribute(null);
        setAttributeName(null);
        setFilterValue(null);
        setDateValue(null);
        setStartDateValue(null);
        setEndDate(null);
        setOperatorName(null);
        setValue('');
        setNoValue(false);
        setShowOperator(true);
        setIsBetween(false);
        setStartDate(null);
        setDateOption(false);
        handleReset();
        setSelectedAction(null);
    };

    const actionHandler = (option: any) => {
        setSelectedAction(option);
        if (option.value === 'changeOwner') {
            setOpenModal(true);
        } else if (option.value === 'withdraw') {
            setOpenWithdrawModal(true);
        } else {
            setModal(true);
        }
    };
    return (
        <div className="table-filters">
            <div className="filter action-filter">
                <label htmlFor="ActionFilter">Action</label>
                <Select
                    name="ActionFilter"
                    id="ActionFilter"
                    isMulti={false}
                    className="react-select"
                    classNamePrefix="select"
                    options={actionOption}
                    onChange={actionHandler}
                    value={selectedAction}
                    isDisabled={!(SelectedDocuments.length > 0)}
                />
            </div>
            <div className="filter filter-right-margin">
                <label htmlFor="AttributeFilter">Attribute</label>
                <Select
                    name="AttributeFilter"
                    id="AttributeFilter"
                    isMulti={false}
                    className="react-select"
                    classNamePrefix="select"
                    options={attributeOption}
                    onChange={handleChange}
                    value={attribute}
                />
            </div>
            {showOperator && (
                <div className="filter filter-right-margin">
                    <label htmlFor="operatorFilter">Operator</label>
                    <Select
                        name="operatorFilter"
                        id="operatorFilter"
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={operatorType}
                        onChange={handleOperatorChange}
                        value={operator}
                    />
                </div>
            )}
            {!dateOption && (
                <div className="filter filter-right-margin">
                    <label htmlFor="filterValue">Value</label>
                    <input
                        name="filterValue"
                        id="filterValue"
                        value={value || ''}
                        onChange={handleValueChange}
                        placeholder="Enter Value"
                        className="input-value"
                    />
                </div>
            )}
            {dateOption && !isBetween && !noValue && (
                <div className="filter filter-right-margin">
                    <label htmlFor="filterDateValue">Value</label>
                    <DatePicker
                        name="filterDateValue"
                        id="filterDateValue"
                        selected={startDate}
                        onChange={(date: Date | null) => handleDateChange(date)}
                        className="input-value"
                    />
                </div>
            )}
            {dateOption && isBetween && !noValue && (
                <>
                    <div className="filter">
                        <label htmlFor="filterStartDateValue">Value</label>
                        <DatePicker
                            name="filterStartDateValue"
                            id="filterStartDateValue"
                            selected={filterStartDate}
                            onChange={(date: Date | null) => handleStartDateChange(date)}
                            className="input-value"
                            placeholderText="Start Date"
                        />
                    </div>
                    <div className="filter-dash-margin">&nbsp;-&nbsp;</div>
                    <div className="filter filter-margin filter-right-margin">
                        <DatePicker
                            name="filterEndDateValue"
                            id="filterEndDateValue"
                            selected={filterEndDate}
                            onChange={(date: Date | null) => handleEndDateChange(date)}
                            className="input-value"
                            placeholderText="End Date"
                        />
                    </div>
                </>
            )}
            <div className="search-button filter-right-margin">
                <button type="button" onClick={handleSearch}>
                    Search
                </button>
            </div>
            <div className="search-button">
                <button type="button" onClick={resetHandler}>
                    Reset
                </button>
            </div>
        </div>
    );
};

export default AdminDocTableFilters;
