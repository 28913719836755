/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    getAllCount,
    getGroupApprovals
} from '../API/dashboard';
import {
    myApprovalsDefault,
    myReviewsDefault,
    myGroupApprovalsDefault,
} from '../components/filters/filter-config';
import CONSTANTS from '../constants';
import { IPaginationData } from '../interfaces';
import { IDashboardTabs } from '../interfaces/dashboard';
import { useQueryParams } from './query-params-provider';
import { useAuthDataContext } from './user-auth-provider';

interface IDashboardContext {
    activeTab: IDashboardTabs;
    documentsCount: {
        myApprovals: number;
        myDocuments: number;
        myReviews: number;
        myFavorites: number;
        latestDocuments: number;
        myGroupApprovals: number;
    };
    setActiveTab: React.Dispatch<React.SetStateAction<IDashboardTabs>>;
    shouldGroupApprovalsRender: () => Promise<boolean>;
}

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

const dashboardContext = createContext<IDashboardContext>({
    activeTab: 'MY_LATEST',
    documentsCount: {
        myApprovals: 0,
        myDocuments: 0,
        myFavorites: 0,
        myReviews: 0,
        latestDocuments: 0,
        myGroupApprovals: 0,
    },
    setActiveTab: () => {},
    shouldGroupApprovalsRender: async () => false,
});

const DashboardProvider: React.FC = (props: unknown) => {
    const { checkParamExist, getQueryParam, queryParams: params } = useQueryParams();
    const { user } = useAuthDataContext();
    const { search } = useLocation();
    const [activeTab, setActiveTab] = useState<IDashboardTabs>('' as IDashboardTabs);
    const [myApprovalCount, setMyApprovalCount] = useState(0);
    const [myGroupApprovalCount, setMyGroupApprovalCount] = useState(0);
    const [myDocumentsCount, setMyDocumentsCount] = useState(0);
    const [myFavoriteCount, setMyFavoriteCount] = useState(0);
    const [latestDocumentsCount, setLatestDocumentsCount] = useState(0);
    const [myReviewsCount, setMyReviewsCount] = useState(0);
    const [Grouprender, setGrouprender] = useState(false);

    const fetchCountOfDocuments = async () => {

        const results = getAllCount(
            {
                ...myApprovalsDefault,
            }
        );

        Promise.all([
            results
        ]).then((values) => {
            if (values[0]?.apiStatus === 'SUCCESS') {
                setMyDocumentsCount(values[0].MyDocsCount);
                setMyApprovalCount(values[0].MyApprovalCount);
                setMyReviewsCount(values[0].MyReviewCount);
                setMyFavoriteCount(values[0].MyFavoriteCount);
                setLatestDocumentsCount(values[0].MyLatestCount);
                setMyGroupApprovalCount(values[0].GroupApprovalcount);
                setGrouprender(values[0].render);
            }
            if (!search.includes('type')) {
                if (values[0]?.apiStatus === 'SUCCESS' && values[0].MyLatestCount > 0) {
                    setActiveTab('MY_LATEST');
                } else {
                    setActiveTab('MY_DOCUMENTS');
                }
            }
        });
         
    };

    const shouldGroupApprovalsRender = async () => {
        const res = await getGroupApprovals(
            {
                ...myGroupApprovalsDefault,
            },
            {
                sortBy: 'documentTitle',
                sortOrder: 'desc',
            },
            DEFAULT_PAGINATION_DATA.pageSize,
            DEFAULT_PAGINATION_DATA.pageIndex * DEFAULT_PAGINATION_DATA.pageSize,
            true
        );
        if (res?.apiStatus === 'SUCCESS') {
            return res.render;
        }
        return false;
    };

    useEffect(() => {
        if (checkParamExist('type')) {
            const tab = getQueryParam('type') as IDashboardTabs;
            setActiveTab(tab);
        }
    }, [params]);

    useEffect(() => {
        // if(activeTab==='' as IDashboardTabs){
        //     setActiveTab('MY_LATEST');
        // }else{
        //     setActiveTab(activeTab);
        // }
        fetchCountOfDocuments();
    }, []);

    return (
        <dashboardContext.Provider
            value={{
                activeTab,
                documentsCount: {
                    myApprovals: myApprovalCount,
                    myDocuments: myDocumentsCount,
                    myFavorites: myFavoriteCount,
                    myReviews: myReviewsCount,
                    latestDocuments: latestDocumentsCount,
                    myGroupApprovals: myGroupApprovalCount,
                },
                setActiveTab,
                shouldGroupApprovalsRender,
            }}
            {...props}
        />
    );
};

export const useDashboardContext = (): IDashboardContext => useContext(dashboardContext);

export default DashboardProvider;
