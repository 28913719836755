/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Column, useTable, usePagination } from 'react-table';
import {
    // DeleteSvg,
    EditSvg,
} from '../../assets/images';
import AddRoleModal from './GroupModal';
import CONSTANTS from '../../constants';
import { deleteGroup } from '../../API/groups';
import Pagination from '../pagination/Pagination';
import { IPaginationData } from '../../interfaces';
import { useRoleContext } from '../../contexts/group-provider';
import { NoDocumentsFound } from '../General';

interface Props {
    data: { id: number; name: string; alias: string }[];
    pagination: IPaginationData;
    loadingState: any;
    fetchGroups: (pageIndex: number, pageSize: number) => void;
}
const DMSGroupsTable: React.FC<Props> = ({ data, pagination, loadingState, fetchGroups }) => {
    const [showModal, setShowModal] = useState(false);
    const { setEditInfo } = useRoleContext();

    const deleteHandler = async (obj: {
        id: number;
        name: string;
        alias: { primary_email: string }[];
    }) => {
        const { id } = obj;
        const response = await deleteGroup(id);
        if (response?.apiStatus === 'SUCCESS') {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            fetchGroups(pagination.pageIndex, pagination.pageSize);
            toast.success('Email group has been removed successfully!');
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    const editHandler = async (obj: { id: number; name: string; alias: string }) => {
        const { id, name, alias } = obj;
        const arr: any = alias.split(',').map((email: string) => ({ label: email, value: email }));
        setShowModal(true);
        setEditInfo(name, arr, id);
    };

    const EditGroup = (row: any) => {
        const { original } = row;
        return (
            // eslint-disable-next-line no-console
            <div className="btn">
                <EditSvg className="edit-icon" onClick={() => editHandler(original)} />
            </div>
        );
    };
    // const DeleteGroup = (row: any) => {
    //     const { original } = row;
    //     return (
    //         <div className="btn">
    //             <DeleteSvg className="delete" onClick={() => deleteHandler(original)} />
    //         </div>
    //     );
    // };

    const AliasList = ({ value }: { value: string }) => {
        const arr: string[] = value.split(',');
        return (
            <>
                {arr?.map((alias: string) => (
                    <div className="alias-email">{alias}</div>
                ))}
            </>
        );
    };

    const ROLES_COLUMN: Column[] = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Alias Email',
            accessor: 'alias',
            Cell: ({ value }) => <AliasList value={value} />,
        },
        {
            Header: 'Edit',
            accessor: 'id',
            Cell: ({ row }) => <EditGroup {...row} />,
        },
        // {
        //     Header: 'Delete',
        //     accessor: 'delete',
        //     Cell: ({ row }) => <DeleteGroup {...row} />,
        // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => ROLES_COLUMN, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            loadingState,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
        },
        usePagination,
    );

    useEffect(() => {
        fetchGroups(pageIndex, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, showModal]);
    return (
        <div>
            <div className="roles-table">
                <div className="table-section">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                        <Pagination
                            nextPage={nextPage}
                            canNextPage={canNextPage}
                            canPreviousPage={canPreviousPage}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            pageSize={pageSize}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                        />
                    )}
                    {page.length === 0 && <NoDocumentsFound heading="NO DMS GROUP" />}
                </div>
            </div>
            {showModal && (
                <AddRoleModal isModalOpen={showModal} onModalClose={setShowModal} isEdit />
            )}
        </div>
    );
};

export default DMSGroupsTable;
