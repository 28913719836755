/* eslint-disable @typescript-eslint/no-unused-vars */
import CONSTANTS from '../constants';
import { IAPIResponseStatus } from '../interfaces';
import { IComment, IDocumentActionTypes } from '../interfaces/document';
import { getUserFullName } from '../utils';
import { deleteRequest, getRequest, postRequest, putRequest } from '../utils/api-request';
import { convertEpochToDate } from '../utils/date';

// eslint-disable-next-line max-lines-per-function
const getComments = async (
    documentVersionId: number,
    allowReviseByAnyone: boolean,
    limit = 10,
    offset = 0,
): Promise<{ apiStatus: IAPIResponseStatus; comments: IComment[] } | undefined> => {
    try {
        const res = await getRequest(`documents/comment?documentVersionId=${documentVersionId}&allowReviseByAnyone=${allowReviseByAnyone}`);
        const comments: IComment[] = [];
        if (res?.apiStatus === 'SUCCESS') {
            const commentsList = res.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const comment of commentsList) {
                let commentStr = comment.data;
                let commentType: IDocumentActionTypes = '';
                if (comment.data.startsWith(CONSTANTS.RANDOM_COMMENT_STRINGS.APPROVE)) {
                    commentStr = comment.data.replace(CONSTANTS.RANDOM_COMMENT_STRINGS.APPROVE, '');
                    commentType = 'APPROVE';
                }
                if (comment.data.startsWith(CONSTANTS.RANDOM_COMMENT_STRINGS.REJECT)) {
                    commentStr = comment.data.replace(CONSTANTS.RANDOM_COMMENT_STRINGS.REJECT, '');
                    commentType = 'REJECT';
                }
                comments.push({
                    id: comment.id,
                    commenterId: comment.commenterId,
                    comment: commentStr,
                    commentType,
                    commenterName: getUserFullName(
                        comment.commenter.firstName,
                        comment.commenter.lastName,
                    ),
                    commentEdited: comment.createdAt !== comment.updatedAt,
                    updatedDate: new Date(convertEpochToDate(comment.updatedAt)),
                });
            }
        }

        return { apiStatus: 'SUCCESS', comments };
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};
const createComment = async (
    documentVersionId: number,
    comment: string,
    allowReviseByAnyone: boolean,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await postRequest(`documents/comment`, {
            data: comment,
            documentVersionId,
            allowReviseByAnyone
        });
        if (res?.apiStatus) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const deleteComment = async (
    commentId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`documents/comment?commentId=${commentId}`);
        if (res) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const updateComment = async (
    commentId: number,
    comment: string,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await putRequest(`documents/comment`, {
            commentId,
            data: comment,
        });
        if (res) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export { getComments, createComment, deleteComment, updateComment };
