/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, usePagination, useTable, useSortBy, Row } from 'react-table';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { SortIcon } from '../../assets/images';
import { IDocumentActivity } from '../../interfaces/document';
import CONSTANTS from '../../constants';
import Pagination from '../pagination/Pagination';
import './ActivitySection.scss';
import { fetchDocumentActivity } from '../../API/document';
import { IPaginationData } from '../../interfaces';
import ViewDifferenceModal from './ViewDifferenceModal';
import { IDocumentTypeInfo } from '../../interfaces/document-type';

interface Props {
    documentId: number;
    docTypeInfo: IDocumentTypeInfo;
}

const ChecklistColumn = ({
    row,
    rowSelected,
    setRowsSelected,
    setSelectedDocRows
}: {
    row: Row<IDocumentActivity>;
    rowSelected: number[];
    setRowsSelected: any;
    setSelectedDocRows: any;
}) => {
    const {
        original: { activityId },
    } = row;
    const isSelected = rowSelected.includes(activityId);

    const handleChecklistBoxes = () => {
        setRowsSelected((prevRowsSelected: number[]) => {
            if (prevRowsSelected.includes(activityId)) {
                // Remove activityId from selected rows
                setSelectedDocRows((prevSelectedDocRows: IDocumentActivity[]) => 
                    prevSelectedDocRows.filter(doc => doc.activityId !== activityId)
                );
                return prevRowsSelected.filter((id) => id !== activityId);
            }
            if (prevRowsSelected.length < 2) {
                setSelectedDocRows((prevSelectedDocRows: IDocumentActivity[]) => 
                    [...prevSelectedDocRows, row.original]
                );
                return [...prevRowsSelected, activityId];
            }
            return prevRowsSelected;
        });
    };

    return (
        <input
            type="checkbox"
            checked={isSelected}
            onChange={handleChecklistBoxes}
            disabled={!isSelected && rowSelected.length >= 2}
        />
    );
};

const COLUMNS: Column<IDocumentActivity>[] = [
    {
        Header: 'Select',
        id: 'selection',
        Cell: ({ row, rowSelected, setRowsSelected, setSelectedDocRows }: { row: Row<IDocumentActivity>; rowSelected: number[] ; setRowsSelected: any; setSelectedDocRows: IDocumentActivity[]}) => (
            <ChecklistColumn row={row} rowSelected={rowSelected} setRowsSelected={setRowsSelected} setSelectedDocRows={setSelectedDocRows} />
        ),
        maxWidth: 50,
    },
    {
        Header: 'Revision',
        accessor: 'currentVersion',
    },
    {
        Header: 'Action',
        accessor: 'action',
    },
    {
        Header: 'Actor',
        accessor: 'actorName',
    },
    {
        Header: 'Date',
        accessor: 'actionTime',
    },
];

const DCO_COLUMNS: Column<IDocumentActivity>[] = [
    {
        Header: 'Select',
        id: 'selection',
        Cell: ({ row, rowSelected, setRowsSelected, setSelectedDocRows }: { row: Row<IDocumentActivity>; rowSelected: number[] ; setRowsSelected: any; setSelectedDocRows: IDocumentActivity[]}) => (
            <ChecklistColumn row={row} rowSelected={rowSelected} setRowsSelected={setRowsSelected} setSelectedDocRows={setSelectedDocRows} />
        ),
        maxWidth: 50,
    },
    {
        Header: 'Action',
        accessor: 'action',
    },
    {
        Header: 'Actor',
        accessor: 'actorName',
    },
    {
        Header: 'Date',
        accessor: 'actionTime',
    },
];

const ActivitySection: React.FC<Props> = ({ documentId, docTypeInfo }) => {
    const SkeletonFields = (): JSX.Element => (
        <div className="full">
            <div>
                <Skeleton width="100%" />
            </div>
        </div>
    );

    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
        totalCount: 0,
    };

    const [rowsSelected, setRowsSelected] = useState<number[]>([]);
    const [selectedDocRows, setSelectedDocRows] = useState<IDocumentActivity[]>([]);
    const [documentActivity, setDocumentActivity] = useState<IDocumentActivity[]>([]);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [loader, setLoader] = useState(true);
    const [openViewDiffModal, setOpenViewDiffModal] = useState(false);

    const getDocumentActivity = async () => {
        const queryParams = {
            limit: pageSize.toString(),
            offset: (pageIndex * pageSize).toString(),
            docId: documentId.toString(),
        };
        const res = await fetchDocumentActivity(queryParams);
        if (res?.apiStatus === 'SUCCESS') {
            setLoader(false);
            setDocumentActivity(res?.data ? res.data : []);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: res.totalActivityCount,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    // const data = useMemo(() => documentActivity, [documentActivity]);
    const columns = useMemo(() => docTypeInfo?.isDCO ? DCO_COLUMNS : COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        gotoPage,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data: documentActivity as IDocumentActivity[],
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            rowSelected: rowsSelected,
            setRowsSelected,
            setSelectedDocRows
        },
        usePagination,
    );

    useEffect(() => {
        getDocumentActivity();
    }, [pageIndex, pageSize]);

    return (
        <div className="document-activity">
            {loader && (
                <div className="loading-state">
                    <SkeletonFields />
                    <SkeletonFields />
                    <SkeletonFields />
                </div>
            )}

            {!loader && documentActivity.length ? (
                <div className="table-section">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            style={{ textAlign: 'left' }}
                                        >
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    }`}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, idx) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                                key={idx}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <button
                        type="button"
                        id="General"
                        className="ViewDiffbutton"
                        disabled={rowsSelected.length !== 2}
                        onClick={() => setOpenViewDiffModal(true)}
                    >
                        <span className="name">View Difference</span>
                    </button>
                    <ViewDifferenceModal
                        show={openViewDiffModal}
                        handleClose={() => setOpenViewDiffModal(false)}
                        documentActivity={selectedDocRows}
                        rowsSelected={rowsSelected}
                        docTypeInfo={docTypeInfo}
                    />
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ActivitySection;
