/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import './ReactTagsInput.scss';

interface Props {
    tags: string[];
    onChange: (tags: string[]) => void;
    disabled: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ReactTagsInput: React.FC<Props> = ({ tags, onChange, disabled }) => {
    const removeTagData = (indexToRemove: number) => {
        onChange([...tags.filter((_, index) => index !== indexToRemove)]);
    };

    const addTagData = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.value !== '' && !tags.includes(target.value)) {
            onChange([...tags, target.value]);
            target.value = '';
        }
    };

    return (
        <div className={`react-tag-input ${disabled ? 'disabled' : ''}`}>
            <ul className="tags">
                {tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        {!disabled && (
                            <span className="tag-close-icon" onClick={() => removeTagData(index)}>
                                <svg
                                    height="14"
                                    width="14"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    className="css-tj5bde-Svg"
                                >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                                </svg>
                            </span>
                        )}
                    </li>
                ))}
                {!disabled && (
                    <li className="tag input">
                        <input
                            className="tag-input"
                            type="text"
                            onKeyUp={(event) => (event.key === 'Enter' ? addTagData(event) : null)}
                            placeholder="Press enter to add a tag"
                        />
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ReactTagsInput;
