/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';

import './SearchBar.scss';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { SettingIconSvg, SearchIconSvg } from '../../assets/images';
import { useSearch } from '../../contexts/search-provider';
import STATIC_CONTENT from '../../constants/StaticContent';
import PLACEHOLDERS from './advanced-search-placeholder';
import Button from '../button/Button';
import useRefPosition from '../../hooks/use-position';
import Toggle from '../toggle/Toggle';
import CONSTANTS from '../../constants';
import { getDynamicValues } from '../../API/fields';
import { IOptionValue } from '../../interfaces';
import { getPublishedDocumentTypes } from '../../API/document-type';
// import { SEARCH_STATE_DATA } from '../filters/filter-config';

const { DOCUMENT_SEARCH } = STATIC_CONTENT;

// const DEFAULT_DOC_TYPE: IDocTypeSearch = { label: 'All Types', value: '', fields: [] };

interface Props {
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar: React.FC<Props> = ({ setShowSearch }: Props) => {
    const {
        advancedFilters,
        searchString,
        searchInAttachment,
        advancedSearchChange,
        onSearch,
        setSearchString,
        onClear,
        setSearchInAttachment,
        selectedAuthor,
        setselectedAuthor,
        selectedState,
        setSelectedState,
        toList,
        setToList,
        ccList,
        setCcList,
        docTypeSelect,
        setdocTypeSelect,
        selectedManager,
        setselectedManager,
        level,
        setLevel,
        levelOptions,
    } = useSearch();
    const searchBarReference = useRef<HTMLDivElement>(null);
    const { width, x, y } = useRefPosition(searchBarReference);

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [docTypesLoading, setDocTypesLoading] = useState(false);

    const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
            onSearch();
        }
    };

    const menuRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (event: { target: any }) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                iconRef.current &&
                !iconRef.current.contains(event.target)
            ) {
                setShowAdvanced(false);
            }
        };
        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });
    const [isAuthornameLoading, setAuthornameLoading] = useState(false);
    const [isManagerNameLoading, setManagerNameLoading] = useState(false);

    const promiseDocTypeOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setDocTypesLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const res = await getPublishedDocumentTypes(inputValue);
            if (res?.apiStatus === 'SUCCESS') {
                const { docTypeCard } = res;
                const allowedForDCO = docTypeCard
                    .filter((item) => item.isAllowedForDCO)
                    .map((item) => item.code)
                    .join(',');
                const notAllowedForDCO = docTypeCard
                    .filter((item) => !item.isDCO && !item.isAllowedForDCO)
                    .map((item) => item.code)
                    .join(',');
                const docTypes: IOptionValue[] = [
                    {
                        value: '',
                        label: 'All',
                    },
                    {
                        value: 'DCO',
                        label: 'All Changes(DCO)',
                    },
                    {
                        value: allowedForDCO,
                        label: 'All DCO Controlled',
                    },
                    {
                        value: notAllowedForDCO,
                        label: 'All Non-DCO Controlled',
                    },
                ];
                // eslint-disable-next-line no-restricted-syntax
                for (const d of docTypeCard) {
                    if (d.name !== 'DCO') {
                        docTypes.push({ label: `${d.name}(${d.code})`, value: d.code });
                    }
                    /* if (name === 'documentTypeCode') {
                        docTypes.push({ label: d.name, value: d.code });
                    } else {
                        docTypes.push({ label: d.name, value: d.id });
                    } */
                }
                resolve(docTypes);
                setDocTypesLoading(false);
            }
        });
    };

    const promiseManagerOptions = async (inputValue: string): Promise<IOptionValue[]> =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                setManagerNameLoading(true);
                const dynamicOptions = await getDynamicValues(3, inputValue); // id of to field
                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                    resolve(dynamicOptions.dynamicData);
                    setManagerNameLoading(false);
                }
            } else {
                setManagerNameLoading(false);
            }
        });

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                setAuthornameLoading(true);
                const dynamicOptions = await getDynamicValues(3, inputValue); // id of to field
                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                    resolve(dynamicOptions.dynamicData);
                    setAuthornameLoading(false);
                }
            } else {
                setAuthornameLoading(false);
            }
        });

    return (
        <div className="document-search">
            <div className="document-search__simple">
                <div className="input-fields" ref={searchBarReference}>
                    <AsyncSelect
                        name="doc-type"
                        id="doc-type"
                        isMulti={false}
                        className="react-select doc-type-search"
                        classNamePrefix="select"
                        loadOptions={promiseDocTypeOptions}
                        defaultOptions
                        cacheOptions
                        placeholder="Document Type"
                        isClearable
                        isLoading={docTypesLoading}
                        value={docTypeSelect}
                        onChange={(option) => {
                            setdocTypeSelect(option as IOptionValue);
                        }}
                    />
                    <input
                        className="document-search__search-text doc-search"
                        name="search-text"
                        id="search-text"
                        placeholder="Search Document"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        autoComplete="off"
                        value={searchString}
                        onKeyDown={(evt) => onKeyDown(evt)}
                        onChange={(e) => setSearchString(e.target.value)}
                    />
                    <div
                        className="advanced-setting"
                        ref={iconRef}
                        onClick={() => setShowAdvanced(!showAdvanced)}
                        onKeyDown={() => setShowAdvanced(!showAdvanced)}
                        role="button"
                        tabIndex={0}
                    >
                        <SettingIconSvg
                            className={`advanced-setting-icon ${showAdvanced ? 'top' : ''}`}
                        />
                    </div>
                    <Button
                        type="button"
                        className="search-btn btn-default"
                        onClick={() => onSearch()}
                        disabled={false}
                        isLoading={false}
                    >
                        <SearchIconSvg />
                    </Button>
                </div>
                {/* <div
                    className="search-close"
                    onClick={() => setShowSearch(false)}
                    role="presentation"
                >
                    <PlusIconSvg />
                    </div> */}
            </div>
            {showAdvanced && (
                <div
                    className="document-search__advanced"
                    ref={menuRef}
                    style={{ left: x, width, top: y ? y + 41 : 41 }}
                >
                    <div className="include-attachment">
                        <label htmlFor="search-in-attachment">
                            {DOCUMENT_SEARCH.LABEL.SEARCH_IN_ATTACHMENT}
                        </label>
                        <Toggle
                            isChecked={searchInAttachment}
                            isDisabled={false}
                            onclick={() => setSearchInAttachment(!searchInAttachment)}
                        />
                    </div>
                    {advancedFilters.map((field, index) => (
                        <div key={field.name}>
                            <label className="advanced-search-label" htmlFor={field.name}>
                                {field.displayName}
                            </label>
                            {field.name === 'owner.name' && (
                                <AsyncSelect
                                    className={`advanced-search-input ${field.name}`}
                                    name={field.name}
                                    id={field.name}
                                    isMulti={false}
                                    classNamePrefix="select"
                                    placeholder={PLACEHOLDERS.MEMO[field.name] || 'select author..'}
                                    loadOptions={promiseOptions}
                                    isLoading={isAuthornameLoading}
                                    value={selectedAuthor === undefined ? null : selectedAuthor}
                                    isClearable
                                    onChange={(option) => {
                                        setselectedAuthor(option as IOptionValue);
                                        advancedSearchChange(index, option?.value as string);
                                    }}
                                />
                            )}
                            {field.name === 'manager' && (
                                <>
                                    <AsyncSelect
                                        className={`advanced-search-input ${field.name}`}
                                        name={field.name}
                                        id={field.name}
                                        isMulti={false}
                                        classNamePrefix="select"
                                        placeholder={
                                            PLACEHOLDERS.MEMO[field.name] || 'Select manager..'
                                        }
                                        loadOptions={promiseManagerOptions}
                                        isLoading={isManagerNameLoading}
                                        value={
                                            selectedManager === undefined ? null : selectedManager
                                        }
                                        isClearable
                                        onChange={(option) => {
                                            setselectedManager(option as IOptionValue);
                                            advancedSearchChange(index, option?.value as string);
                                        }}
                                    />
                                    <Select
                                        id={field.name}
                                        isClearable
                                        placeholder="Select level"
                                        value={level === undefined ? null : level}
                                        className={`advanced-search-input `}
                                        options={levelOptions}
                                        onChange={(e) => {
                                            if (e?.value) {
                                                setLevel(e);
                                            } else {
                                                setLevel(undefined);
                                            }
                                        }}
                                    />
                                </>
                            )}
                            {/* {field.name === 'state' && (
                                <Select
                                    className="advanced-search-input"
                                    name={field.name}
                                    id={field.name}
                                    isMulti={false}
                                    placeholder="Select State"
                                    options={SEARCH_STATE_DATA}
                                    value={selectedState === undefined ? null : selectedState}
                                    onChange={(option) => {
                                        setSelectedState(option as IOptionValue);
                                        advancedSearchChange(index, option?.label as string);
                                    }}
                                />
                            )} */}
                            {field.name === 'data.to' && (
                                <AsyncSelect
                                    className={`advanced-search-input ${field.name}`}
                                    name={field.name}
                                    id={field.name}
                                    isMulti
                                    placeholder={PLACEHOLDERS.MEMO[field.name] || 'select email..'}
                                    loadOptions={promiseOptions}
                                    isLoading={isAuthornameLoading}
                                    value={toList === undefined ? null : toList}
                                    isClearable
                                    onChange={(option) => {
                                        if (option) {
                                            setToList(option as IOptionValue[]);
                                            let mailString = '';
                                            option.forEach((item) => {
                                                const mail = item?.value as string;
                                                mailString = mailString.concat(
                                                    mail.split('@')[0],
                                                    ', ',
                                                );
                                            });
                                            mailString = mailString.slice(0, -2);
                                            advancedSearchChange(index, mailString);
                                        }
                                    }}
                                />
                            )}

                            {field.name === 'data.cc' && (
                                <AsyncSelect
                                    className={`advanced-search-input ${field.name}`}
                                    name={field.name}
                                    id={field.name}
                                    isMulti
                                    placeholder={PLACEHOLDERS.MEMO[field.name] || 'select email..'}
                                    loadOptions={promiseOptions}
                                    isLoading={isAuthornameLoading}
                                    value={ccList === undefined ? null : ccList}
                                    isClearable
                                    onChange={(option) => {
                                        if (option) {
                                            setCcList(option as IOptionValue[]);
                                            let mailString = '';
                                            option.forEach((item) => {
                                                const mail = item?.value as string;
                                                mailString = mailString.concat(
                                                    mail.split('@')[0],
                                                    ', ',
                                                );
                                            });
                                            mailString = mailString.slice(0, -2);
                                            advancedSearchChange(index, mailString);
                                        }
                                    }}
                                />
                            )}

                            {field.name !== 'owner.name' &&
                                field.name !== 'manager' &&
                                field.name !== 'data.to' &&
                                field.name !== 'state' &&
                                field.name !== 'data.cc' && (
                                    <input
                                        className={`advanced-search-input ${field.name}`}
                                        name={field.name}
                                        id={field.name}
                                        autoComplete="off"
                                        value={field.value}
                                        onChange={(e) =>
                                            advancedSearchChange(index, e.target.value)
                                        }
                                        placeholder={PLACEHOLDERS.MEMO[field.name] || ''}
                                    />
                                )}
                        </div>
                    ))}

                    <div className="btn">
                        <Button
                            type="button"
                            className="secondary"
                            onClick={() => {
                                onClear();
                                setShowAdvanced(false);
                            }}
                            disabled={false}
                            isLoading={false}
                        >
                            {DOCUMENT_SEARCH.BUTTON.CLEAR}
                        </Button>
                        <Button
                            type="button"
                            className="primary-btn"
                            onClick={() => {
                                onSearch();
                                setShowAdvanced(false);
                            }}
                            disabled={false}
                            isLoading={false}
                        >
                            {DOCUMENT_SEARCH.BUTTON.SEARCH}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBar;
