import React from 'react';
import Select from 'react-select';
import CONSTANTS from '../../../constants';
import { IDocStatusFilter } from '../../../interfaces';
import {
    STATUS_DATA,
    STATUS_DATA_APPROVAL,
    STATUS_DATA_REVIEW,
    STATUS_DATA_SUNSET_DOCS,
    STATUS_DATA_SUNSET_TASK_ACTION,
} from '../filter-config';

interface Props {
    name: string;
    displayName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilters: (name: string, value: any, type: string) => void;
    options: {
        label: string;
        value: IDocStatusFilter;
    }[];
    value: IDocStatusFilter;
    type: string;
}

const getOptions = (type: string): { label: string; value: IDocStatusFilter }[] => {
    switch (type) {
        case 'APPROVAL':
            return STATUS_DATA_APPROVAL;
        case 'REVIEW':
            return STATUS_DATA_REVIEW;
        case 'SUNSET_TASK':
            return STATUS_DATA_SUNSET_TASK_ACTION;
        case 'SUNSET_DOCS_DATA':
            return STATUS_DATA_SUNSET_DOCS;
        default:
            return STATUS_DATA;
    }
};

const DocStatus: React.FC<Props> = ({ value, name, displayName, updateFilters, type }) => (
    <div>
        <label htmlFor={name}>{displayName}</label>
        <Select
            name={name}
            id={name}
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={getOptions(type)}
            isSearchable={false}
            value={{
                value,
                label: CONSTANTS.DOCUMENT_STATUS[value],
            }}
            onChange={(option) => {
                updateFilters(name, option?.value, 'STATUS');
            }}
        />
    </div>
);

export default DocStatus;
