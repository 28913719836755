/* eslint-disable react/no-array-index-key */
import React from 'react';
import update from 'immutability-helper';
import { TagsInput } from 'react-tag-input-component';

import './DocTypeAttachmentFlow.scss';
import { AddButton } from '../General';
import ReactDND from '../ReactDND';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import { DragIconSvg, DeleteSvg } from '../../assets/images';
import { IAttachmentConfig } from '../../interfaces/attachment';
import Toggle from '../toggle/Toggle';
import { ICardError } from '../../interfaces/document-type';
import STATIC_CONTENT from '../../constants/StaticContent';

interface IAttachmentConfigCardProps {
    attachmentConfig: IAttachmentConfig;
    index: number;
    isDisabled: boolean;
    errors: ICardError;
}

const { DOC_TYPE_ATTACHMENT } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const AttachmentConfigCard: React.FC<IAttachmentConfigCardProps> = ({
    attachmentConfig,
    index,
    isDisabled,
    errors,
}) => {
    const { attachmentConfigDispatch, docTypeInfo } = useDocTypeContext();

    return (
        <div className="attachment-config-card">
            <div className="form-section">
                <div>
                    <label htmlFor="display-name">{DOC_TYPE_ATTACHMENT.LABEL.DISPLAY_NAME}</label>
                    <input
                        type="text"
                        name="display-name"
                        id="display-name"
                        className={`${
                            errors.fieldList && errors.fieldList.includes('displayName')
                                ? 'error'
                                : ''
                        }`}
                        value={attachmentConfig.displayName}
                        autoComplete="off"
                        onChange={(e) =>
                            attachmentConfigDispatch({
                                type: 'display-name',
                                key: index,
                                value: e.target.value,
                                changeInternalName:
                                    docTypeInfo.version === '1.0' || docTypeInfo.version === '',
                            })
                        }
                        disabled={isDisabled}
                    />
                </div>
                <div>
                    <label htmlFor="attachment-type">
                        {DOC_TYPE_ATTACHMENT.LABEL.INTERNAL_NAME}
                    </label>
                    <input
                        type="text"
                        name="attachment-type"
                        id="attachment-type"
                        className={`${
                            errors.fieldList && errors.fieldList.includes('attachmentType')
                                ? 'error'
                                : ''
                        }`}
                        autoComplete="off"
                        value={attachmentConfig.attachmentType}
                        onChange={(e) =>
                            attachmentConfigDispatch({
                                type: 'attachment-type',
                                key: index,
                                value: e.target.value,
                            })
                        }
                        disabled={isDisabled}
                    />
                </div>
                <div>
                    <label htmlFor="scope-type">{DOC_TYPE_ATTACHMENT.LABEL.IS_MANDATORY}</label>
                    <Toggle
                        isChecked={attachmentConfig.isMandatory}
                        isDisabled={isDisabled}
                        onclick={() =>
                            attachmentConfigDispatch({
                                type: 'is-mandatory',
                                key: index,
                                value: !attachmentConfig.isMandatory,
                            })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="scope-type">{DOC_TYPE_ATTACHMENT.LABEL.ALLOW_MULTIPLE}</label>
                    <Toggle
                        isChecked={attachmentConfig.allowMultiple}
                        isDisabled={isDisabled}
                        onclick={() =>
                            attachmentConfigDispatch({
                                type: 'allow-multiple',
                                key: index,
                                value: !attachmentConfig.allowMultiple,
                            })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="scope-type">{DOC_TYPE_ATTACHMENT.LABEL.ALLOW_DRAG_DROP}</label>
                    <Toggle
                        isChecked={attachmentConfig.allowDragDrop ? attachmentConfig.allowDragDrop : false}
                        isDisabled={isDisabled}
                        onclick={() =>
                            attachmentConfigDispatch({
                                type: 'allow-drag-drop',
                                key: index,
                                value: !attachmentConfig.allowDragDrop,
                            })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="scope-type">{DOC_TYPE_ATTACHMENT.LABEL.ALLOW_FILE_TYPE_ATTACHMENT}</label>
                    <Toggle
                        isChecked={attachmentConfig.allowFileTypeAttachment ? attachmentConfig.allowFileTypeAttachment : false}
                        isDisabled={isDisabled}
                        onclick={() =>
                            attachmentConfigDispatch({
                                type: 'allow-file-type-attachment',
                                key: index,
                                value: !attachmentConfig.allowFileTypeAttachment,
                            })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="scope-type">
                        {DOC_TYPE_ATTACHMENT.LABEL.ALLOWED_FILE_TYPES}
                    </label>
                    <TagsInput
                        value={attachmentConfig.allowedFileTypes}
                        onChange={(tags) =>
                            attachmentConfigDispatch({
                                type: 'allowed-types',
                                key: index,
                                value: tags,
                            })
                        }
                    />
                </div>
            </div>
            <div className="action-buttons">
                <span className="line" />
                <div
                    className="delete"
                    onClick={() =>
                        !isDisabled && attachmentConfigDispatch({ type: 'delete', key: index })
                    }
                    role="presentation"
                >
                    <h5>{DOC_TYPE_ATTACHMENT.LABEL.DELETE}</h5>
                    <DeleteSvg />
                </div>
                <DragIconSvg />
            </div>
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
const DocTypeAttachmentFlow: React.FC = () => {
    const {
        attachmentConfig,
        attachmentConfigDispatch,
        docTypeInfo,
        docTypeVersionError: {
            ATTACHMENT_FLOW: { cards },
        },
    } = useDocTypeContext();

    const moveCard = (dragIndex: number, hoverIndex: number) => {
        const dragCard = attachmentConfig[dragIndex];
        if (dragCard) {
            attachmentConfigDispatch({
                type: 'update',
                value: update(attachmentConfig, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            });
        }
    };

    return (
        <div className="doc-type-attachment-flow">
            {/* <DndProvider backend={HTML5Backend}> */}
            {attachmentConfig.map((item, index) => (
                <ReactDND key={`${index}`} index={index} moveCard={moveCard}>
                    <AttachmentConfigCard
                        errors={cards[index] ? cards[index] : { cardError: false, fieldList: [] }}
                        attachmentConfig={item}
                        index={index}
                        key={index}
                        isDisabled={docTypeInfo.status !== 'DRAFT'}
                    />
                </ReactDND>
            ))}
            {/* </DndProvider> */}
            {docTypeInfo.status === 'DRAFT' && (
                <div className="new-button">
                    <button type="button" onClick={() => attachmentConfigDispatch({ type: 'new' })}>
                        <AddButton />
                        {DOC_TYPE_ATTACHMENT.BUTTON.ADD_ATTACHMENT}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DocTypeAttachmentFlow;
