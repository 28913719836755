/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select from 'react-select';
import { IOptionValue } from '../../../interfaces';

interface Props {
    name: string;
    displayName: string;
    value: IOptionValue;
    updateFilters: (name: string, value: any, type: string) => void;
    options: IOptionValue[];
    placeholder: string | undefined;
}

const SelectFromOptions: React.FC<Props> = ({
    displayName,
    name,
    updateFilters,
    value,
    options,
    placeholder,
}) => (
    <div className="select-from-options">
        <label htmlFor={name}>{displayName}</label>
        <Select
            name={name}
            id={name}
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={options}
            value={value}
            onChange={(option) => {
                updateFilters(name, option, 'SELECT_FROM_OPTION');
            }}
            placeholder={placeholder || '   '}
        />
    </div>
);

export default SelectFromOptions;
