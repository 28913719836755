/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React from 'react';

import './PRBNomination.scss';
import { useDocumentContext } from '../../contexts/document-provider';
import { NoPermission } from '../General';
import STATIC_CONTENT from '../../constants/StaticContent';
import { LogoSvg } from '../../assets/images';
import { StageCard } from '../work-flow-panel/WorkFlowPanel';

let prefixCode = '';

const lpnf = 'LPNF';
const icmp = 'ICMP';

// eslint-disable-next-line max-lines-per-function
function CheckboxPRBNomination(rating: any) {
    const { RATING_PRB_NOMINATION_AND_LEADERSHIP } = STATIC_CONTENT;
    const { doesNotMeetExpectations } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { meetsExpectations } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { exceedsExpectations } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { SrMgr } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { Dir } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { SrDir } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { VP } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { SrVP } = RATING_PRB_NOMINATION_AND_LEADERSHIP;
    const { Rating } = rating;

    if (prefixCode !== '' && prefixCode === icmp) {
        return (
            <div className="check-box-prb-nomination">
                <div>
                    <input
                        readOnly
                        type="checkbox"
                        checked={Rating === 'Does not meet expectations'}
                    />
                    {doesNotMeetExpectations}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === 'Meets expectations'} />
                    {meetsExpectations}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === 'Exceeds expectations'} />
                    {exceedsExpectations}
                </div>
            </div>
        );
    }
    if (prefixCode !== '' && prefixCode === lpnf) {
        return (
            <div className="check-box-prb-leadership">
                <div>
                    <input readOnly type="checkbox" checked={Rating === SrMgr} />
                    {SrMgr}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === Dir} />
                    {Dir}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === SrDir} />
                    {SrDir}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === VP} />
                    {VP}
                </div>
                <div>
                    <input readOnly type="checkbox" checked={Rating === SrVP} />
                    {SrVP}
                </div>
            </div>
        );
    }
    return <></>;
}

function formatDate(timestamp: number) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1 to get the actual month
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}

// eslint-disable-next-line max-lines-per-function
const PRBNomination: React.FC = () => {
    const { fieldsData, docVersions, canViewDocumentDetails, docInfo, approvers, submittedOn, docTypeInfo,approversDispatch, setActiveVersionApprovalDeadline, activeVersionOwnerInfo, saveFieldsData } =
        useDocumentContext();
    const dateSubmittedOn = formatDate(submittedOn);
    let latestDocVersion = 0;
    docVersions.forEach((docVersion) => {
        const versionNumber = parseFloat(docVersion.label);
        if (versionNumber > latestDocVersion) {
            latestDocVersion = versionNumber;
        }
    });
    
    prefixCode = docInfo.docNumber.slice(0, 4);
    const employeeName = fieldsData.filter((item) => item.name === 'employeeName');
    const eStaff = fieldsData.filter((item) => item.name === 'eStaff');
    const HRBP = fieldsData.filter((item) => item.name === 'hrbp');
    const PRBHRREP = fieldsData.filter((item) => item.name === 'prbHRRep');
    const VPHR = fieldsData.filter((item) => item.name === 'vpHr');
    const NEO = fieldsData.filter((item) => item.name === 'neo');
    const currentGrade = fieldsData.filter((item) => item.name === 'currentGrade');
    const proposedGrade = fieldsData.filter((item) => item.name === 'proposedGrade');
    const experienceAtEnphaseYears = fieldsData.filter(
        (item) => item.name === 'experienceAtEnphaseYears',
    );
    const experienceAtEnphaseMonths = fieldsData.filter(
        (item) => item.name === 'experienceAtEnphase(Months)',
    );
    const promotionEffectiveDate = fieldsData.filter(
        (item) => item.name === 'promotionEffectiveDate',
    );
    const employeeId = fieldsData.filter((item) => item.name === 'employeeId');
    const timeInCurrentGradeYears = fieldsData.filter(
        (item) => item.name === 'timeInCurrentGradeYears',
    );
    const timeInCurrentGradeMonths = fieldsData.filter(
        (item) => item.name === 'timeInCurrentGradeMonths',
    );
    const current = fieldsData.filter((item) => item.name === 'currentPosition');
    const proposed = fieldsData.filter((item) => item.name === 'proposedPosition');
    const managerName = fieldsData.filter((item) => item.name === 'managerName');
    const functionalArea = fieldsData.filter((item) => item.name === 'functionalArea');

    const knowledgeSpecificExample = fieldsData.filter(
        (item) => item.name === 'knowledgeOrTechnicalCompetenceSpecificExamples',
    );
    const customerFocusSpecificExample = fieldsData.filter(
        (item) => item.name === 'customerFocusSpecificExamples',
    );
    const innovationSpecificExample = fieldsData.filter(
        (item) => item.name === 'innovationSpecificExamples',
    );
    const problemSolvingSpecificExample = fieldsData.filter(
        (item) => item.name === 'problemSolvingSpecificExamples',
    );
    const qualitySpecificExample = fieldsData.filter(
        (item) => item.name === 'qualitySpecificExamples',
    );
    const accountibilitySpecificExample = fieldsData.filter(
        (item) => item.name === 'accountabilitySpecificExamples',
    );
    const collaborationSpecificExample = fieldsData.filter(
        (item) => item.name === 'collaborationSpecificExamples',
    );
    const developSpecificExample = fieldsData.filter(
        (item) => item.name === 'developAndEnable(ForManagerOnly)SpecificExamples',
    );

    const knowledgeDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'knowledgeOrTechnicalCompetenceDevelopmentAreas',
    );
    const customerFocusDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'customerFocusDevelopmentAreas',
    );
    const innovationDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'innovationDevelopmentAreas',
    );
    const problemSolvingDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'problemSolvingDevelopmentAreas',
    );
    const qualityDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'qualityDevelopmentAreas',
    );
    const accountibilityDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'accountabilityDevelopmentAreas',
    );
    const collaborationDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'collaborationDevelopmentAreas',
    );
    const developDevelopmentAreas = fieldsData.filter(
        (item) => item.name === 'developAndEnable(ForManagerOnly)DevelopmentAreas',
    );
    const overallCareerExperienceLPNF = fieldsData.filter(
        (item) => item.name === 'overallCareerExperience',
    );
    const knowledgeRating = fieldsData.filter(
        (item) => item.name === 'knowledgeOrTechnicalCompetenceRating',
    );

    const experienceAtEnphaseLPNF = fieldsData.filter(
        (item) => item.name === 'experienceAtEnphase',
    );
    const mdpAttended = fieldsData.filter(
        (item) => item.name === 'MDP',
    );
    console.log(mdpAttended);
    const timeInCurrentGradeLPNF = fieldsData.filter((item) => item.name === 'timeInCurrentGrade');
    const customerFocusRating = fieldsData.filter((item) => item.name === 'customerFocusRating');
    const innovationRating = fieldsData.filter((item) => item.name === 'innovationRating');
    const problemSolvingRating = fieldsData.filter((item) => item.name === 'problemSolvingRating');
    const qualityRating = fieldsData.filter((item) => item.name === 'qualityRating');
    const accountibilityRating = fieldsData.filter((item) => item.name === 'accountabilityRating');
    const collaborationRating = fieldsData.filter((item) => item.name === 'collaborationRating');
    const developRating = fieldsData.filter(
        (item) => item.name === 'developAndEnable(ForManagerOnly)Rating',
    );
    const focalRank = fieldsData.filter((item) => item.name === 'focalRank');
    const jobCode = fieldsData.filter((item) => item.name === 'jobCode');
    const educationQualification = fieldsData.filter(
        (item) => item.name === 'educationQualification',
    );
    const overallCareerExperience = fieldsData.filter(
        (item) => item.name === 'overallCareerExperience(Years)',
    );
    
    return (
        <>
            {canViewDocumentDetails ? (
                <div className="table-container prb-nomination">
                    <div className="table-1">
                        <div className="title-ana-logo">
                            <div className="logo">
                                <LogoSvg />
                            </div>
                            <div className="title-header">
                                {prefixCode === lpnf
                                    ? 'Leadership Promotion Nomination Form'
                                    : 'PRB/TRB Nomination Form'}
                            </div>
                        </div>
                        <div className="headings">
                            <p className="table-title">Document Info:</p>
                            <p className="doc-number">REF: {docInfo.docNumber}</p>
                        </div>
                        <table>
                            <col span={4} style={{ width: '25%' }} />
                            <thead>
                                {/* <tr>
                                    <th>Info</th>
                                    <th>Value</th>
                                </tr> */}
                            </thead>
                            <tbody>
                                <tr>
                                    {/* [{"name":"employeeName","value":[{"label":"Ambati Guru Rajesh Kumar Reddy","value":"amreddy@enphaseenergy.com"},{"label":"Ashwin Patil","value":"apatil@enphaseenergy.com"}]}] */}
                                    <td style={{ fontWeight: 'bold' }}>Employee Name</td>
                                    <td>
                                        {employeeName.length > 0
                                            ? employeeName[0]?.value[0]?.label
                                            : ''}
                                    </td>

                                    <td style={{ fontWeight: 'bold' }}>Employee Id</td>
                                    <td>{employeeId.length > 0 ? employeeId[0]?.value : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Manager Name</td>
                                    <td>
                                        {managerName.length > 0
                                            ? managerName[0]?.value[0]?.label
                                            : ''}
                                    </td>
                                    <td style={{ fontWeight: 'bold' }}>EStaff</td>
                                    <td>
                                        {eStaff.length > 0
                                            ? eStaff[0]?.value[0]?.label
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Current Grade</td>
                                    <td>
                                        {currentGrade.length > 0
                                            ? currentGrade[0]?.value[0]?.value
                                            : ''}
                                    </td>

                                    <td style={{ fontWeight: 'bold' }}>Proposed Grade</td>
                                    <td>
                                        {proposedGrade.length > 0
                                            ? proposedGrade[0]?.value[0]?.value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    {prefixCode === icmp ? (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>Current Title</td>
                                            <td>{current.length > 0 ? current[0]?.value : ''}</td>
                                            <td style={{ fontWeight: 'bold' }}>Proposed Title</td>
                                            <td>{proposed.length > 0 ? proposed[0]?.value : ''}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>Current Position</td>
                                            <td>
                                                {current.length > 0
                                                    ? current[0]?.value[0]?.value
                                                    : ''}
                                            </td>
                                            <td style={{ fontWeight: 'bold' }}>
                                                Proposed Position
                                            </td>
                                            <td>
                                                {proposed.length > 0
                                                    ? proposed[0]?.value[0]?.value
                                                    : ''}
                                            </td>
                                        </>
                                    )}
                                </tr>
                                <tr>
                                    {prefixCode === icmp ? (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>Job Code</td>
                                            <td>{jobCode.length > 0 ? jobCode[0]?.value : ''}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>Functional Area</td>
                                            <td>
                                                {functionalArea.length > 0
                                                    ? functionalArea[0]?.value
                                                    : ''}
                                            </td>
                                        </>
                                    )}
                                    {prefixCode === icmp ? (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>
                                                Overall Career Experience
                                            </td>
                                            <td>
                                                {overallCareerExperience.length > 0
                                                    ? `${overallCareerExperience[0]?.value} Years`
                                                    : ''}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>
                                                Overall Career Experience
                                            </td>
                                            <td>
                                                {overallCareerExperienceLPNF.length > 0
                                                    ? `${overallCareerExperienceLPNF[0].value}`
                                                    : ''}
                                            </td>
                                        </>
                                    )}
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Experience at Enphase</td>
                                    {prefixCode === icmp ? (
                                        <td>
                                            {experienceAtEnphaseYears.length > 0
                                                ? `${experienceAtEnphaseYears[0].value}`
                                                : ''}
                                        </td>
                                    ) : (
                                        <td>
                                            {experienceAtEnphaseLPNF.length > 0
                                                ? `${experienceAtEnphaseLPNF[0].value}`
                                                : ''}
                                        </td>
                                    )}

                                    <td style={{ fontWeight: 'bold' }}>Time in Current Grade</td>
                                    {prefixCode === icmp ? (
                                        <td>
                                            {timeInCurrentGradeYears.length > 0
                                                ? `${timeInCurrentGradeYears[0].value} Years ${
                                                      timeInCurrentGradeMonths.length > 0 &&
                                                      timeInCurrentGradeMonths[0].value != null
                                                          ? timeInCurrentGradeMonths[0].value
                                                          : 0
                                                  } Months`
                                                : ''}
                                        </td>
                                    ) : (
                                        <td>
                                            {timeInCurrentGradeLPNF.length > 0
                                                ? `${timeInCurrentGradeLPNF[0].value}`
                                                : ''}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    {prefixCode === icmp ? (
                                        <>
                                            <td style={{ fontWeight: 'bold' }}>Focal Rank</td>
                                            <td>
                                                {focalRank.length > 0
                                                    ? focalRank[0]?.value[0]?.value
                                                    : ''}
                                            </td>
                                            <td style={{ fontWeight: 'bold' }}>Functional Area</td>
                                            <td>
                                                {functionalArea.length > 0
                                                    ? functionalArea[0]?.value
                                                    : ''}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            {promotionEffectiveDate.length > 0 && (
                                                <>
                                                    <td style={{ fontWeight: 'bold' }}>
                                                        Promotion Effective Date
                                                    </td>
                                                    <td>
                                                        {promotionEffectiveDate.length > 0
                                                            ? promotionEffectiveDate[0]?.value.slice(
                                                                  0,
                                                                  10,
                                                              )
                                                            : ''}
                                                    </td>
                                                </>
                                            )}
                                            <td style={{ fontWeight: 'bold' }}>
                                                Recommending Estaff / NEO
                                            </td>
                                            <td>{NEO.length > 0 ? NEO[0]?.value[0]?.value : ''}</td>
                                        </>
                                    )}
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="table-2">
                        <p className="table-title">Document Details:</p>
                        <table>
                            <col span={3} />
                            <thead>
                                {prefixCode === icmp ? (
                                    <>
                                        <tr>
                                            <th style={{ width: '34%', fontWeight: 'bold' }}>
                                                Category
                                            </th>
                                            <th style={{ width: '33%', fontWeight: 'bold' }}>
                                                Specific Examples
                                            </th>
                                            <th style={{ width: '33%', fontWeight: 'bold' }}>
                                                Development Areas
                                            </th>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <th style={{ width: '20%', fontWeight: 'bold' }}>
                                                Category
                                            </th>
                                            <th style={{ width: '40%', fontWeight: 'bold' }}>
                                                Specific Examples
                                            </th>
                                            <th style={{ width: '40%', fontWeight: 'bold' }}>
                                                {prefixCode !== '' && prefixCode === lpnf
                                                    ? '360 Degree Feedback'
                                                    : 'Development Areas'}
                                            </th>
                                        </tr>
                                    </>
                                )}
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Knowledge/Technical Competence
                                        </div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                knowledgeRating.length > 0 &&
                                                knowledgeRating[0].value.length > 0 &&
                                                knowledgeRating[0].value[0].label != null
                                                    ? knowledgeRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                        <></>
                                    </td>
                                    <td>
                                        {knowledgeSpecificExample.length > 0
                                            ? knowledgeSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {knowledgeDevelopmentAreas.length > 0
                                            ? knowledgeDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Customer Focus</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                customerFocusRating.length > 0 &&
                                                customerFocusRating[0].value.length > 0 &&
                                                customerFocusRating[0].value[0].label != null
                                                    ? customerFocusRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {customerFocusSpecificExample.length > 0
                                            ? customerFocusSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {customerFocusDevelopmentAreas.length > 0
                                            ? customerFocusDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Innovation</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                innovationRating.length > 0 &&
                                                innovationRating[0].value.length > 0 &&
                                                innovationRating[0].value[0].label != null
                                                    ? innovationRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {innovationSpecificExample.length > 0
                                            ? innovationSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {innovationDevelopmentAreas.length > 0
                                            ? innovationDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Problem Solving</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                problemSolvingRating.length > 0 &&
                                                problemSolvingRating[0].value.length > 0 &&
                                                problemSolvingRating[0].value[0].label != null
                                                    ? problemSolvingRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {problemSolvingSpecificExample.length > 0
                                            ? problemSolvingSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {problemSolvingDevelopmentAreas.length > 0
                                            ? problemSolvingDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Quality</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                qualityRating.length > 0 &&
                                                qualityRating[0].value.length > 0 &&
                                                qualityRating[0].value[0].label != null
                                                    ? qualityRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {qualitySpecificExample.length > 0
                                            ? qualitySpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {qualityDevelopmentAreas.length > 0
                                            ? qualityDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Accountability</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                accountibilityRating.length > 0 &&
                                                accountibilityRating[0].value.length > 0 &&
                                                accountibilityRating[0].value[0].label != null
                                                    ? accountibilityRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {accountibilitySpecificExample.length > 0
                                            ? accountibilitySpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {accountibilityDevelopmentAreas.length > 0
                                            ? accountibilityDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>Collaboration</div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                collaborationRating.length > 0 &&
                                                collaborationRating[0].value.length > 0 &&
                                                collaborationRating[0].value[0].label != null
                                                    ? collaborationRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {collaborationSpecificExample.length > 0
                                            ? collaborationSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {collaborationDevelopmentAreas.length > 0
                                            ? collaborationDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Develop & Enable(for mgr only)
                                        </div>
                                        <CheckboxPRBNomination
                                            Rating={
                                                developRating.length > 0 &&
                                                developRating[0].value.length > 0 &&
                                                developRating[0].value[0].label != null
                                                    ? developRating[0].value[0].label
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td>
                                        {developSpecificExample.length > 0
                                            ? developSpecificExample[0].value
                                            : ''}
                                    </td>
                                    <td>
                                        {developDevelopmentAreas.length > 0
                                            ? developDevelopmentAreas[0].value
                                            : ''}
                                    </td>
                                </tr>

                                {/* {fieldsData.map((field) => {
                                    if (typeof field.value === 'object') {
                                        return (
                                            <tr>
                                                <td style={{textTransform: "capitalize"}}>{field.name}</td>
                                                <td>
                                                    {field.value.map((item: { value: string }) => (
                                                        <p>{item.value}</p>
                                                    ))}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return (
                                        <tr>
                                            <td style={{textTransform: "capitalize"}}>{field.name}</td>
                                            <td>{field.value}</td>
                                        </tr>
                                    );
                                })} */}
                            </tbody>
                        </table>
                    </div>

                    {prefixCode === lpnf ? (
                        <>
                            <div className="table-3">
                                <p className='mdp-info'>Completed level 2 Management Development Program (MDP) : {mdpAttended.length > 0 && mdpAttended[0].value ? 'Yes' : 'No'}</p>
                                {/* <p className="confidential-footter">Author</p> */}
                                <table>
                                    <col span={4} style={{ width: '25%' }} />
                                    <thead>
                                        {/* <tr>
                                    <th>Info</th>
                                    <th>Value</th>
                            </tr> */}
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Author Name</td>
                                            <td>{docInfo.author.name}</td>

                                            <td style={{ fontWeight: 'bold' }}>Submitted On</td>
                                            <td>{dateSubmittedOn}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-4">
                                {approvers.length > 0 && approvers[0].approvers.length > 0 && (
                                    <p className="table-title">Approvers</p>
                                )}
                                {approvers.length > 0 && (
                                    <div className="approvers">
                                        <div className="approvers-tab tab-data">
                                            {approvers.map((approver, index) => (
                                                <>
                                                    {approver.approvers.length > 0 ? (
                                                        <StageCard
                                                            stageIndex={index}
                                                            error={false}
                                                            stageData={approver}
                                                            approversDispatch={approversDispatch}
                                                            setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                            isEditable={false}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                            docTypeInfo={docTypeInfo}
                                                            docInfo={docInfo}
                                                            activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                            saveFieldsData={saveFieldsData}
                                                        />
                                                    ) : null}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <p className="confidential-footter">
                                    Confidential. For internal use only.
                                </p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {/* <div className="table-3">
                        <p className="table-title">Approves</p>
                        {prefixCode !== '' && prefixCode === icmp &&
                        <table>
                            <col span={5} />
                            <thead>
                                <tr>
                                    <th style={{ fontWeight: 'bold' }}>Manager</th>
                                    <th style={{ fontWeight: 'bold' }}>Review note HRBP</th>
                                    <th style={{ fontWeight: 'bold' }}>Estaff</th>
                                    <th style={{ fontWeight: 'bold' }}>VP HR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {managerName.length > 0
                                            ? managerName[0]?.value[0]?.label
                                            : ''}
                                    </td>
                                    <td>{approvers.length > 0 && approvers[0].approvers.length >0 && approvers[0].approvers[0].employee != null ? approvers[0].approvers[0].employee.label : ''}</td>
                                    <td>{eStaff.length > 0 ? eStaff[0]?.value[0]?.label : ''}</td>
                                    <td>{VPHR.length > 0 ? VPHR[0]?.value[0]?.label : ''}</td>
                                </tr>
                            </tbody>
                        </table>}
                        {prefixCode !== '' && prefixCode === lpnf &&
                        <table>
                            <col span={5} />
                            <thead>
                                <tr>
                                    <th style={{ fontWeight: 'bold' }}>Manager</th>
                                    <th style={{ fontWeight: 'bold' }}>Estaff</th>
                                    <th style={{ fontWeight: 'bold' }}>NEO or designate</th>
                                    <th style={{ fontWeight: 'bold' }}>VP HR</th>
                                    <th style={{ fontWeight: 'bold' }}>CEO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {managerName.length > 0
                                            ? managerName[0]?.value[0]?.label
                                            : ''}
                                    </td>
                                    <td>{eStaff.length > 0 ? eStaff[0]?.value[0]?.label : ''}</td>
                                    <td>{NEO.length > 0 ? NEO[0]?.value[0]?.label : ''}</td>
                                    <td>{VPHR.length > 0 ? VPHR[0]?.value[0]?.label : ''}</td>
                                    <td>Badri Kothandaraman</td>
                                </tr>
                            </tbody>
                        </table>}

                    </div> */}
                </div>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

export default PRBNomination;

// 48821
