/* eslint-disable no-console */
import React, { useState } from 'react';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import CONSTANTS from '../../constants';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useRoleContext } from '../../contexts/group-provider';
import './GroupModal.scss';
import { addGroup, editGroup, groupSearch } from '../../API/groups';
import { DMSGroupPayload } from '../../interfaces/groups';

const animatedComponents = makeAnimated();
interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    isEdit: boolean;
}

const { DMS_GROUPS } = STATIC_CONTENT;
// eslint-disable-next-line max-lines-per-function
const GroupModal: React.FC<Props> = ({ isModalOpen, onModalClose, isEdit }) => {
    const { roleInfo, changeRoleInfo, active, resetInput, EditId } = useRoleContext();
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [errorList, setErrorList] = useState<string[]>([]);

    const mapResponseToValuesAndLabels = (data: { primary_email: string }) => ({
        value: data.primary_email,
        label: data.primary_email,
    });

    const promiseOptions = async (inputValue: string): Promise<any[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor, consistent-return
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const group = await groupSearch(inputValue);
                if (group?.apiStatus === 'SUCCESS') {
                    setLoading(false);
                    const response = group.emailGroup.map((i) => mapResponseToValuesAndLabels(i));
                    const data = response.filter((i) =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase()),
                    );
                    resolve(data);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    // eslint-disable-next-line consistent-return, max-lines-per-function
    const handleSubmit = async (obj: DMSGroupPayload) => {
        const errorFields: string[] = [];
        if (!isEdit) {
            const aliasEmail = obj.alias.map((email) => email.value).toString();
            const payload = { name: obj.name, aliasEmail };
            try {
                const response = await addGroup(payload);
                if (response?.apiStatus === 'SUCCESS') {
                    onModalClose(false);
                    resetInput();
                    setErrorList([]);
                    toast.success('DMS Group added successfully!');
                }
                if (response?.apiStatus === 'FAILURE') {
                    errorFields.push('group-name');
                    setErrorList(errorFields);
                    // console.log(response?.errorMessage);
                    toast.error(response?.errorMessage);
                }
                return undefined;
            } catch (e) {
                return undefined;
            }
        } else {
            const aliasEmail = obj.alias.map((email) => email.value).toString();
            const payload = { name: obj.name, aliasEmail, id: EditId };
            try {
                const response = await editGroup(payload);
                if (response?.apiStatus === 'SUCCESS') {
                    onModalClose(false);
                    resetInput();
                    setErrorList([]);
                    toast.success('DMS Group added successfully!');
                }
                if (response?.apiStatus === 'FAILURE') {
                    errorFields.push('group-name');
                    setErrorList(errorFields);
                    // console.log(response?.errorMessage);
                    toast.error(response?.errorMessage);
                }
                return undefined;
            } catch (e) {
                return undefined;
            }
        }
    };

    return (
        <Modal className="add_role__modal" isOpen={isModalOpen}>
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div className="modal-body">
                <div className="heading">
                    <h1>
                        {isEdit ? DMS_GROUPS.LABEL.UPDATE_A_GROUP : DMS_GROUPS.LABEL.CREATE_A_GROUP}
                    </h1>
                    <div className="role-input-group">
                        <label htmlFor="role-name">Name</label>
                        <input
                            type="text"
                            name="role-name"
                            className={errorList.includes('group-name') ? 'error' : ''}
                            autoComplete="off"
                            autoCapitalize="on"
                            placeholder="Enter Name"
                            value={roleInfo.name}
                            onChange={(e) => changeRoleInfo('name', e.target.value)}
                        />
                    </div>
                    <div className="role-input-group">
                        <label htmlFor="single-select">Email Group</label>
                        <AsyncSelect
                            name="single-select"
                            components={animatedComponents}
                            id="single-select"
                            className="react-select"
                            classNamePrefix="select"
                            value={roleInfo.alias}
                            isLoading={isLoading}
                            isMulti
                            placeholder="Search group..."
                            loadOptions={promiseOptions}
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                            onChange={(option: any) => {
                                changeRoleInfo('alias', option);
                            }}
                        />
                    </div>
                    <div className="button-group">
                        <button
                            type="button"
                            className="primary-btn"
                            disabled={!active()}
                            onClick={() => handleSubmit(roleInfo)}
                        >
                            {isEdit ? DMS_GROUPS.BUTTONS.UPDATE : DMS_GROUPS.BUTTONS.CREATE}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default GroupModal;
