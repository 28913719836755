import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../components/button/Button';
import { PlusIconSvg } from '../../assets/images';
import './AddToChangeModal.scss';
import {
    createDocument,
    getDocumentsDetails,
    toggleDocumentLock,
    updateDocumentVersionFieldsData,
} from '../../API/document';
import { getPublishedDocumentTypes } from '../../API/document-type';
import { IFieldsData } from '../../interfaces/document';
import Pagination from '../../components/pagination/Pagination';
import CONSTANTS from '../../constants';
import { IDCO } from '../../interfaces/dashboard';
import { IDocStatus, IPaginationData } from '../../interfaces';
import { getAllDraftDCOs } from '../../API/dashboard';
import { SkeletonText } from '../../components/Skeleton';
import { useDocumentContext } from '../../contexts/document-provider';

interface Props {
    show: boolean;
    handleClose: () => void;
    childDocVersionId: number;
    latestrelationArr: any[];
    docVersionCheckedInFlag: boolean;
}
// eslint-disable-next-line max-lines-per-function
const AddToChangeModal: React.FC<Props> = ({
    show,
    handleClose,
    childDocVersionId,
    latestrelationArr,
    docVersionCheckedInFlag
}) => {
    const [isLoading, setLoading] = useState(false);
    const [isSelectLoading, setisSelectLoading] = useState(false);
    const history = useHistory();
    const [selectedDocId, setSelectedDocId] = useState<number | null>(null);
    const [dcoData, setDcoData] = useState(null);
    const [selectedDocVersionId, setSelectedVersionDocId] = useState<number | null>(null);
    const [selectedDocNumber, setSelectedDocNumber] = useState<string | null>(null);
    const {saveFieldsData} = useDocumentContext();
    const getDCODocTypeId = async () => {
        try {
            const res = await getPublishedDocumentTypes('DCO');

            if (res?.apiStatus === 'SUCCESS') {
                // eslint-disable-next-line no-restricted-syntax
                for (const d of res.docTypeCard) {
                    if (d.config?.isDCO) {
                        return d.id;
                    }
                }
            }

            toast.error('Error fetching DCO document type');
            return null;
        } catch (error) {
            toast.error('An error occurred while fetching DCO document type');
            return null;
        }
    };

    const [enteredChangeNumber, setenteredChangeNumber] = useState<string | null>(null);

    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS_IN_MODAL,
        totalCount: 0,
    };

    const defaultdrafDCOs = () => {
        const drafDCOs: IDCO[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            drafDCOs.push({
                docId: CONSTANTS.LOADING.NUMBER,
                docNumber: CONSTANTS.LOADING.TEXT,
                title: CONSTANTS.LOADING.TEXT,
                state: CONSTANTS.LOADING.TEXT as IDocStatus,
                docVersionId: CONSTANTS.LOADING.NUMBER,
            });
        }

        return drafDCOs;
    };

    const [drafDCOs, setdrafDCOs] = useState<IDCO[]>(defaultdrafDCOs());
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    const handleCloseModal = () => {
        setdrafDCOs(defaultdrafDCOs());
        setPagination(DEFAULT_PAGINATION_DATA);
        setenteredChangeNumber(null);
        setSelectedDocId(null);
        setDcoData(null);
        setSelectedVersionDocId(null);
        setSelectedDocNumber(null);
        handleClose();
    };

    const AddDocumentTODCO = async (docId: number, docVersionId: number, flag?: boolean, DCOData?: any) => {
        const res2 = await getDocumentsDetails([childDocVersionId]);
        if (res2?.apiStatus === 'SUCCESS') {
            if (DCOData && DCOData.documents) {
                res2.data = res2.data.concat(DCOData.documents);
            }
            const docs: IFieldsData[] = [{ name: 'documents', value: res2.data }];
            const response = await updateDocumentVersionFieldsData(docs, docId, docVersionId, true);
            if (response?.apiStatus === 'SUCCESS') {
                if (flag) {
                    toast.success(`Created new DCO and added the document to DCO`);
                    toast.success(`Redirecting to newly created DCO`);
                    await toggleDocumentLock(true, res2?.data[0].id, docId);
                    setTimeout(() => {
                        history.push(`/create-document?doc_id=${docId}`);
                        handleClose();
                    }, 2000);
                } else {
                    toast.success(`Added the document to ${selectedDocNumber}`);
                    handleCloseModal();
                }
                if(res2.data[0].state !== 'DRAFT'){
                    toast.error(`There is no draft version of ${res2.data[0].documentNumber}`, { autoClose: 7000 });
                }
                if(!docVersionCheckedInFlag) {
                    toast.error(`Please check in the document ${res2.data[0].documentNumber} before submitting the DCO`, { autoClose: 7000 });
                }
            }
        }
    };

    const SelectAddToDCO = async () => {
        setisSelectLoading(true);
        if (latestrelationArr.some((obj) => obj.activeVersionId === selectedDocVersionId)) {
            toast.error('The document is already part of the selected DCO');
        } else
            await AddDocumentTODCO(selectedDocId as number, selectedDocVersionId as number, false, dcoData);
        setisSelectLoading(false);
    };

    // eslint-disable-next-line max-lines-per-function
    const handleCreate = async () => {
        setLoading(true);
        await saveFieldsData(false);
        try {
            const object = {};
            const DCOdoctypeid = await getDCODocTypeId();
            if (DCOdoctypeid) {
                const res1 = await createDocument(DCOdoctypeid, object);
                if (res1?.apiStatus === 'SUCCESS') {
                    if (childDocVersionId) {
                        await AddDocumentTODCO(res1.docId, res1.docVersionId, true);
                    }
                } else {
                    toast.error(res1?.errorMessage);
                }
            } else {
                toast.error('DCO Document Type not found');
            }
        } catch (error) {
            toast.error('An error occurred while creating the DCO and adding the document');
        } finally {
            setLoading(false);
        }
    };

    const SelectedDraftDCO = (row: Row<IDCO>) => {
        const {
            original: { docNumber, docId, docVersionId, data },
        } = row;

        return (
            <div className="align-radio-in-modal">
                <input
                    type="radio"
                    id={`radio-${docId}`}
                    name="submit"
                    value="submit"
                    onChange={() => {
                        setSelectedDocId(docId);
                        setSelectedVersionDocId(docVersionId);
                        setSelectedDocNumber(docNumber);
                        setDcoData(data);
                    }}
                    checked={docId === selectedDocId}
                />
            </div>
        );
    }

    const DocNumberDraftDCOs = (row: Row<IDCO>) => {
        const {
            original: { docNumber, docId },
        } = row;

        return (
            <div className="align-DCONumber">
                <Link
                    className="link-style DCO-modal"
                    style={{ textDecoration: 'none' }}
                    to={{
                        pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                        search: `?doc_id=${docId}`,
                    }}
                    target="_blank"
                >
                    <SkeletonText text={docNumber} width={200} />
                </Link>
            </div>
        );
    };

    const TitleDraftDCOsColumn = (row: Row<IDCO>) => {
        const {
            original: { title },
        } = row;

        return (
            <>
                <SkeletonText text={title} width={200} />
            </>
        );
    };

    const StatusDraftDCOsColumn = (row: Row<IDCO>) => {
        const {
            original: { state },
        } = row;

        return (
            <>
                <SkeletonText text={state} width={100} />
            </>
        );
    };
    const DRAFT_DCOS_COLUMNS: Column<IDCO>[] = [
        {
            Header: 'Select',
            accessor: 'docId',
            Cell: ({ row }) => <SelectedDraftDCO {...row} />,
            minWidth: 30,
            maxWidth: 30
        },
        {
            Header: 'Change Number',
            accessor: 'docNumber',
            Cell: ({ row }) => <DocNumberDraftDCOs {...row} />,
            minWidth: 100,
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ row }) => <TitleDraftDCOsColumn {...row} />,
            minWidth: 150,
        },
        {
            Header: 'Status',
            accessor: 'state',
            Cell: ({ row }) => <StatusDraftDCOsColumn {...row} />,
            minWidth: 50,
            maxWidth: 50
        },
    ];

    const columns = useMemo(() => DRAFT_DCOS_COLUMNS, [selectedDocId]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        gotoPage,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data: drafDCOs,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
            updateResults: () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                fetchDocuments();
            },
        },
        useSortBy,
        usePagination,
    );

    const fetchDocuments = async () => {
        const queryParams = {
            limit: pageSize.toString(),
            offset: (pageIndex * pageSize).toString(),
            searchDocNumber: enteredChangeNumber as string,
        };

        const response = await getAllDraftDCOs(queryParams);
        if (response?.apiStatus === 'SUCCESS') {
            setdrafDCOs(response.DraftDCOs);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: response.totalDCOs,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    const handleChange = (event: { target: { value: React.SetStateAction<string | null> } }) => {
        setenteredChangeNumber(event.target.value);
    };

    useEffect(() => {
        if (show) {
            fetchDocuments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, show, enteredChangeNumber]);

    return (
        <div>
            <Modal isOpen={show} className="AddToChange-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleCloseModal()} />
                <div>
                    <h1>Add to Change</h1>
                    <div className="Create-NewChange">
                        <h2>Create a new Change Order</h2>
                        <Button
                            onClick={() => handleCreate()}
                            disabled={isLoading}
                            isLoading={isLoading}
                            className="submit-button add-to-change-btns"
                            type={undefined}
                        >
                            Create
                        </Button>
                    </div>
                    <div>
                        <h2>Select an existing change</h2>
                        {/* <div className="document-approvers-filters">
                            <EnterChangeNumber />
                        </div> */}
                        <div className="table-section">
                            <table className="table-in-modal" {...getTableProps()}>
                                <thead className="thead-in-modal">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth
                                                                ? column.minWidth
                                                                : 'auto',
                                                        },
                                                    })}
                                                >
                                                    {column.render('Header')}
                                                    {headerGroup.headers[1] === column && (
                                                        <div className="document-approvers-filters">
                                                            <input
                                                                type="text"
                                                                name="doc-number"
                                                                id="doc-number"
                                                                placeholder="Search here..."
                                                                className="input-search"
                                                                value={
                                                                    enteredChangeNumber as string
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody className="tbody-in-modal" {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <td
                                                        className="rowheight-modal"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.column.minWidth
                                                                    ? cell.column.minWidth
                                                                    : 'auto',
                                                            },
                                                        })}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {page.length > 0 &&
                                pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS_IN_MODAL && (
                                    <Pagination
                                        nextPage={nextPage}
                                        canNextPage={canNextPage}
                                        canPreviousPage={canPreviousPage}
                                        pageIndex={pageIndex}
                                        pageOptions={pageOptions}
                                        pageSize={pageSize}
                                        previousPage={previousPage}
                                        setPageSize={setPageSize}
                                        className="in-modal"
                                    />
                                )}
                            {page.length === 0 && (
                                <div className="no-draft-dcos-found">
                                    <h1>No Draft DCOs Found</h1>
                                </div>
                            )}
                            {page.length > 0 && (
                                <div>
                                    <Button
                                        onClick={() => SelectAddToDCO()}
                                        disabled={isSelectLoading || selectedDocId === null}
                                        isLoading={isSelectLoading}
                                        className="submit-button add-to-change-btns"
                                        type={undefined}
                                    >
                                        Select
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddToChangeModal;
