import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import './InactiveApproversSetting.scss';
import InactiveApproversActions from '../actions/inactiveApproversActions';
import InactiveApproversFilter from '../filters/InactiveApproversFilter';
import InactiveApproversTable from './InactiveApproversTable';
import {
    IInactiveApproverActions,
    IInactiveApproverDocuments,
    IInactiveAProverLoading,
    IInactiveDocuments,
    IOptionValue,
    IPaginationData,
    IUserOptionValue,
} from '../../../interfaces';
import CONSTANTS from '../../../constants';
import {
    getDocumentWithInactiveApprovers,
    inactiveApproversActions,
} from '../../../API/inactive-approvers';
import { useAuthDataContext } from '../../../contexts/user-auth-provider';
import { inactiveString } from '../../../utils';

const defaultDocWithInactiveApprovers = () => {
    const myApprovals: IInactiveApproverDocuments[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 9; i++) {
        myApprovals.push({
            documentId: CONSTANTS.LOADING.NUMBER,
            docNumber: CONSTANTS.LOADING.TEXT,
            documentType: CONSTANTS.LOADING.TEXT,
            inActiveApprover: CONSTANTS.LOADING.TEXT,
            inActiveApproverId: CONSTANTS.LOADING.NUMBER,
            ownerId: CONSTANTS.LOADING.NUMBER,
        });
    }

    return myApprovals;
};

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

// eslint-disable-next-line max-lines-per-function
const InactiveApproversSetting: React.FC = () => {
    const { user } = useAuthDataContext();
    const [docWithInactiveApprovers, setDocWithInactiveApprovers] = useState(
        defaultDocWithInactiveApprovers,
    );
    const [selectedDocuments, setSelectedDocuments] = useState<IInactiveDocuments[]>([]);
    const [selectedInactiveApprover, setSelectedInactiveApprover] = useState<IOptionValue>({
        label: 'ALL',
        value: '',
    });
    const [selectedNewApprover, setSelectedNewAPprover] = useState<IUserOptionValue | null>(null);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    const [loadingState, setLoadingState] = useState<IInactiveAProverLoading | null>(null);

    const update = async (pageIndex?: number, pageSize?: number) => {
        const inactiveApproversIds: number[] = selectedInactiveApprover?.value
            ? [selectedInactiveApprover?.value as number]
            : [];
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const documents = await getDocumentWithInactiveApprovers(
            [user.id],
            inactiveApproversIds,
            size,
            index * size,
        );
        if (documents?.apiStatus === 'SUCCESS') {
            setDocWithInactiveApprovers(documents.documentWithInactiveApprovers);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: documents.totalCount,
            });
        }
    };

    const handleSingleCheckBox = (documentId: number, oldApproverId: number) => {
        const selectedDocumentsCopy = [...selectedDocuments];
        const position = selectedDocumentsCopy
            .map((d) => inactiveString(d.documentId, d.oldApproverId))
            .indexOf(inactiveString(documentId, oldApproverId));
        if (position === -1) {
            selectedDocumentsCopy.push({ documentId, oldApproverId });
        } else {
            selectedDocumentsCopy.splice(position, 1);
        }
        setSelectedDocuments(selectedDocumentsCopy);
    };

    const handleSelectAll = (selectAll1: boolean) => {
        if (selectAll1) {
            setSelectedDocuments(
                docWithInactiveApprovers.map((d) => ({
                    documentId: d.documentId,
                    oldApproverId: d.inActiveApproverId,
                })),
            );
        } else {
            setSelectedDocuments([]);
        }
    };

    // eslint-disable-next-line max-lines-per-function
    const handleAction = async (type: IInactiveApproverActions, documentId?: number) => {
        setLoadingState({ type, id: documentId || 0 });
        if (documentId) {
            const [document] = docWithInactiveApprovers.filter((d) => d.documentId === documentId);
            if (type === 'REASSIGN' && !document.selectedApprover?.value) {
                toast.error('Please Select Approver');
            } else {
                const res = await inactiveApproversActions(
                    type,
                    [{ documentId, oldApproverId: document.inActiveApproverId }],
                    document.selectedApprover?.value as number,
                );
                if (res?.apiStatus === 'FAILURE') {
                    toast.error('Something went wrong');
                } else {
                    update();
                }
            }
        } else {
            const documents = selectedDocuments;
            if (type === 'REASSIGN' && !selectedNewApprover?.value) {
                toast.error('Please Select Approver');
            } else {
                const res = await inactiveApproversActions(
                    type,
                    documents,
                    selectedNewApprover?.value as number,
                );
                if (res?.apiStatus === 'FAILURE') {
                    toast.error('Something went wrong');
                } else {
                    if (res?.errors && res.errors.length > 0) {
                        res.errors.map((item) => {
                            const temp = JSON.parse(item.errorReason);
                            if (temp.message === 'Duplicate new approver.') {
                                return toast.error(
                                    `Could not resolve inactive approver for ${item.documentNumber},
                                 new approver already exists.`,
                                    { toastId: item.documentId },
                                );
                            }
                            return toast.error(temp.message);
                        });
                    }
                    update();
                }
            }
        }
        setLoadingState(null);
    };

    const updateInactiveApprover = (
        selectedUser: IUserOptionValue,
        documentId: number,
        inactiveApproverId: number,
    ) => {
        const documentsCopy = [...docWithInactiveApprovers];
        const position = docWithInactiveApprovers
            .map((d) => inactiveString(d.documentId, d.inActiveApproverId))
            .indexOf(inactiveString(documentId, inactiveApproverId));
        const [document] = docWithInactiveApprovers.filter(
            (d) =>
                inactiveString(d.documentId, d.inActiveApproverId) ===
                inactiveString(documentId, inactiveApproverId),
        );
        document.selectedApprover = selectedUser;
        setDocWithInactiveApprovers([
            ...documentsCopy.slice(0, position),
            document,
            ...documentsCopy.slice(position + 1),
        ]);
    };

    useEffect(() => {
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInactiveApprover]);

    return (
        <div className="inactive-approvers-setting">
            <InactiveApproversFilter
                owner={{
                    jobTitle: user.jobTitle,
                    label: user.name,
                    role: user.role,
                    roleId: 0,
                    value: user.id,
                    activeUser: true,
                }}
                inactiveApprover={selectedInactiveApprover}
                updateInactiveApprover={(approver) => setSelectedInactiveApprover(approver)}
                hideUsers={[user.id]}
            />
            <InactiveApproversTable
                data={docWithInactiveApprovers}
                selectAll={selectedDocuments.length === docWithInactiveApprovers.length}
                SelectedDocuments={selectedDocuments}
                pagination={pagination}
                handleRowAction={(type, documentId) => handleAction(type, documentId)}
                handleSelectAll={(selectAll2) => {
                    handleSelectAll(selectAll2);
                }}
                handleSingleCheckBox={(documentId, oldApproverId) =>
                    handleSingleCheckBox(documentId, oldApproverId)
                }
                updateInactiveApprover={(selectedUser, documentId, inactiveApproverId) =>
                    updateInactiveApprover(selectedUser, documentId, inactiveApproverId)
                }
                fetchDocuments={(pageIndex, pageSize) => update(pageIndex, pageSize)}
                loadingState={loadingState}
            />
            <InactiveApproversActions
                isDisabled={!(selectedDocuments.length > 0)}
                onButtonClickAction={(type) => handleAction(type)}
                changedApprover={selectedNewApprover}
                updateChangedApprover={(selectedUser: IUserOptionValue) =>
                    setSelectedNewAPprover(selectedUser)
                }
                hideUsers={[]}
                loadingState={loadingState}
            />
        </div>
    );
};

export default InactiveApproversSetting;
