import React, { useState } from 'react';
import Modal from 'react-modal';
import { PlusIconSvg } from '../../assets/images';
import './VersionConfirmationModal.scss';
import Button from '../button/Button';


interface Props {
    isModalOpen: boolean;
    onModalClose: () => void;
    onConfirm: () => void;
}

/* eslint-disable max-lines-per-function */
const VersionConfirmationModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    onConfirm,
}) => {
    const [loading, setLoading] = useState(false);
    const handleConfirmClick = async () => {
        setLoading(true);
        await onConfirm();
        setLoading(false);
        onModalClose();
    };
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={onModalClose}
            contentLabel="confirmation modal"
            className="confirmation__modal"
        >
            <PlusIconSvg className="close-btn" onClick={onModalClose} />
            <div className="heading">
                <h1>Version number is not modified from the previous release.
                </h1>
                <h4>
                    Do you want to continue?
                </h4>
            </div>
            <div className="buttons">
                <Button type="button" onClick={onModalClose} disabled={loading} isLoading={false} className="primary-btn">
                    No
                </Button>
                <Button
                    type="button"
                    className="primary-btn"
                    onClick={handleConfirmClick}
                    isLoading={loading} disabled={loading}                >
                    Yes
                </Button>
            </div>
        </Modal>
    );
}

export default VersionConfirmationModal;
