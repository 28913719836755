import React from 'react';

import './SortOption.scss';

interface Props {
    sortType: string;
    setSortType: (sortType: string) => void;
    sortOrder: string;
    setSortOrder: (sortOrder: string) => void;

}

const SortOption: React.FC<Props> = ({ sortType, setSortType, sortOrder, setSortOrder }) => (
    <div className="sortoption">
        <label htmlFor="sort-by">Sort By </label>
        <select
            id="sort-by"
            name="sort-by"
            className="sort-by"
            value={sortType}
            onChange={(e) => {
                setSortType(e.target.value);
            }}
        >
            <option value="Best Match">Best Match</option>
            <option value="owner.name.keyword">Author Name</option>
            <option value="documentNumber.keyword">Document Number</option>
            <option value="approvedOn">Publication Date</option>
        </select>
        { sortType !== 'Best Match' &&
        <>&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="order-by">Order By </label><select
                id="order-by"
                name="order-by"
                className="order-by"
                value={sortOrder}
                onChange={(e) => {
                    setSortOrder(e.target.value);
                } }
            >
                <option value="asc">asc</option>
                <option value="desc">desc</option>
            </select></>
        }
    </div>
);

export default SortOption;
