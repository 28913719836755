import React, { createContext, useEffect, useContext, useState } from 'react';
import qs, { ParsedQs } from 'qs';
import { useLocation } from 'react-router-dom';

interface IQueryParamsContext {
    queryParams: ParsedQs,
    checkParamExist: (key: string) => boolean,
    getQueryParam: (key: string) => string 
}

const QueryParamsContext = createContext<IQueryParamsContext>({
    queryParams: {},
    checkParamExist: () => false,
    getQueryParam : () => ''
});

const QueryParamsProvider: React.FC = (props: unknown) => {
    const [queryParams, setQueryParams] = useState<ParsedQs>({});
    const location = useLocation();
    useEffect(() => {
        const queryParamsData = qs.parse(location.search,{ ignoreQueryPrefix: true });
        setQueryParams(queryParamsData)
    }, [location])

    const checkParamExist = (key: string) => !!queryParams[key];

    const getQueryParam = (key: string) => queryParams[key] ? queryParams[key] as string : '';
    
    return <QueryParamsContext.Provider value={{queryParams, checkParamExist, getQueryParam }} {...props} />
}

export const useQueryParams = ():IQueryParamsContext => useContext(QueryParamsContext)

export default QueryParamsProvider;