import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { getFromLocalStorage } from '../../utils/local-storage';
import './Breadcrumbs.scss';

// TODO : dynamic breadcrumb based on path of page

interface IBreadcrumb {
    name: string;
    link: string;
}

const breadcrumbMapping: { [key: string]: IBreadcrumb[] } = {
    '/dashboard': [],
    '/create-document': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Documents',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`,
        },
        { name: 'Create Document', link: CONSTANTS.RELATIVE_PATHS.createDocument.Url },
    ],
    '/document-type/create': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Document Types', link: CONSTANTS.RELATIVE_PATHS.documentTypes.Url },
        { name: 'Create', link: CONSTANTS.RELATIVE_PATHS.documentTypeCreate.Url },
    ],
    '/document-types': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Document Types', link: CONSTANTS.RELATIVE_PATHS.documentTypes.Url },
    ],
    '/document-details': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Documents',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`,
        },
        { name: 'Document Details', link: CONSTANTS.RELATIVE_PATHS.documentDetails.Url },
    ],
    '/file-details': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'File Details', link: CONSTANTS.RELATIVE_PATHS.fileDetails.Url },
    ],
    '/search': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Search', link: CONSTANTS.RELATIVE_PATHS.search.Url },
    ],
    '/settings': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Settings', link: CONSTANTS.RELATIVE_PATHS.settings.Url },
    ],
    '/task-details': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Approvals',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`,
        },
        { name: 'Task Details', link: CONSTANTS.RELATIVE_PATHS.taskDetails.Url },
    ],
    '/documentation': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'User Guide', link: CONSTANTS.RELATIVE_PATHS.documentation.Url },
    ],
    '/adm/documents': [
        { name: 'Admin Dashboard', link: CONSTANTS.RELATIVE_PATHS.adminDashboard.Url },
        { name: 'Documents', link: CONSTANTS.RELATIVE_PATHS.adminDocs.Url },
    ],
    '/adm/document-types-config': [
        { name: 'Admin Dashboard', link: CONSTANTS.RELATIVE_PATHS.adminDashboard.Url },
        { name: 'Document Types', link: CONSTANTS.RELATIVE_PATHS.adminDocTypes.Url },
    ],
    '/adm/tasks': [
        { name: 'Admin Dashboard', link: CONSTANTS.RELATIVE_PATHS.adminDashboard.Url },
        { name: 'Tasks', link: CONSTANTS.RELATIVE_PATHS.adminTasks.Url },
    ],
    '/adm/delegations': [
        { name: 'Admin Dashboard', link: CONSTANTS.RELATIVE_PATHS.adminDashboard.Url },
        { name: 'Delegations', link: CONSTANTS.RELATIVE_PATHS.adminDelegations.Url },
    ],
    '/clm/contracts': [
        { name: 'Contracts Dashboard', link: CONSTANTS.RELATIVE_PATHS.contractsDashboard.Url },
        { name: 'Contracts', link: CONSTANTS.RELATIVE_PATHS.contracts.Url },
    ],
    '/reportList': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Reports', link: CONSTANTS.RELATIVE_PATHS.reports.Url },
    ],
    '/reportList/report': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Reports', link: CONSTANTS.RELATIVE_PATHS.reports.Url },
        { name: 'Report', link: CONSTANTS.RELATIVE_PATHS.report.Url },
    ],
    '/reportList/detail-summary': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Reports', link: CONSTANTS.RELATIVE_PATHS.reports.Url },
        { name: 'Report Summary', link: CONSTANTS.RELATIVE_PATHS.reportSummary.Url },
    ],
};

// eslint-disable-next-line max-lines-per-function
const Breadcrumbs: React.FC = () => {
    const [breadcrumb, setBreadCrumb] = useState<IBreadcrumb[]>();
    const [createText, setCreateText] = useState('');
    const [detailText, setDetailText] = useState('');
    const { pathname, search } = useLocation();
    const history = useHistory();
    const [isDCOValue, setIsDCOValue] = useState(getFromLocalStorage('isDCO'));
    const [detailDCOValue, setDetailDCOValue] = useState(getFromLocalStorage('detailDCO'));
    const handleLocalStorageUpdate = (event: CustomEvent) => {
        if (event.detail.key === 'isDCO') {
            setIsDCOValue(event.detail.value);
        } else if (event.detail.key === 'detailDCO') {
            setDetailDCOValue(event.detail.value);
        }
    };
    window.addEventListener('localStorageUpdate', handleLocalStorageUpdate as EventListener);
    useEffect(() => {
        if (isDCOValue === 'true') {
            setCreateText('Create Document Change Order');
        } else {
            setCreateText('Create Document');
        }
    }, [isDCOValue]);
    useEffect(() => {
        if (detailDCOValue === 'true') {
            setDetailText('Change Details');
        } else {
            setDetailText('Document Details');
        }
    }, [detailDCOValue]);

    // eslint-disable-next-line max-lines-per-function
    useEffect(() => {
        if (breadcrumbMapping[pathname]) {
            const breadcrumbs = [...breadcrumbMapping[pathname]];
            if (
                pathname === '/document-details' ||
                pathname === '/task-details' ||
                pathname === '/file-details'
            ) {
                if (
                    pathname === '/document-details' &&
                    !(search.includes('ADMIN_DOC') || search.includes('ADMIN_TASKS') || search.includes('REPORTS'))
                ) {
                    breadcrumbs[0].name = 'Dashboard';
                    breadcrumbs[0].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}`;
                    breadcrumbs[1] = {
                        ...breadcrumbs[1],
                        name: 'My Documents',
                        link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`,
                    };
                    breadcrumbs[2] = { ...breadcrumbs[2], name: detailText };
                } else if (pathname === '/file-details') {
                    breadcrumbs[1] = {
                        ...breadcrumbs[1],
                        name: 'File Details',
                        link: `${CONSTANTS.RELATIVE_PATHS.fileDetails.Url}`,
                    };
                } else if (search.includes('MY_GROUP_APPROVALS')) {
                    breadcrumbs[1].name = 'My Group Approvals';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_GROUP_APPROVALS`;
                } else if (search.includes('MY_REVIEWS')) {
                    breadcrumbs[1].name = 'My Reviews';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_REVIEWS`;
                } else if (search.includes('MY_FAVORITES')) {
                    breadcrumbs[1].name = 'My Favorites';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_FAVORITES`;
                } else if (search.includes('MY_LATEST')) {
                    breadcrumbs[1].name = 'Latest Documents';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_LATEST`;
                } else if (search.includes('SEARCH')) {
                    breadcrumbs[1].name = 'Search';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.search.Url}`;
                } else if (search.includes('ADMIN_DOC')) {
                    breadcrumbs[1].name = 'Documents';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.adminDocs.Url}`;
                    breadcrumbs[0].name = 'Admin Dashboard';
                    breadcrumbs[0].link = `${CONSTANTS.RELATIVE_PATHS.adminDashboard.Url}`;
                    breadcrumbs[2] = { ...breadcrumbs[2], name: detailText };
                } else if (search.includes('ADMIN_DASHBOARD')) {
                    breadcrumbs[1].name = 'Admin Dashboard';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.adminDashboard.Url}`;
                    breadcrumbs.shift();
                } else if (search.includes('ADMIN_TASKS')) {
                    breadcrumbs[1].name = 'Tasks';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.adminTasks.Url}`;
                    breadcrumbs[0].name = 'Admin Dashboard';
                    breadcrumbs[0].link = `${CONSTANTS.RELATIVE_PATHS.adminDashboard.Url}`;
                    breadcrumbs[2] = { ...breadcrumbs[2], name: detailText };
                } else if (search.includes('REPORTS')) {
                    breadcrumbs[1].name = 'Reports';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.reports.Url}`;
                    breadcrumbs[2] = { ...breadcrumbs[2], name: detailText };
                } else {
                    breadcrumbs[1].name = 'My Approvals';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`;
                }
            } else if (pathname === '/document-type/create') {
                if (search.includes('ADMIN_DOC_TYPE')) {
                    breadcrumbs[1].name = 'Document Types';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.adminDocTypes.Url}`;
                    breadcrumbs[0].name = 'Admin Dashboard';
                    breadcrumbs[0].link = `${CONSTANTS.RELATIVE_PATHS.adminDashboard.Url}`;
                }
            } else if (pathname === '/create-document') {
                breadcrumbs[2] = { ...breadcrumbs[2], name: createText };
            } else if (search.includes('CUSTOM_REPORT')) {
                breadcrumbs[2] = { ...breadcrumbs[2], name: 'Custom Report' };
            } else if (search.includes('STANDARD_REPORT')) {
                breadcrumbs[2] = { ...breadcrumbs[2], name: 'Standard Report' };
            }
            setBreadCrumb(breadcrumbs);
        } else {
            setBreadCrumb([]);
        }
    }, [pathname, search, createText, detailText]);

    const onClick = (link: string) => {
        history.push(`/${link}`);
    };
    const { tokenValid, user } = useAuthDataContext();
    return (
        <div className="breadcrumbs">
            {tokenValid && user.id && (
                <ul>
                    {breadcrumb?.map((data) => (
                        <li key={data.name}>
                            <span
                                role="link"
                                onClick={() => onClick(data.link)}
                                onKeyPress={() => onClick(data.link)}
                                tabIndex={0}
                            >
                                {data.name}
                            </span>
                            <ArrowIcon />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Breadcrumbs;
