/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { IUserOptionValue } from '../../../interfaces';
import CONSTANTS from '../../../constants';
import './DocumentAdminFilters.scss';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { userSearch } from '../../../API/users';

const { DOCUMENNT_ADMIN } = STATIC_CONTENT;
const animatedComponents = makeAnimated();
const DEFAULT_OWNER: IUserOptionValue[] = [
    {
        jobTitle: '',
        label: 'All',
        role: '',
        roleId: 0,
        value: 0,
        activeUser: false,
    },
];
interface SelectOwnerProps {
    owner: IUserOptionValue | null;
    updateOwner: (approver: IUserOptionValue) => void;
}
// eslint-disable-next-line max-lines-per-function
const SelectOwner: React.FC<SelectOwnerProps> = ({ owner, updateOwner }) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    if (userList.length > 0) {
                        resolve([...DEFAULT_OWNER, ...userList]);
                    } else if (inputValue.toLowerCase() === 'all') {
                        resolve(DEFAULT_OWNER);
                    } else {
                        resolve([]);
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };
    const handleChange = (option: any) => {
        if (option) updateOwner(option as IUserOptionValue);
        else
            updateOwner({
                jobTitle: '',
                label: 'All',
                value: '',
                activeUser: true,
                role: '',
                roleId: 0,
            });
    };
    return (
        <div className="select-user">
            <label htmlFor="user-search" className="select-user__label">
                {DOCUMENNT_ADMIN.LABEL.ENTER_OWNER}
            </label>
            <AsyncSelect
                name="user-search"
                id="user-search"
                components={animatedComponents}
                isLoading={isLoading}
                placeholder="Search user..."
                className="react-select select-approver__select"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                getOptionLabel={(e) => e.jobTitle ? `${e.label} | ${e.jobTitle}` : e.label }
                value={owner}
                noOptionsMessage={() =>
                    searchText.length >= 3
                        ? 'No results found'
                        : 'Start typing minimum 3 characters'
                }
                onChange={(option) => {
                    handleChange(option);
                }}
                isClearable
            />
        </div>
    );
};
interface EnterDocNumberProps {
    docNumber: string | null;
    updateDocNumber: React.Dispatch<React.SetStateAction<string>>;
}
// eslint-disable-next-line max-lines-per-function
const EnterDocNumber: React.FC<EnterDocNumberProps> = ({ docNumber, updateDocNumber }) => {
    const handleChange = (input: React.SetStateAction<string>, { action }: any) => {
        if (action === 'input-change') {
            updateDocNumber(input);
        }
    };
    return (
        <div className="enter-doc-number">
            <label htmlFor="user-search" className="enter-doc-number__label">
                {DOCUMENNT_ADMIN.LABEL.ENTER_DOC_NUMBER}
            </label>
            <AsyncSelect
                name="doc-number"
                id="doc-number"
                components={animatedComponents}
                menuIsOpen={false}
                loadOptions={() => Promise.resolve([])}
                placeholder="Enter Doc Number..."
                className="react-select doc-number-select"
                classNamePrefix="select"
                inputValue={docNumber as string}
                onInputChange={handleChange}
                isClearable={false}
            />
        </div>
    );
};
interface Props {
    owner: IUserOptionValue | null;
    updateOwner?: (approver: IUserOptionValue) => void;
    docNumber: string | null;
    updateDocNumber: React.Dispatch<React.SetStateAction<string>>;
}
const DocumentAdminFilters: React.FC<Props> = ({
    owner,
    updateOwner,
    docNumber,
    updateDocNumber,
}) => (
    <div className="document-approvers-filters">
        <EnterDocNumber docNumber={docNumber} updateDocNumber={updateDocNumber} />
        {updateOwner && <SelectOwner owner={owner || null} updateOwner={updateOwner} />}
    </div>
);
export default DocumentAdminFilters;
