import { IStringObject } from '../../interfaces';

const memo: IStringObject = {
    'documentNumber': 'Type exact document number',
    title: 'Partial or Full Title',
    'owner.name': 'Start typing an employee name/email',
    'data.summary': 'Partial or Full Text in Summary',
    'data.keywords': 'Type one or more keywords',
    'data.to': 'Type one or more email/DL',
    'data.cc': 'Type one or more email/DL',
};

const PLACEHOLDERS = {
    MEMO: memo,
};

export default PLACEHOLDERS;
