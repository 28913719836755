import React from 'react';
import './list-all-tasks-page.scss';
import TaskApproversAdminSetting from '../../components/approval-tasks/admin/TaskApproversAdminSetting';
import AdminSideNavigation from '../../components/admin-page/AdminSideNavigation';
import '../admin-dashboard/AdminDashboard.scss';

const ListAllTasksPage: React.FC = () => (
    <>
        <div className="task-approvers-admin-setting admin-dashboard">
            <AdminSideNavigation />
            <TaskApproversAdminSetting />
        </div>
    </>
);

export default ListAllTasksPage;