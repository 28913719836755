import React from 'react';

import './DashboardTable.scss';
import { useDashboardContext } from '../../contexts/dashboard-provider';
import MyDocumentsTab from './MyDocumentsTab';
import MyApprovalsTab from './MyApprovalsTab';
import MyFavoriteSTab from './MyFavoritesTab';
import MyReviewsTab from './MyReviewsTab';
import MyLatestTab from './MyLatestTab';
import MyGroupApprovalsTab from './MyGroupApprovalsTab';

const DashboardTable: React.FC = () => {
    const { activeTab } = useDashboardContext();
    return (
        <div className="dashboard-table">
            {activeTab === 'MY_APPROVALS' && <MyApprovalsTab />}
            {activeTab === 'MY_DOCUMENTS' && <MyDocumentsTab />}
            {activeTab === 'MY_REVIEWS' && <MyReviewsTab />}
            {activeTab === 'MY_FAVORITES' && <MyFavoriteSTab />}
            {activeTab === 'MY_LATEST' && <MyLatestTab />}
            {activeTab === 'MY_GROUP_APPROVALS' && <MyGroupApprovalsTab />}
        </div>
    );
};

export default DashboardTable;
