import React, { useEffect, useMemo, useState } from "react";
import { Column, usePagination, useTable } from 'react-table';
import { EditSvg } from '../../assets/images';

import Pagination from '../pagination/Pagination';
import { IHRBPInformation, IPaginationData, IUserOptionValue } from "../../interfaces";
import { NoDocumentsFound } from "../General";
import CONSTANTS from "../../constants";
import HRBPModal from "./hrbp-modal/HRBPModal";


const defaultIUserOptionValue: IUserOptionValue = {
    jobTitle: '',
    label: '',
    value: 0,
    role: '',
    roleId: 0,
    activeUser: true,
}

interface Props{
    data : IHRBPInformation[];
    loadingState: any;
    fetchHRBPInfo: (pageIndex: number, pageSize: number) => void;
    pagination: IPaginationData;
}

// eslint-disable-next-line max-lines-per-function
const HRBPInfoTable:React.FC<Props> = ({data ,pagination, fetchHRBPInfo, loadingState}) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedHRBP ,setUpdatedHRBP] = useState<IUserOptionValue>(defaultIUserOptionValue);
    const [updatedEntity, setUpdaedEntity] = useState<string>('');
    const editHandler = async (obj: { country : string }) => {
        const { country } = obj;
        setUpdaedEntity(country);
        setShowModal(true);
    };
    const EditGroup = (row: any) => {
        const { original } = row;
        return (
            // eslint-disable-next-line no-console
            <div className="btn">
                <EditSvg className="edit-icon" onClick={() => editHandler(original)} />
            </div>
        );
    };
    const HRBP_COLUMNS: Column[] = [
        {
            Header: 'Country Code',
            accessor: 'country',
        },
        {
            Header: 'HRBP Name',
            accessor: 'hrbpName',
        },
        {
            Header: 'HRBP Email',
            accessor: 'hrbpEmail',
        },
        {
            Header: 'Edit',
            accessor: 'id',
            Cell: ({ row }) => <EditGroup {...row} />,
        },
    ];
    const columns = useMemo(() => HRBP_COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            loadingState,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
        },
        usePagination,
    );

    useEffect(() => {
        fetchHRBPInfo(pageIndex, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, showModal]);

    return (
        <div>
            <div className="roles-table">
                <div className="table-section">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                        <Pagination
                            nextPage={nextPage}
                            canNextPage={canNextPage}
                            canPreviousPage={canPreviousPage}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            pageSize={pageSize}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                        />
                    )}
                    {page.length === 0 && <NoDocumentsFound heading="No records found" />}
                </div>
                {showModal && (
                <HRBPModal 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    isCreate={false}
                    hrbp={updatedHRBP}
                    setHRBP={setUpdatedHRBP}
                    entity={updatedEntity}
                    setEntity={setUpdaedEntity}
                 />
            )}
            </div>
        </div>
    );
}

export default HRBPInfoTable;