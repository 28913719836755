/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import { Column, Row, usePagination, useTable } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import {
    IInactiveAProverLoading,
    IInactiveApproverAdminDocuments,
    IPaginationData,
} from '../../interfaces';
import STATIC_CONTENT from '../../constants/StaticContent';
import { SortIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { NoDocumentsFound } from '../General';
import { SkeletonNumber, SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';
import "./TaskApproversAdminTable.scss";

const { TASK_APPROVERS } = STATIC_CONTENT;

const DocNumberDocument = (row: Row<IInactiveApproverAdminDocuments>) => {
    const history = useHistory();
    const {
        original: { docNumber, documentId, active },
    } = row;

    const onClick = () => {
        document.body.classList.remove('admin-side-navigation');
        history.push({
            pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
            search: `?doc_id=${documentId}&prev_page_type=ADMIN_TASKS`,
        });
    };

    // Define the URL for the link
    const documentDetailsURL = `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}&prev_page_type=ADMIN_TASKS`;

    if (active)
        return (
            // Use an anchor tag for the link
            <a href={documentDetailsURL} className="link-style" onClick={onClick} onKeyPress={onClick}>
                <SkeletonText text={docNumber} width={200} />
            </a>
        );

    if (active)
        return (
            // Use an anchor tag for the link
            <a href={documentDetailsURL} className="link-style" onClick={onClick} onKeyPress={onClick}>
                <SkeletonText text={docNumber} width={200} />
            </a>
        );

    return (

        <SkeletonText text={docNumber} width={200} />

    );

};
const ActiveStatus = (row: Row<IInactiveApproverAdminDocuments>) => {
    const {
        original: { active },
    } = row;
    return <div className={`status-pill ${active ? 'active' : 'inactive'}`} >
        {`${active ? 'Active' : 'Inactive'}`}
    </div >



};

const COLUMNS: Column<IInactiveApproverAdminDocuments>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 75,
    },
    {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 75,
    },
    {
        Header: 'Approver Name',
        accessor: 'inActiveApprover',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 75,
    },
    {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ row }) => <ActiveStatus {...row} />,
        minWidth: 75,

    }
];

interface Props {
    data: IInactiveApproverAdminDocuments[];
    pagination: IPaginationData;
    loadingState: IInactiveAProverLoading | null;
    fetchDocuments: (pageIndex: number, pageSize: number) => void;
}

const TaskApproversAdminTable: React.FC<Props> = ({ data, pagination, fetchDocuments }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable<IInactiveApproverAdminDocuments>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
        },
        usePagination,
    );

    useEffect(() => {
        fetchDocuments(pageIndex, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize]);

    return (
        <div className="inactive-approvers-admin-table">
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted && (
                                            <SortIcon
                                                className={`sort ${column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                            />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && <NoDocumentsFound heading={TASK_APPROVERS.NO_DOCUMENTS} />}
            </div>
        </div>
    );
};

export default TaskApproversAdminTable;
