const STATIC_CONTENT = {
    TRAVEL_FORM: {
        TRAVEL_REQUEST_FOR : {
            GUEST : 'Guest',
            SELF : 'Self',
            OTHER_EMPLOYEE : 'Other Employee',
        },
        TRAVEL_FORM_NOTE_TO_AUTHOR_IN_APPROVER_SECTION : '* If manager is unavailable, please select alternate approver.',
        ROUND_TRIP : 'Round Trip',
        ONE_WAY : 'One Way',
        MULTI_CITY : 'Multi City',
    },
    CREATE_DOCUMENTS: {
        BUTTON: {
            // NewDocMain Buttons
            SAVE: 'Save',
            NEXT: 'Next',
            PREVIOUS: 'Previous',
            PREVIEW: 'Preview',
            EDIT: 'Edit',
            SUBMIT: 'Submit',
            VIEW_SUBMISSION: 'View Submission',
            NOTIFY_AND_SUBMIT: 'Notify & Submit',
            // WorkflowPanel Buttons
            APPROVERS_BTN: 'Approvers',
            REVIEWERS_BTN: 'Reviewers',
            CHECK_IN: 'Check-in',
            CHECK_OUT: 'Check-out',
            ADD_TO_CHANGE: 'Add To Change',
            CANCEL: 'Discard Changes',
        },
        TOAST: {
            ERROR_MSG: {
                // EditAttachment Error Toast Messages
                UNABLE_TO_UPLOAD: 'Unable to upload',
                UNABLE_TO_DELETE: 'Unable to delete',
                FILE_NOT_SUPPORTED: 'File type is not allowed. Supported file types are: ',
                FILE_LARGER_THAN_25: 'File size is more than 25MB',
                INVALID_FILENAME:
                    'File names can only use alphanumeric characters, underscores, hyphens and period.',
                CHECK_IN: 'Could not check in',
                UNABLE_TO_UPDATE_ATTACHMENT: 'Unable to update attachment'
            },
            SUCCESS_MSG: {
                CHECK_IN: 'Successfully checked in',
            },
        },
        // EditAttachment Note
        NOTE: '* The maximum file size should not exceed 25 MB. Supported file types are: ',
        NOTE_END:
            '* File names can only use alphanumeric characters, underscores, hyphens and period.',
        PRB_FORM: '* This page is optional, click ‘Next’ to skip',
        LEADERSHIP_FORM_APPROVER_POINTS: {
            INDIA_OR_CHINA:
                '* If the employee nominated for promotion is in India/China, please select Ashish Kumar <akumar@enphaseenergy.com>',
            REST_OF_ASIA:
                '* If the employee nominated for promotion is in EMEA/ANZ/Rest of Asia, please select Rajesh Toranagatti <rtoranagatti@enphaseenergy.com>',
            AMERICA:
                '* If the employee nominated for promotion is in Americas (US/Canada/LATAM/Mexico/Brazil/Puerto Rico), please select Roy Malatesta <rmalatesta@enphaseenergy.com>',
            PROMOTION_TO_DIRECTOR:
                '* For promotions to Director and above roles, please select Sunil Thamaran <sthamaran@enphaseenergy.com>',
        },
    },
    DASHBOARD: {
        TABS: {
            MY_APPROVALS: 'My Approvals ',
            MY_DOCUMENTS: 'My Documents ',
            MY_FAVORITES: 'My Favourites ',
            MY_REVIEWS: 'My Reviews ',
            MY_LATEST: 'Latest Documents',
            MY_GROUP_APPROVALS: 'My Group Approvals ',
        },
        NO_DOCUMENT: {
            MY_APPROVALS: 'No Documents for Approval',
            MY_DOCUMENTS: 'No Documents Found',
            MY_FAVORITES: 'No Favorites Found',
            MY_REVIEWS: 'No Documents for Review',
            MY_LATEST: 'No Documents Found',
            DRAFT_DCOS: 'No Draft DCOs found',
        },
    },
    DOCUMENT_INFO: {
        BUTTON: {
            REVERT: 'Revert',
            DISCARD: 'Discard',
            EDIT: 'Edit',
            SHARE: 'Share Link',
            DELETE: 'Delete',
        },
        LABEL: {
            TITLE: 'Title',
            DESCRIPTION: 'Description',
            DOCUMENT_NUMBER: 'Document Number',
            AUTHOR: 'Author',
            OWNER: 'Owner',
            ITEM_CREATOR: 'Item Creator',
            VERSION: 'Version',
            CONFIDENTIAL: 'Confidential',
        },
        TOAST: {
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
                CHECK_OUT: 'Could not check out',
            },
            SUCCESS_MSG: {
                REVERTED: 'Reverted to previous version',
                DISCARDED: 'Document is deleted successfully',
                DELETED: 'DCO is deleted successfully',
                CHECK_OUT: 'Successfully checked out',
            },
        },
    },
    DOCUMENT_OWNER_CHANGE: {
        OWNER: {
            WARNING: 'Please Select Owner',
        },
        TOAST: {
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
            },
            WARNING_MSG: {
                DUPLICATE: 'Error in Changing Owner',
            },
            SUCCESS_MSG: {
                AUTHOR_CHANGED:
                    'Owner Changed successfully and New Owner will be responsible for editing',
            },
        },
    },
    DOCUMENT_ACTIONS: {
        BUTTON: {
            WITHDRAW: 'Withdraw',
            REVISE: 'Revise',
            REPUBLISH: 'Republish',
            APPROVE: 'Approve',
            REJECT: 'Reject',
            REASSIGN: 'Reassign',
            ADD_APPROVER: 'Add Approver',
            REVIEWED: 'Reviewed',
            // Add ApproverModal
            SAVE: 'Save',
            CANCEL: 'Cancel',
            EDIT: 'Edit Document',
            EDIT_CHANGE_ORDER: 'Edit DCO',
            TERMINATE: 'Terminate Contract',
            OBSOLETE: 'Obsolete',
            CHECKOUT: 'Check-out',
        },
        TOAST: {
            SUCCESS_MSG: {
                DOCUMENT_WITHDRAWN: 'Document Withdrawn',
                DOCUMENT_REVIEWED: 'Document Reviewed',
                SUCCESS: 'Success',
                CHANGE_ORDER_WITHDRAWN: 'DCO Withdrawn',
                CHANGE_ORDER_REVIEWED: 'DCO Reviewed',
            },
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
                UNAUTHORIZED: 'Unauthorized, could not save',
            },
        },
    },
    DOC_COMMENTS: {
        BUTTON: {
            SAVE: 'Save',
            CANCEL: 'Cancel',
            EDIT: 'Edit',
            DELETE: 'Delete',
            SUBMIT_COMMENT: 'Submit Comment',
        },
        LABEL: {
            WRITE_A_COMMENT: 'Write a Comment',
        },
        TOAST: {
            ERROR_MSG: {
                UNABLE_TO_DELETE: 'Unable to delete',
                SOMETHING_WENT_WRONG: 'Something went wrong while submitting comment',
            },
            WARNING_MSG: {
                ADD_COMMENT_FIRST: 'Please write a comment before submitting',
            },
        },
    },
    USER_SEARCH: {
        LABEL: {
            SELECT_USERS: 'Select Users',
            ADD_EXTERNAL_APPROVERS: 'Add External Approvers',
        },
    },
    ADD_APPROVER: {
        BUTTON: {
            SAVE: 'Save',
            CANCEL: 'Cancel',
        },
    },
    HEADER: {
        BUTTON: {
            DOC_TYPES: 'Document Types',
            CREATE_DOC: 'Create Document',
            CREATE_CHANGE: 'Create DCO',
            REPORTS: 'Reports',
            CREATE: 'Create',
            // User Header
            SETTINGS: 'Settings',
            CLEAR: 'Clear All',
            LOG_OUT: 'Logout',
        },
        LABEL: {
            DOC_MANAGEMENT_SYSTEM: 'Document Management System',
            ANONYMOUS: 'Anonymous',
        },
    },
    FOOTER: {
        LABEL: {
            DESCRIPTION: 'Copyright© 2023 Enphase Energy. All rights reserved.',
        },
    },
    NOTIFICATIONS: {
        BUTTON: {
            ADD_FILTERS: 'Add Filters',
            DISCARD: 'Discard',
            SAVE: 'Save',
            CANCEL: 'Cancel',
            DONE: 'Done',
        },
        LABEL: {
            NOTIFICATION_TYPE: 'Choose notification type',
            EVENT_TRIGGER: 'Event Trigger',
            MAIL_NOTIFICATION: 'Mail Notification',
            ALERT_NOTIFICATION: 'Alert Notification',
        },
    },
    DELEGATION_SETTINGS: {
        BUTTON: {
            SAVE: 'Save',
        },
        LABEL: {
            DELEGATION_SETTINGS: 'Delegation Settings',
            DELEGATED_FOR_ME: 'Delegation For me',
            DELEGATED_BY_ME: 'Delegated by me',
            DELEGATED_FROM: 'Delegated From',
            DELEGATE_TO: 'Delegate To',
            FROM_DATE: 'From Date',
            TO_DATE: 'To Date',
            DELEGATED_TO: 'Delegated To',
            FROM: 'From',
            TO: 'To',
        },
        TOAST: {
            SUCCESS_MSG: {
                DELEGATED: 'Delegated',
                UPDATED: 'Delegation Updated',
            },
            ERROR_MSG: {
                UNABLE_TO_DELETE: 'Unable to Delete',
                INVALID_DATES: 'Invalid dates',
                USER_ALREADY_DELEGATED: 'User already delegated',
                MULTIPLE_DELEGATIONS_FOR_DELEGATOR:
                    'You already have a delegation during the chosen period',
                DELEGATEE_UNAVAILABLE: 'User is not available during this period',
                SOMETHING_WENT_WRONG: 'Something went wrong',
                USERS_NOT_CHOSEN: 'Delegator / Delegatee not chosen',
                USERS_SAME: 'Delegator & Delegatee cannot be same',
            },
        },
    },
    DOC_CREATE: {
        LABEL: {
            HEADING: 'Choose and Create the document',
            MOBILE_HEADING: 'Create Document',
            NO_DOC_AVAILABLE: 'No documents types available',
            DOCS_AVAILABLE: '  document types available',
            TITLE: 'Title',
            TITLE_MEMO: 'Title of the memo',
            DESCRIPTION: 'Description',
            DESCRIPTION_MEMO: 'Description of the memo',
            SUMMARY: 'Summary',
            SUMMARY_MEMO: 'Summary of the memo (optional)',
            KEYWORDS: 'Keywords',
            KEYWORDS_MEMO: 'Relevant Keywords about the memo (optional)',
            TO_CC: 'To/CC',
            EMAIL_DISTRIBUTION_MEMO: 'Email Distribution list for memo (optional)',
            CONFIDENTIAL: 'Confidential',
            MEMO_ACCESS:
                'If selected, then memo access is restricted to Author, Manager,Approver(s) & Reviewer(s). Default:Unselected',
            ADD_MEMO_ATTACHMENT: 'Add Memo Document Attachment',
            ADD_APPROVER_OR_REVIEWER: 'Add Approver(s) / Reviewer(s)',
            DOWNLOAD_SAMPLE_TEMPLATE: 'Download sample template',
            SELECT_DOCUMENT_TYPE: 'Select a document type to create document',
            ITBR_TITLE:
                'Title to represent the project and requirements <project name> - IT Business Requirements',
            ITBR_DESCRIPTION: 'Brief summary of the project',
            ITBR_PROJECTNAME: 'Name of the project for which the requirements are being captured',
            PRBNOMINATION_1:
                "Title & Description will be autofilled once nominated employee's name is entered",
            PRBNOMINATION_2:
                'Enter employee details (such as current grade, proposed grade, overall experience with Enphase, manager name etc.)',
            PRBNOMINATION_3:
                'Select proposed career ladder (i.e., Individual Contributor/IC or Manager) ',
            PRBNOMINATION_4:
                'Provide examples and instance of behaviours exhibited under each competency provided in the table',
            PRBNOMINATION_5: 'Valid details and click ‘Next’',
            PRBNOMINATION_6: 'Optional: attach support performance documents; click ‘Next’',
            PRBNOMINATION_7: 'Select approvers for all levels ',
            PRBNOMINATION_8: 'Submit form for approval',
            LEADERSHIPFORM_1: 'Click Create',
            LEADERSHIPFORM_2:
                "Title & Description will be auto-filled after nominated employee's name is entered",
            LEADERSHIPFORM_3:
                'Enter employee details (check workday for information you don’t have handy.)',
            LEADERSHIPFORM_4: 'Provide examples and 360 feedback summary for each competency',
            LEADERSHIPFORM_5: 'Valid details and click ‘Next’',
            LEADERSHIPFORM_6:
                'Optional: attach support performance documents, email from NEO etc.; click ‘Next’',
            LEADERSHIPFORM_7: 'Select HR Leader',
            LEADERSHIPFORM_8: 'Click Submit',
        },
        BUTTON: {
            CREATE: 'Create',
            DCO_CONTROLLED: 'DCO Controlled Documents',
            NON_CONTROLLED: 'Non Controlled Documents',
        },
    },

    DOC_TYPE_REVISE: {
        BUTTON: {
            MINOR: 'Minor',
            MAJOR: 'Major',
            REVISE: 'Revise',
            CUSTOM: 'Custom',
            RERELEASE: 'Re-release',
        },
        LABEL: {
            SELECT_VERSION: 'Select version type',
        },
        TOAST: {
            SUCCESS_MSG: {
                REVISED_SUCCESSFULLY: 'Revised Successfully',
            },
            OBSOLETE_MSG: {
                OBSOLETE_STARTED: 'Document pushed to Obsolete Phase',
                CHANGE_ORDER_OBSOLETE: 'DCO pushed to Obsolete Phase',
            },
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
            },
        },
    },
    CONFIRMATION: {
        BUTTON: {
            CANCEL: 'Cancel',
        },
    },
    DOC_TYPE_APPROVAL: {
        BUTTON: {
            ADD_NEW_STAGE: 'Add New Stage',
        },
    },
    DOC_TYPE_PROVIDER: {
        TOAST: {
            SUCCESS_MSG: {
                SUCCESSFUL: 'Successful',
                DOCUMENT_PUBLISHED: 'Document Type Published',
            },
            ERROR_MSG: {
                UNABLE_TO_PUBLISH: 'Unable to publish Document Type',
                SOMETHING_WENT_WRONG: 'Something went wrong',
                CODE_ALREADY_EXIST: 'Code already exist',
            },
        },
    },
    DOC_TYPE_CREATE_INFO: {
        BUTTON: {
            SAVE_DRAFT: 'Save Draft',
            PUBLISH: 'Publish',
            REVISE: 'Revise',
        },
        LABEL: {
            DOCUMENT_TYPE_NAME: 'Document Type Name',
            DESCRIPTION: 'Description',
            NEW_DOC_TYPE: 'New Document Type',
        },
        TOAST: {
            SUCCESS_MSG: {
                REVISED_SUCCESSFULLY: 'Revised Successfully',
            },
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
            },
        },
        NOTE: ' Title & Description will be added for all document types',
    },
    DOC_TYPE_ATTACHMENT: {
        LABEL: {
            DISPLAY_NAME: 'Display Name',
            INTERNAL_NAME: 'Internal Name',
            IS_MANDATORY: 'Is Mandatory',
            ALLOW_MULTIPLE: 'Allow Multiple',
            ALLOWED_FILE_TYPES: 'Allowed File Types',
            ALLOW_DRAG_DROP: 'Allow Drag & Drop',
            ALLOW_FILE_TYPE_ATTACHMENT: 'Allow File Type Attachments',
            DELETE: 'Delete',
        },
        BUTTON: {
            ADD_ATTACHMENT: 'Add Attachment',
        },
    },
    DOCUMENT_TYPES: {
        LABEL: {
            DOCUMENT_TYPES: 'Document Types',
            SELECT_DOCUMENT: 'Select any document type to view or modify details',
        },
        BUTTON: {
            CREATE_NEW: 'Create New Document Type',
        },
    },
    REPORT_LIST: {
        BUTTON: {
            CREATE_NEW: 'Create New Report'
        },
        TABS: {
            CUSTOM: 'Custom',
            STANDARD: 'Standard',
        },
    },
    FILTERS: {
        BUTTON: {
            RESET: 'Reset',
            SHOW_RESULTS: 'Show Results',
        },
        LABEL: {
            FILTERS: 'Filter results',
            NO_RESULT: 'No Results Found',
        },
    },
    DOCUMENT_SEARCH: {
        BUTTON: {
            SEARCH: 'Search',
            CLEAR: 'Reset',
        },
        LABEL: {
            SEARCH_IN_ATTACHMENT: 'Search in Attachment',
        },
    },
    FORWARD_DOC: {
        BUTTON: {
            SAVE: 'Save',
            CANCEL: 'Cancel',
        },
        LABEL: {
            SELECT_USER: 'Select User',
            LENGTH_CONSTRAINT: 'Length should be greater than 3 characters',
        },
        TOAST: {
            ERROR_MSG: {
                SOMETHING_WENT_WRONG: 'Something went wrong',
            },
        },
    },
    ACTION_MODAL: {
        LABEL: {
            WARNING: 'You will not be able to change it if you submit.',
            WRITE_A_COMMENT: 'write a comment',
        },
        BUTTON: {
            SUBMIT: 'Submit',
        },
    },
    DOCTYPE_DOCUMENT_DETAIL: {
        BUTTON: {
            ADD_NEW_FIELD: 'Add New Field',
        },
    },
    SEARCH: {
        BUTTON: {
            SEE_MORE: 'Show More',
            SEE_LESS: 'Show Less',
        },
        NO_DOCUMENT: 'No Documents Found',
    },
    DOCUMENT_PREVIEW: {
        HEADING: 'Document Preview',
        NO_ATTACHMENTS: 'No Attachments Added!',
        REVIEWERS: 'Reviewers',
        DOCUMENT_DETAILS: 'Document Details',
        NO_APPROVERS: 'No Approvers Added!',
        BUTTON: {
            CLOSE: 'Close',
        },
    },
    SETTINGS_PAGE: {
        HEADING: 'Settings',
        ADMIN_HEADING: 'Admin Settings',
        TABS: {
            NOTIFICATIONS: 'Notifications',
            DELEGATION: 'Delegation',
            INACTIVE_APPROVERS: 'Inactive Approvers',
            INACTIVE_APPROVERS_ADMIN: 'Inactive Approvers',
            DMS_GROUP: 'DMS Group',
            APPROVAL_TASKS: 'Approval Tasks',
            DOCUMENT: 'Document',
            SUNSET_DOCUMENTS: 'Sunset Documents',
            HRBP_INFO : 'HRBP Information',
            DELEGATIONS: 'Delegations',
        },
        NOTIFICATION_TAB_TIP: 'By Default you will receive notification for all relevant events.',
    },
    INACTIVE_APPROVERS: {
        LABEL: {
            SELECT_OWNER: 'Select Owner',
            SELECT_INACTIVE_APPROVER: 'Select Inactive User',
        },
        BUTTONS: {
            REPLACE: 'Replace',
            REMOVE: 'Remove',
        },
        NO_DOCUMENTS: 'No Inactive Approvers with Documents',
    },
    TASK_APPROVERS: {
        LABEL: {
            SELECT_OWNER: 'Enter Owner',
            SELECT_APPROVER: 'Enter Approver',
            INPUT_DOCNUMBER: 'Enter Doc Number',
        },
        BUTTONS: {
            REPLACE: 'Replace',
            REMOVE: 'Remove',
        },
        NO_DOCUMENTS: 'No Approvers with Documents',
    },
    HRBP_INFO_SETTINGS : {
        LABEL: {
            SELECT_HRBP: 'Enter HRBP',
        },
        NO_ENTRIES: 'Enter a valid HRBP',
    },
    DMS_GROUPS: {
        LABEL: {
            CREATE_A_GROUP: 'Create a DMS Group',
            UPDATE_A_GROUP: 'Update a DMS Group',
        },
        BUTTONS: {
            CREATE: 'Create',
            UPDATE: 'Update',
        },
    },
    APPROVAL_TASKS: {
        LABEL: {
            SELECT_OWNER: 'Select Owner',
            SELECT_INACTIVE_APPROVER: 'Select User',
        },
        BUTTONS: {
            REPLACE: 'Replace',
            REMOVE: 'Remove',
        },
        NO_DOCUMENTS: 'No Pending Approval Tasks found',
    },
    DOCUMENNT_ADMIN: {
        LABEL: {
            ENTER_DOC_NUMBER: 'Enter Doc Number',
            ENTER_OWNER: 'Enter Owner',
        },
    },
    DCO: {
        ADDED_BUT_UNLOCKED: 'This document version is part of DCO:',
        ADDED_AND_LOCKED: 'This document version has been locked by DCO:',
        PUBLISHED_DOCUMENT: 'This document version got published through DCO:',
        PUBLISHED_DOCUMENT_ADDED_TO_DCO:
            'This published document has been added to an unpublished DCO:',
        ALLOWED_DCO_CHILD_NOTE:
            'This document can only be published through a DCO, please save the details and add this document to DCO.',
        LOCK: {
            DOCUMENT_LOCKED: ' has been locked successfully',
            DOCUMENT_UNLOCKED: ' has been unlocked successfully',
        },
        TITLE: 'Change Number',
        CREATOR: 'DCO Creator',
        SUBMITTER: 'DCO Submitter',
    },
    RATING_PRB_NOMINATION_AND_LEADERSHIP: {
        doesNotMeetExpectations: 'Does not meet expectations',
        meetsExpectations: 'Meets Expectations',
        exceedsExpectations: 'Exceeds Expectations',
        SrMgr: 'Sr. Mgr',
        Dir: 'Dir',
        SrDir: 'Sr. Dir',
        VP: 'VP',
        SrVP: 'Sr. VP',
    },
    SPEC_DEPARTMENTS: [
        {
            value: 'Security Documents',
            label: 'SEC',
        },
        {
            value: 'Enphase QA',
            label: 'EQA',
        },
        {
            value: 'Chief Technical Office',
            label: 'CTO',
        },
        {
            label: 'Product Verification',
            options: [
                {
                    value: '-- Hardware Product Verification',
                    label: 'HPV',
                },
                {
                    value: '-- Software Verification Test',
                    label: 'SVT',
                },
                {
                    value: '-- Quality Failure Analysis',
                    label: 'QFA',
                },
            ],
        },
        {
            label: 'Manufacturing Test and Tools',
            options: [
                {
                    value: '-- Manufacturing Test and Tools',
                    label: 'MTT',
                },
                {
                    value: '-- Manufacturing Test and Tools New Product',
                    label: 'TRB',
                },
                {
                    value: '-- Manufacturing Test and Tools Sustaining',
                    label: 'TCR',
                },
            ],
        },
        {
            value: 'Sales Operations',
            label: 'SOP',
        },
        {
            label: 'Engineering',
            options: [
                {
                    value: '-- ASIC Engineering',
                    label: 'AEG',
                },
                {
                    value: '-- Engineering Services',
                    label: 'ENS',
                },
                {
                    value: '-- Hardware Engineering',
                    label: 'HEG',
                },
                {
                    value: '-- Software Engineering',
                    label: 'SEG',
                },
                {
                    value: '-- Mechanical Engineering',
                    label: 'MEG',
                },
                {
                    value: '-- Project Doc List',
                    label: 'PRJ',
                },
            ],
        },
        {
            label: 'Operations',
            options: [
                {
                    value: '-- Supplier Quality Assurance',
                    label: 'SQA',
                },
                {
                    value: '-- Operations',
                    label: 'OPS',
                },
            ],
        },
        {
            value: 'Product Marketing',
            label: 'MKT',
        },
        {
            value: 'Human Resources and Facilities',
            label: 'HRF',
        },
        {
            value: 'Customer Support',
            label: 'CSO',
        },
        {
            value: 'Finance and IT',
            label: 'FIN',
        },
        {
            value: 'IT',
            label: 'ITS',
        },
        {
            value: 'Strategic Initiatives & Products',
            label: 'SIP',
        },
        {
            value: 'Systems Engineering',
            label: 'SYS',
        },
        {
            label: 'Tech Pubs',
            options: [
                {
                    value: '-- Tech Pubs - Process',
                    label: 'TEP',
                },
                {
                    value: '-- Tech Pubs - Data Sheet',
                    label: 'DSH',
                },
                {
                    value: '-- Tech Pubs - Installation and operation manuals',
                    label: 'IOM',
                },
                {
                    value: '-- Tech Pubs - Installation guide',
                    label: 'ING',
                },
                {
                    value: '-- Tech Pubs - User guides',
                    label: 'USG',
                },
                {
                    value: '-- Tech Pubs - Tech briefs',
                    label: 'TEB',
                },
                {
                    value: '-- Tech Pubs - White papers',
                    label: 'WHP',
                },
                {
                    value: '-- Tech Pubs - User manual',
                    label: 'USM',
                },
                {
                    value: '-- Tech Pubs - Technical Note',
                    label: 'TEN',
                },
            ],
        },
    ],
    SPEC_CATEGORIES: [
        {
            value: 'Document',
            label: 'Document',
        },
        {
            value: 'Form or Template',
            label: 'Form or Template',
        },
        {
            value: 'Quality Manual',
            label: 'Quality Manual',
        },
        {
            value: 'Quality Record',
            label: 'Quality Record',
        },
        {
            value: 'Work Instruction',
            label: 'Work Instruction',
        },
        {
            value: 'Uncategorized',
            label: 'Uncategorized',
        },
    ],
    CLCM_SUB_CATEGORIES: [

        {
            label: 'Sales',
            options: [
                {
                    value: 'Grid Services',
                    label: 'SALE',
                },
            ],
        },
    ],
    CLCM_CATEGORIES: [
        {
            value: 'CNDA',
            label: 'NDA',
        },
        {
            value: 'Sales',
            label: 'Sales',
        }
    ],
    CLCM_SUB_CATEGORIES2: [{
        label: 'Grid Services',
        options: [
            {
                value: 'API',
                label: 'APDP',
            }
        ]
    }],
} as const;

export default STATIC_CONTENT;