import { IReviewers } from '../interfaces';

export type IDocumentReviewersAction =
    | { type: 'update'; value: IReviewers[] }
    | { type: 'add-reviewer'; value: IReviewers }
    | { type: 'delete'; index: number }
    | {
          type: 'update-user';
          index: number;
          value: IReviewers;
      };

export const documentReviewersReducer = (
    state: IReviewers[],
    action: IDocumentReviewersAction,
): IReviewers[] => {
    switch (action.type) {
        case 'update': {
            const tempState: IReviewers[] = [];
            return tempState.concat(action.value);
        }
        case 'add-reviewer':
            return [...state, action.value];
        case 'delete':
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case 'update-user':
            return [
                ...state.slice(0, action.index),
                { ...state[action.index], ...action.value },
                ...state.slice(action.index + 1),
            ];
        default:
            return state;
    }
};
