/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines-per-function */
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';

import Loader from 'react-loader-spinner';
import { Column, useExpanded, usePagination, useTable } from 'react-table';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { createDocument, getDocumentsDetails, toggleDocumentLock } from '../../../API/document';
import { getDocuments } from '../../../API/fields';
import CONSTANTS from '../../../constants';
import { IOptionValue } from '../../../interfaces';
import { IDocReferenceInfo, IDocumentData, IField } from '../../../interfaces/document';
import './DocumentReference.scss';

import { getDocTypes } from '../../../API/document-type';
import {
    CaretDownSvg,
    CaretRightSvg,
    DocumentIconSvg,
    PlusIconSvg,
    RemoveIconSvg,
    UnlockIconSvg
} from '../../../assets/images';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { useDocumentContext } from '../../../contexts/document-provider';
import { useEventsHeight } from '../../../contexts/events-height-provider';
import { useTaskDetailContext } from '../../../contexts/task-details-provider';
import { useGAEvents } from '../../../hooks/useGoogleAnalytics';
import { IDocumentAction } from '../../../reducers/document-reducer';
import CopyText from '../../CopyText';
import { SkeletonText } from '../../Skeleton';
import { DownloadAttachment } from '../../attachments/view/ViewAttachments';
import Button from '../../button/Button';

const animatedComponents = makeAnimated();
interface Props {
    field: IField;
    isDisabled: boolean;
    value: IDocReferenceInfo[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const DocumentReference: React.FC<Props> = ({
    field,
    isDisabled,
    value,
    error,
    documentDispatch,
}) => {
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const [doctypeselection,setdoctypeselection] = useState(false);
    const [doctypeloading,setdoctypeloading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [selectedDocument, setSelectedDocument] = useState<IDocumentData | null>(null);
    const [statedocList, setstatedocList] = useState<IOptionValue>();
    const [allowedDocTypeIds, setallowDocTypeIds] = useState<number[]>([]);
    const { documentId, isFieldsDataSaved } = useDocumentContext();
    const isDCOTrue1 = useDocumentContext().docTypeInfo;
    const isDCOTrue2 = useTaskDetailContext().docTypeInfo;
    const isDCO = isDCOTrue1.isDCO || isDCOTrue2.isDCO;
    const { triggerEvent } = useGAEvents();
    const history = useHistory();
    const location = useLocation();
    const [disablebutton, setdisablebutton] = useState(false);
    const [latestrelationArr, setlatestrelationArr] = useState<any[]>([]);
    const [isModalOpen, setisModalOpen] = useState(false);
    const { updateHeight } = useEventsHeight();

    const [disableAddButton, setDisableAddButton] = useState(false);
    const documentList = useMemo(() => value.map((val) => {
        const documentAttachmentLength = val.activeDocumentVersion?.documentAttachment?.length || 0;
        const fileLength = val.activeDocumentVersion?.data?.file?.length || 0;
        return {
            ...val,
            filesCount: documentAttachmentLength + fileLength,
        };
    }), [value]);

    const docVersionInitialValue = {
        id: 0,
        majorVersionNumber: 0,
        minorVersionNumber: 0,
        data: {},
        state: '',
        documentId: 0,
        documentTypeVersionId: 0,
        versionOwnerId: 0,
        documentAttachment: [],
        documentTypeVersion: {},
        approvalDeadline: 0
    }
    const docReferenceDefaultValues = {
        id: 0,
        documentNumber: '',
        title: '',
        documentLock: false,
        state: '',
        activeVersionId: 0,
        approvedVersionId: 0,
        ownerId:0,
        activeDocumentVersion: docVersionInitialValue,
        approvedDocumentVersion: docVersionInitialValue
    }
    const queryObj = qs.parse(search, { ignoreQueryPrefix: true });

    const getDocumentsDetail = async (
        documents: (string | number)[],
    ): Promise<{
        data: IDocReferenceInfo;
    }> => {
        const docList = documents;
        const documentsDetailsRes = await getDocumentsDetails(docList);
        return {
            data: documentsDetailsRes?.data[0] ? documentsDetailsRes.data[0] : docReferenceDefaultValues,
        };
    };

    const getDocumentsDetailByState = async () => {
        const list: (number| string)[] = [];
        value.forEach((currDoc, idx) => {
            list.push(currDoc.activeVersionId);
        })

        if (queryObj.newDocversionId) {
            if(!list.includes(Number(queryObj.newDocversionId))){
                const res = await getDocumentsDetail([Number(queryObj.newDocversionId)]);

                if (documentDispatch) {
                    documentDispatch({
                        type: 'document-reference',
                        inputName: field.name,
                        value: [...value, res?.data] as IDocReferenceInfo[],
                    });
                }
            }
        }

        const params = new URLSearchParams(search);        
        // Delete the newDocversionId parameter.
        params.delete("newDocversionId");
        history.push({
            search: params.toString()
        })
    };

    const promiseOptions = async (inputValue: string): Promise<IDocumentData[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const alreadyAddedDocList: number[] = [];
                value.forEach((currDoc, idx) => {
                    alreadyAddedDocList.push(currDoc?.activeVersionId);
                });

                const dynamicOptions = await getDocuments(
                    field.id,
                    inputValue,
                    allowedDocTypeIds,
                    alreadyAddedDocList,
                );
                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                    resolve(dynamicOptions.dynamicData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const handleAddDocument = async () => {
        setDisableAddButton(true);
        const docActiveVersionId = selectedDocument?.documentVersionId;
        let res;
        if(docActiveVersionId) res = await getDocumentsDetail([docActiveVersionId]);
        if (res?.data.state !== 'DRAFT') {
            toast.error(`There is no draft version of ${res?.data.documentNumber}`);
        }
        setDisableAddButton(false);
        setSelectedDocument(null);
        if (res?.data.documentLock) {
            if(res.data.parentDCOInfo){
                setlatestrelationArr(res.data.parentDCOInfo);
            }
            if(res.data?.parentDCOInfo?.length){
                toast.error(`${res.data.documentNumber} is already locked by ${res.data?.parentDCOInfo[0]?.documentNumber}`);
            }
        }
        if (!res?.data.activeDocumentVersion.checkedIn) {
            toast.error(`Please check in the document ${res?.data.documentNumber} before submitting the DCO`);
        }
        if (documentDispatch) {
            documentDispatch({
                type: 'document-reference',
                inputName: field.name,
                value: [...value, res?.data] as IDocReferenceInfo[],
            });
        }
    };

    const handleRemoveDocument = async (
        activeVersionId: number,
        currDocId: number,
        documentLock: boolean,
    ) => {        
        const afterRemoval = value.filter((currItem, idx) => currItem?.activeVersionId !== activeVersionId);        
        if (documentDispatch) {
            documentDispatch({
                type: 'document-reference',
                inputName: field.name,
                value: afterRemoval as IDocReferenceInfo[],
            });
        }

        await toggleDocumentLock(false, currDocId, documentId);
    };

    const handleChange = async (option: any) => {
        setSelectedDocument(option);
    };
    
    const DocNumberForLink = (row: any) => {
        const {
            original: { documentNumber, id },
        } = row;
        return (
            <Link
                className="link-style"
                target="_blank"
                style={{ textDecoration: 'none' }}
                to={{
                    pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${id}${isDCO ? '&latestVersion=true' : ''}`,
                }}
            >
                <SkeletonText text={documentNumber} width={200} />
            </Link>
        );
    };

    const NewVersion = (row: any) => {
        const {
            original: { activeDocumentVersion , approvedDocumentVersion},
        } = row;
        const { majorVersionNumber, minorVersionNumber, versionState } = activeDocumentVersion;

        if(activeDocumentVersion?.id === approvedDocumentVersion?.id){
            return(
                <>
                    -
                </>
            );
        }
        return (
            <>
                <div className='versionNumber'>{majorVersionNumber}.{minorVersionNumber}</div>
            </>
        )
    };

    const OldVersion = (row: any) => {
        const {
            original: { approvedDocumentVersion },
        } = row;
        if (approvedDocumentVersion) {     
            const { majorVersionNumber, minorVersionNumber, versionState } = approvedDocumentVersion;      
            return (
                <>
                    <div className='versionNumber'>{majorVersionNumber}.{minorVersionNumber}</div>
                </>
            )
        }
        return(
            <>
                -
            </>
        );
    };

    const CheckedIn = (row : any) => {
        const {
            original: { activeDocumentVersion , approvedDocumentVersion},
        } = row;
        if(activeDocumentVersion?.id === approvedDocumentVersion?.id){
            return (
                <span className='No'> NO </span>
            )
        }
        return (
            <>
                {activeDocumentVersion.checkedIn ? (
                    <span className='Yes'> YES </span>
                ) : (
                    <span className='No'> NO </span>
                )}
            </>
        );
        
    };

    const FilesColumn = ({ value }: { value: number }) => (
        <div className="files-column">
            {value ? (
                <>
                    {value} <DocumentIconSvg />{' '}
                </>
            ) : (
                <>-</>
            )}
        </div>
    );

    interface ILockProps {
        docId: number;
        documentLock: boolean;
        documentNumber: string;
    }
    const LockElement: React.FC<ILockProps> = ({docId, documentLock, documentNumber}) => {
        const [locking, setLocking] = useState<boolean>(false);
        const handleToggleDocumentLock = async () => {
            setLocking(true);
            const toastMsg = documentLock ? STATIC_CONTENT.DCO.LOCK.DOCUMENT_UNLOCKED : STATIC_CONTENT.DCO.LOCK.DOCUMENT_LOCKED;
            const lockRes = await toggleDocumentLock(!documentLock, docId, documentId);
            if(lockRes?.apiStatus === 'SUCCESS') {
                toast.success(documentNumber + toastMsg);
                setLocking(false);
            } else {
                toast.error(CONSTANTS.ERROR_406);
                setLocking(false);
            }    
        };

        return (
            <span
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
                data-tip
                data-for="lock-action"
                className="action-icon"
                onClick={() => handleToggleDocumentLock()}
            >
                {locking ? (
                    <Loader type="Oval" color="#000" height={14} width={14} />
                ) : (
                    <UnlockIconSvg />
                )}
            </span>
        );
    }

    const ActionColumn = (element: any) => {
        const {
            element: { id: currDocId, activeVersionId, documentLock, documentNumber },
        } = element;

        return (
            <div className="action">
                {/* <span
                    aria-hidden="true"
                    style={{ cursor: 'pointer', marginTop: '10px' }}
                    data-tip
                    data-for="view-action"
                    className="action-icon"
                    // onClick={() => handleViewDocument(activeVersionId)}
                >
                    <Link
                        className="link-style"
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                        to={{
                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                            search: `?doc_id=${currDocId}`,
                        }}
                    >
                        <ViewIconSvg />
                    </Link>
                </span> */}
                {/* <LockElement docId={currDocId} documentLock={documentLock} documentNumber={documentNumber}/> */}
                <span
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                    data-tip
                    data-for="remove-action"
                    className="action-icon"
                    onClick={() =>
                        handleRemoveDocument(activeVersionId, currDocId, documentLock)
                    }
                >
                    <RemoveIconSvg />
                </span>
                {/* <ReactTooltip place="top" type="dark" effect="solid" id="view-action">
                    {' '}
                    <span>View</span>
                </ReactTooltip> */}
                <ReactTooltip place="top" id="remove-action">
                    {' '}
                    <span>Remove</span>
                </ReactTooltip>
                {/* <ReactTooltip place="top" type="dark" effect="solid" id="lock-action">
                    {' '}
                    <span>{documentLock ? 'Unlock' : 'Lock'}</span>
                </ReactTooltip> */}
            </div>
        );
    };

    const FIELDS_COLUMN: Column[] = [
        {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <CaretDownSvg /> : <CaretRightSvg />}
                </span>
            ),
        },
        {
            Header: 'Document Number',
            accessor: 'documentNumber',
            Cell: ({ row }) => <DocNumberForLink {...row} />,
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Files',
            accessor: 'filesCount',
            Cell: ({ value }) => <FilesColumn value={value} />,
        },
        {
            Header: 'Old Version',
            accessor: 'approvedDocumentVersion',
            Cell: ({ row }) => <OldVersion {...row} />,
        },
        {
            Header: 'New Version',
            accessor: 'activeDocumentVersion',
            Cell: ({ row }) => <NewVersion {...row} />,
        },
        {
            Header: 'Checked In',
            accessor: 'activeDocumentVersion.checkedIn',
            Cell: ({row}) => <CheckedIn {...row} />
        },
        {
            Header: 'Remove',
            accessor: (row) => row,
            Cell: ({ value }) => <ActionColumn element={value} />,
        },
    ];

    const READMODE_FIELDS_COLUMN: Column[] = [
        {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <CaretDownSvg /> : <CaretRightSvg />}
                </span>
            ),
        },
        {
            Header: 'Document Number',
            accessor: 'documentNumber',
            Cell: ({ row }) => <DocNumberForLink {...row} />,
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Files',
            accessor: 'filesCount',
            Cell: ({ value }) => <FilesColumn value={value} />,
        },
        {
            Header: 'Old Version',
            accessor: 'approvedDocumentVersion',
            Cell: ({ row }) => <OldVersion {...row} />,
        },
        {
            Header: 'New Version',
            accessor: 'activeDocumentVersion',
            Cell: ({ row }) => <NewVersion {...row} />,
        },
        {
            Header: 'Checked In',
            accessor: 'activeDocumentVersion.checkedIn',
            Cell: ({row}) => <CheckedIn {...row} />
        },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const columns = useMemo(() => FIELDS_COLUMN, []);
    let columns = null;
    if (location.pathname.includes('create-document')) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        columns = useMemo(() => FIELDS_COLUMN, [documentList]);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        columns = useMemo(() => READMODE_FIELDS_COLUMN, [documentList]);
    }

    const renderRowSubComponent = React.useCallback(({ row }) => {
        const {
            original: { activeDocumentVersion },
        } = row;
        const { documentTypeVersion, data } = activeDocumentVersion;
        const {
            fieldSet: { fields },
        } = documentTypeVersion;
        return (
            <>
                {data.file && data.file.length > 0 ? (
                    <table className='file-table'>
                        <thead>
                            <tr>
                                <th>File Number</th>
                                <th>Attachment</th>
                                <th>Description</th>
                                {/* <th>Uploaded on</th>
                                <th>Released on</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.file.map((item: any) => (
                                <tr key={item.id}>
                                    <td>{item.fileNumber}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description ? item.description : 'N/A'}</td>
                                    {/* <td>{item.uploadedOn && item.uploadedOn !== 'N/A' ? formatDateTime(item.uploadedOn) : 'N/A'}</td>
                                    <td>{item.releasedOn && item.releasedOn !== 'N/A' ? formatDateTime(item.releasedOn) : 'N/A'}</td> */}
                                    <td>
                                        <div className="file-actions">
                                            <DownloadAttachment attachmentId={item.attachmentId} fileName={item.name} />
                                            <div className="copy-smartlink" data-tip='Copy Smartlink' data-for='smartlink-tooltip'>
                                                <CopyText
                                                    text=''
                                                    link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.fileDetails.WPUrl}?doc_id=${item.id}`}
                                                    differentIcon
                                                />
                                            </div>
                                            <ReactTooltip className='tooltip-docinfo' id="smartlink-tooltip" effect="solid" place="top" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <table>
                        <tbody>
                            {' '}
                            {fields.map((k: any) => {
                                if (k.name !== 'LinkedDocuments') {
                                    return (
                                        <tr key={k.name}>
                                            <th>{k.displayName}</th>
                                            <td>{activeDocumentVersion.data[k.name]}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                )}
            </>
        );
    }, []);

    const handleCreateDoc = async () => {
        setdoctypeselection(true);
        setdisablebutton(true);
        setdoctypeloading(true);
        triggerEvent('DMS_CREATE');
        const res = await createDocument(statedocList?.value as number, {});
        if (res?.apiStatus === 'SUCCESS') {
            history.push(
                `/create-document-in-dco?doc_id=${res.docId}&doc_version_id=${res.docVersionId}&dcoDocumentId=${documentId}`,
            );
            setdisablebutton(false);
            setisModalOpen(false);
            setdoctypeloading(false);
            setdoctypeselection(false);
        }
        if (res?.apiStatus === 'FAILURE') {
            toast.error(res.errorMessage);
            setdisablebutton(false);
            setisModalOpen(false);
            setdoctypeloading(false);
            setdoctypeselection(false);
        }
    };

    const handleDocTypeChange = async (option: any) => {
        setstatedocList(option);
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { pageIndex, pageSize, expanded },
    } = useTable(
        {
            columns,
            data: documentList || [],
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
        },
        useExpanded,
        usePagination,
    );

    useEffect(() => {
        getDocumentsDetailByState();   
    }, [ queryObj.newDocversionId]);

    useEffect(() => {
        const docTypeIds = [];
        for (let index = 0; index < field.docTypeOptionValues.length; index += 1) {
            docTypeIds.push(field.docTypeOptionValues[index].value as number);
        }
        setallowDocTypeIds(docTypeIds);
    }, []);

    useEffect(() => {
        updateHeight();
    });
    type LabelValuePair = { label: string, value: number };
    const [docoptionsDCO,setOptions] = useState<LabelValuePair[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await getDocTypes({ inputStr: '', sortBy: 'asc' });
            if (res?.apiStatus === 'SUCCESS') {
                const docOptions: { label: string,value: number }[] = []
                // eslint-disable-next-line array-callback-return
                field.docTypeOptionValues.map(r => {
                    const docOption = res?.docTypeCard.filter(d => d.id === r.value);
                    if(docOption){
                        docOptions.push({"label": `${docOption[0].name} (${docOption[0].code}-XXXXX)`,"value":docOption[0].id})
                    }
                })
                setOptions(docOptions)
            }
        };
        fetchData();
    }, []);
    
    return (
        <>
            <div className="document-reference border">
                <div className="search-document">
                    { location.pathname.includes('create-document') ? (
                        <>
                            <AsyncSelect
                                name="single-select"
                                components={animatedComponents}
                                id="single-select"
                                className={`react-select ${error ? 'error' : ''}`}
                                classNamePrefix="select"
                                value={selectedDocument}
                                getOptionLabel={(e) => `${e.value} | ${e.label} `}
                                isMulti={false}
                                isDisabled={isDisabled}
                                isLoading={loading}
                                placeholder={isDisabled ? '' : 'Start Typing...'}
                                isClearable
                                loadOptions={promiseOptions}
                                noOptionsMessage={() =>
                                    searchText.length >= 3
                                        ? 'No results found'
                                        : 'Start typing minimum 3 characters'
                                }
                                onChange={(option) => handleChange(option)}
                            />
                            {/* <button type="button" onClick={() => handleAddDocument()}>
                                Add
                            </button> */}
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => handleAddDocument()}
                                disabled={disableAddButton || isFieldsDataSaved || selectedDocument?.value === '' || selectedDocument === null}
                                isLoading={disableAddButton}
                            >
                                Add
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div>
                    <div className="roles-table">
                        <div className="table-section">
                            <div>
                                { location.pathname.includes('create-document') ? (
                                    <>
                                        {/* <Button
                                            disabled={false}
                                            isLoading={false}
                                            className="create-new-document"
                                            onClick={() => setisModalOpen(true)}
                                            type="button"
                                        >
                                            + Create New Document
                                        </Button> */}
                                        <Modal
                                            isOpen={isModalOpen}
                                            className="create-document__Modal"
                                        >
                                            <PlusIconSvg
                                                className="close-btn"
                                                onClick={() => {
                                                    setisModalOpen(false);
                                                    setstatedocList(undefined);
                                                }}
                                            />
                                            <label>Select a document and click on Create</label>
                                            <div className="select-field">
                                                <Select
                                                    name="single-select"
                                                    components={animatedComponents}
                                                    id="single-select"
                                                    className="react-select"
                                                    classNamePrefix="select"
                                                    value={statedocList}
                                                    isMulti={false}
                                                    isDisabled={doctypeselection}
                                                    placeholder="Select"
                                                    options={docoptionsDCO}
                                                    onChange={(option) =>
                                                        handleDocTypeChange(option)
                                                    }
                                                />
                                                <Button
                                                    type="button"
                                                    isLoading={doctypeloading}
                                                    onClick={() => handleCreateDoc()}
                                                    disabled={doctypeselection}
                                                    className=""
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Modal>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <table {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth
                                                                ? column.minWidth
                                                                : 'auto',
                                                        },
                                                    })}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <React.Fragment key={row.id}>
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                                {row.isExpanded ? (
                                                    <tr key={row.id}>
                                                        <td
                                                            colSpan={visibleColumns.length}
                                                            className="document-details"
                                                        >
                                                            {renderRowSubComponent({ row })}
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {page.length === 0 && <div className='NoDocumentsFound'><h1>NO DOCUMENT</h1> </div>}
                        </div>
                    </div>
                    {/* {showModal && (
                <AddRoleModal isModalOpen={showModal} onModalClose={setShowModal} isEdit />
            )} */}
                </div>
            </div>
        </>
    );
};

export default DocumentReference;