/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { IAPIResponseStatus, IOwnerChange, IOwnerChangeAdminDocuments } from '../interfaces';
import { getRequest, postRequest } from '../utils/api-request';
import { getUserFullName } from '../utils';
import { convertEpochToDate } from '../utils/date';

const getDocumentList = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          DocumentList: IOwnerChangeAdminDocuments[];
          totalDocuments: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`owner-change/document-list?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const DocumentList: IOwnerChangeAdminDocuments[] = [];

            const documents = response.response.rows;
            for (const document of documents) {
                DocumentList.push({
                    documentId: document.id,
                    docNumber: document.documentNumber,
                    documentType: document.documentNumber.split('-')[0],
                    ownerId: document.ownerId,
                    owner: document.owner
                        ? getUserFullName(document.owner.firstName, document.owner.lastName)
                        : '',
                    status: document.state,
                    updatedAt: convertEpochToDate(document.updatedAt),
                });
            }

            return {
                apiStatus: response.apiStatus,
                DocumentList,
                totalDocuments: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, DocumentList: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const ownerChangeAction = async (
    documents: IOwnerChange[],
    newOwnerId?: number,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          errors: [
              {
                  documentId: number;
                  errorReason: string;
                  resolution: string;
                  documentNumber: string;
              },
          ];
      }
    | undefined
> => {
    try {
        const body: {
            documents: IOwnerChange[];
            newOwnerId?: number;
        } = { documents };
        if (newOwnerId) {
            body.newOwnerId = newOwnerId;
        } else {
            throw Error('ownerId is missing');
        }
        const res = await postRequest(`owner-change/change-owner`, body);
        if (res) {
            const errorList = res.response.filter(
                (item: { resolution: string }) => item.resolution === 'error',
            );
            return { apiStatus: res?.apiStatus, errors: errorList };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

export { getDocumentList, ownerChangeAction };
