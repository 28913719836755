/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './TravelForm.scss'; // Make sure to create this file or remove this line if not needed
import { Button } from 'react-bootstrap'; // Make sure to install react-bootstrap
import { ITravelDetails, ITravelForm, ITravelType } from '../../../interfaces';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import { RemoveIconSvg } from '../../../assets/images';
import STATIC_CONTENT from '../../../constants/StaticContent';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: ITravelForm;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const intialTripDetails = {from: '', to: '', fromDate: '', toDate: '' };

const initialTravelForm: ITravelForm = {
    travelDetails : [{...intialTripDetails}],
    travelType : STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP,
};

// eslint-disable-next-line max-lines-per-function
const TravelForm = ({ field, isDisabled, value, documentDispatch, error }: Props) => {
    const [data, setData] = useState<ITravelDetails[]>(value.travelDetails);
    const [tripType, setTripType] = useState<ITravelType>(value.travelType);
    const [maxTrips, setMaxTrips] = useState<number>(value.travelDetails.length);
    const handleAddRow = () => {
        const addedRowdata = [...data, { ...intialTripDetails }];
        const updatedDetails : ITravelForm = {
            travelDetails : addedRowdata,
            travelType: tripType,
        };
        setData(addedRowdata);
        if (documentDispatch) {
            documentDispatch({
                type: 'travel-form',
                inputName: field.name,
                value: updatedDetails,
            });
        }
    };

    const handleDeleteRow = (index: number) => {
        const deletedRowData = data.filter((item, i) => i !== index);
        setData(deletedRowData);
        const updatedDetails : ITravelForm = {
            travelDetails : deletedRowData,
            travelType: tripType,
        };
        if (documentDispatch) {
            documentDispatch({
                type: 'travel-form',
                inputName: field.name,
                value: updatedDetails,
            });
        }
    };

    const handleChange = (index: number, fieldName: string, info: string) => {
        const updatedData = data.map((row, i) =>
            i === index ? { ...row, [fieldName]: info } : row,
        );
        setData(updatedData);
        const updatedDetails : ITravelForm = {
            travelDetails : updatedData,
            travelType: tripType,
        };
        if (documentDispatch) {
            documentDispatch({
                type: 'travel-form',
                inputName: field.name,
                value: updatedDetails,
            });
        }
    };

    const handleTravelTypeChange = (travelType : ITravelType) => {
        setTripType(travelType);
        let removeRows = data;
        if(travelType === STATIC_CONTENT.TRAVEL_FORM.MULTI_CITY){
            setMaxTrips(6);
        } else {
            removeRows = data.filter((item, i) => i === 0);
            setMaxTrips(1);
        }
        const updatedDetails : ITravelForm = {
            travelDetails : removeRows,
            travelType,
        };
        if (documentDispatch) {
            documentDispatch({
                type: 'travel-form',
                inputName: field.name,
                value: updatedDetails,
            });
        }
    }

    useEffect(() => {
        setData(value.travelDetails);
        setTripType(value.travelType);
        if(value.travelType === STATIC_CONTENT.TRAVEL_FORM.MULTI_CITY){
            setMaxTrips(6);
        } else {
            setMaxTrips(1);
        }
    }, [value]);

    return (
        <div className={`trip-details-container ${error ? 'error' : ''}`}>
            <div className="tab">
                <button
                    id="TRIP_TYPE"
                    type="button"
                    className={`${tripType === STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP ? 'active' : ''}`}
                    onClick={() => handleTravelTypeChange(STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP)}
                    disabled={isDisabled}
                >
                    <span className="name">Round Trip</span>
                </button>
                <button
                    id="TRIP_TYPE"
                    type="button"
                    className={`${tripType === STATIC_CONTENT.TRAVEL_FORM.ONE_WAY ? 'active' : ''}`}
                    onClick={() => handleTravelTypeChange(STATIC_CONTENT.TRAVEL_FORM.ONE_WAY)}
                    disabled={isDisabled}
                >
                    <span className="name">One Way</span>
                </button>
                <button
                    id="TRIP_TYPE"
                    type="button"
                    className={`${tripType === STATIC_CONTENT.TRAVEL_FORM.MULTI_CITY ? 'active' : ''}`}
                    onClick={() => handleTravelTypeChange(STATIC_CONTENT.TRAVEL_FORM.MULTI_CITY)}
                    disabled={isDisabled}
                >
                    <span className="name">Multi City</span>
                </button>
            </div>
            <div>
                <div className="table-wrapper">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Departure City</th>
                                <th>Arrival City</th>
                                <th>Departure Date</th>
                                {tripType === STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP ? <th>Return Date</th> : null}
                                {!isDisabled ? <th>Actions</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr>
                                    <td>
                                        <input
                                            className="styled-input"
                                            disabled={isDisabled}
                                            type="text"
                                            value={row.from}
                                            onChange={(e) =>
                                                handleChange(index, 'from', e.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="styled-input"
                                            disabled={isDisabled}
                                            type="text"
                                            value={row.to}
                                            onChange={(e) =>
                                                handleChange(index, 'to', e.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            disabled={isDisabled}
                                            className="styled-date-picker"
                                            type="date"
                                            placeholder="dd/mm/yyyy"
                                            value={row.fromDate}
                                            onChange={(e) =>
                                                handleChange(index, 'fromDate', e.target.value)
                                            }
                                        />
                                    </td>
                                    {tripType === STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP ? 
                                        <td>
                                            <input
                                                disabled={isDisabled}
                                                className="styled-date-picker"
                                                type="date"
                                                value={row.toDate}
                                                onChange={(e) =>
                                                    handleChange(index, 'toDate', e.target.value)
                                                }
                                            />
                                        </td> 
                                    : null}
                                    {!isDisabled ? (
                                        <td>
                                            <span
                                                aria-hidden="true"
                                                style={{ cursor: 'pointer' }}
                                                data-tip
                                                data-for="remove-action"
                                                className="action-icon"
                                                onClick={() => handleDeleteRow(index)}
                                            >
                                                <RemoveIconSvg />
                                            </span>
                                        </td>
                                    ) : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {!isDisabled && data.length < maxTrips ? (
                    <Button className="styled-button" onClick={handleAddRow}>
                        Add Row
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default TravelForm;
