/* eslint-disable no-debugger */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import { NoPermission } from '../../components/General';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { getDocumentVersion } from '../../API/document';
import './FileDetails.scss';
import { IFileTypeDoc } from '../../interfaces/attachment';
import CopyText from '../../components/CopyText';
import CONSTANTS from '../../constants';
import { DownloadAttachment } from '../../components/attachments/view/ViewAttachments';
import { formatDateTime } from '../../utils/date';

// eslint-disable-next-line max-lines-per-function
const FileDetails: React.FC = () => {
    const {
        docInfo,
        userType,
        documentId,
        selectedDocVersion,
        activeDocVersionId,
        docVersions,
        approvers,
        canViewDocumentDetails,
        activeVersionOwnerInfo,
    } = useDocumentContext();
    const defaultFileDetails: IFileTypeDoc = {
        parentDocId: 0,
        docVersionId: 0,
        attachmentId: 0,
        attachmentType: '',
        displayName: '',
        fileNumber: '',
        title: '',
        description: '',
        isPrimary: false,
        uploadedOn: 0,
        releasedOn: null,
        name: '',
        extension: '',
        mimeType: '',
        size: 0,
    };
    const [filesData, setFilesData] = useState<IFileTypeDoc>(defaultFileDetails);
    const [loading, setLoading] = useState(false);
    const { user } = useAuthDataContext();
    let latestDocVersion = 0;
    docVersions.forEach((docVersion) => {
        const versionNumber = parseFloat(docVersion.label);
        if (versionNumber > latestDocVersion) {
            latestDocVersion = versionNumber;
        }
    });

    // check if logged-in user is a valid approver
    const isValidApprover = (userId: number) => {
        const approversArr = approvers.length > 0 ? approvers[0]?.approvers : [];
        const approverIDsArr: number[] = [];

        approversArr.forEach((approver) => {
            if (approver?.state === 'UNDER_REVIEW') {
                const empId: any = approver?.employee?.value;
                approverIDsArr.push(empId);
            }

            const adhocApprovers = approver?.adHoc || [];
            adhocApprovers.forEach((adhocApprover) => {
                if (adhocApprover?.state === 'UNDER_REVIEW') {
                    const empId: any = adhocApprover?.employee?.value;
                    approverIDsArr.push(empId);
                }
            });
        });

        if (approverIDsArr.includes(userId)) {
            return true;
        }
        return false;
    };

    // Show Approver buttons only for valid approvers with document details link
    let customUserType = userType;
    if (
        activeVersionOwnerInfo.id !== user.id &&
        docInfo.docState === 'UNDER_REVIEW' &&
        isValidApprover(user.id)
    ) {
        customUserType = 'APPROVER';
    } else if (
        activeVersionOwnerInfo.id !== user.id &&
        userType === 'CREATOR' &&
        !isValidApprover(user.id)
    ) {
        customUserType = 'OTHER';
    } else {
        customUserType = userType;
    }

    useEffect(() => {
        async function fetchFn() {
            setLoading(true);
            const response = await getDocumentVersion(
                documentId,
                docInfo.approvedVersionId,
                user.id,
                true,
            );
            const currFileInfo: IFileTypeDoc = {
                parentDocId: 0,
                docVersionId: response?.docInfo?.approvedVersionId as number,
                attachmentId: response?.docVersionAttachment[0]?.id,
                attachmentType: response?.docVersionAttachment[0]?.metaData?.attachmentType,
                displayName: response?.docVersionAttachment[0]?.metaData?.displayName,
                fileNumber: docInfo.docNumber,
                title: docInfo.title,
                description: response?.data?.description as string,
                isPrimary: false,
                uploadedOn: response?.docVersionAttachment[0]?.createdAt,
                releasedOn: response?.docInfo?.approvedOn ? response?.docInfo?.approvedOn : 'N/A',
                name: response?.docVersionAttachment[0]?.metaData?.name,
                extension: response?.docVersionAttachment[0]?.metaData?.extension,
                mimeType: response?.docVersionAttachment[0]?.metaData?.mimeType,
                size: response?.docVersionAttachment[0]?.metaData?.size,
            };
            setFilesData(currFileInfo);
            setLoading(false);
        }
        if (selectedDocVersion.value && docInfo.approvedVersionId) {
            fetchFn();
        }
    }, [docInfo]);

    if (docInfo.docNumber === 'LOADING' || loading) {
        return (
            <div className='shimmer'>
                <Skeleton width="100%" containerClassName="skeleton-loading" />
                <Skeleton width="100%" containerClassName="skeleton-loading" />
                <Skeleton width="100%" containerClassName="skeleton-loading" />
            </div>
        )
    } else if (canViewDocumentDetails && docInfo.approvedVersionId) {
        return (
            <div className="attachment-list">
                <div className="attachment-item files-header">
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>File Number</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Attachment</h5>
                    </div>
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Description</h5>
                    </div>

                    {/* <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Uploaded on</h5>
                    </div> */}

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Released on</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Actions</h5>
                    </div>
                </div>
                <div className="attachment-item">
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>{filesData.fileNumber}</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>{filesData.name}</h5>
                    </div>
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>{filesData.description || 'N/A'}</h5>
                    </div>

                    {/* <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>
                            {new Date(filesData.uploadedOn * 1000)?.toLocaleDateString('en-GB')}
                        </h5>
                    </div> */}

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>
                            {filesData.releasedOn && filesData.releasedOn !== 'N/A'
                                ? formatDateTime(new Date(filesData.releasedOn * 1000))
                                : 'N/A'}
                        </h5>
                    </div>

                    <div className="file-actions">
                        <DownloadAttachment attachmentId={filesData.attachmentId} fileName={filesData.name} />
                        {/* <div className="copy-smartlink" data-tip='Copy Smartlink' data-for='smartlink-tooltip'>
                            <CopyText
                                text=""
                                link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.fileDetails.WPUrl}?doc_id=${filesData.id}`}
                                differentIcon
                            />
                        </div>
                        <ReactTooltip className='tooltip-docinfo' id="smartlink-tooltip" effect="solid" place="top" /> */}
                    </div>
                </div>
            </div>
        );
    } else if (canViewDocumentDetails && !docInfo.approvedVersionId) {
        return (
            <h2 style={{ textAlign: 'center' }}>There is no released version for this file: <b>{docInfo.docNumber}</b></h2>
        )
    } else {
        return <NoPermission />;
    }

    // below is the different UI for smartlink page
    return (
        <>
            {canViewDocumentDetails ? (
                {
                    /* <div className="main-section" id="docDetailMain" ref={heightRef}>
                            <div className="document-details">
                                <div className="document-details-header">
                                    <h3>Details</h3>
                                </div>
                                <div className="file-info">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>File Number:</th>
                                            <th className='value'>{docInfo.docNumber}</th>
                                        </tr>
                                        <tr>
                                            <th>Title:</th>
                                            <th className='value'>{docInfo.title}</th>
                                        </tr>
                                        <tr>
                                            <th>Description:</th>
                                            <th className='value'>{docInfo.description}</th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {selectedDocVersion.value && !docTypeInfo.allowFileTypeAttachment ? (
                                <ViewAttachments
                                    activeDocVersion={selectedDocVersion.value as number}
                                    attachmentConfig={attachmentConfig}
                                    isDocFetched
                                    allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                />
                            ) : null}
                    </div> */
                }
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const FileDetailsPage: React.FC = () => (
    <div className="file-details">
        <DocumentProvider>
            <FileDetails />
        </DocumentProvider>
    </div>
);

export default FileDetailsPage;
