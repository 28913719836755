import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { InvalidSvg } from '../../assets/images';
import DocTypeApprovalFlow from '../../components/doc-type-approval-flow/DocTypeApprovalFlow';
import DocTypeAttachmentFlow from '../../components/doc-type-attachment-flow/DocTypeAttachmentFlow';
import DocTypeDocumentDetail from '../../components/doc-type-document-details/DocTypeDocumentDetail';
import DocumentTypeProvider, { useDocTypeContext } from '../../contexts/document-type-provider';
import { IDocTypeTabs } from '../../interfaces/document-type';
import DocTypeAdditionalConfig from '../../widgets/doc-type-addtional-config/DocTypeAdditionalConfig';
import DocTypeCreateInfo from '../../widgets/doc-type-create-info/DocTypeCreateInfo';
import './DocumentTypeCreate.scss';

interface Props {
    activeTab: IDocTypeTabs;
    setActiveTab: React.Dispatch<React.SetStateAction<IDocTypeTabs>>;
}

const DocumentTypeTabs: React.FC<Props> = ({ activeTab, setActiveTab }) => {
    const {
        docTypeVersionError: { APPROVAL_FLOW, ATTACHMENT_FLOW, DOCUMENT_DETAILS },
    } = useDocTypeContext();

    return (
        <div className="tab">
            <button
                type="button"
                className={`${activeTab === 'DOCUMENT_DETAILS' ? 'active' : ''}`}
                onClick={() => setActiveTab('DOCUMENT_DETAILS')}
            >
                Document Details
                {DOCUMENT_DETAILS.error && <InvalidSvg />}
            </button>
            <button
                type="button"
                className={`${activeTab === 'APPROVAL_FLOW' ? 'active' : ''}`}
                onClick={() => setActiveTab('APPROVAL_FLOW')}
            >
                Approval Flow
                {APPROVAL_FLOW.error && <InvalidSvg />}
            </button>
            <button
                type="button"
                className={`${activeTab === 'ATTACHMENT_FLOW' ? 'active' : ''}`}
                onClick={() => setActiveTab('ATTACHMENT_FLOW')}
            >
                Attachment Configuration
                {ATTACHMENT_FLOW.error && <InvalidSvg />}
            </button>
        </div>
    );
};

const DocumentTypeCreatePage: React.FC = () => {
    const [activeTab, setActiveTab] = useState<IDocTypeTabs>('DOCUMENT_DETAILS');

    return (
        <div className="document-type-create">
            <DocumentTypeProvider>
                <DocTypeCreateInfo />
                <DocTypeAdditionalConfig />
                <DndProvider backend={HTML5Backend}>
                    <DocumentTypeTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                    {activeTab === 'DOCUMENT_DETAILS' && <DocTypeDocumentDetail />}
                    {activeTab === 'APPROVAL_FLOW' && <DocTypeApprovalFlow />}
                    {activeTab === 'ATTACHMENT_FLOW' && <DocTypeAttachmentFlow />}
                </DndProvider>
            </DocumentTypeProvider>
        </div>
    );
};
export default DocumentTypeCreatePage;
