import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import Button from '../button/Button';
import { CrossIcon, InvalidSvg, PlusIconSvg, TickIcon } from '../../assets/images';
import { IOwnerChange } from '../../interfaces';
import './OwnerSelectModal.scss';
import { documentAction } from '../../API/document';
import { IDocumentTypeInfo } from '../../interfaces/document-type';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, 0%)',
        padding: '2rem 2rem 1rem 2rem',
        overflow: 'auto'
    },
};

interface Props {
    documents: IOwnerChange[];
    show: boolean;
    handleShow: (check: boolean) => void;
}

interface IWithdrawSummary {
    documentNumber: string;
    success: boolean;
    message?: string;
}

// eslint-disable-next-line max-lines-per-function
const WithdrawModal: React.FC<Props> = ({ documents, show, handleShow }) => {
    const docsEligibleForWithdraw = documents.filter((document) => document.state === 'UNDER_REVIEW');
    const [responseSummary, setResponseSummary] = useState<IWithdrawSummary[]>([]);
    const [showSummary, setShowSummary] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleWithdraw = async (docsToBeWithdrawn:IOwnerChange[]) => {
        setLoading(true);
        const summaryList = [];

        /* eslint-disable no-await-in-loop */
        for(let i=0; i<docsToBeWithdrawn.length; i+=1) {
            const res = await documentAction(docsToBeWithdrawn[i].documentId, "WITHDRAW", {} as IDocumentTypeInfo);

            const summaryObj: IWithdrawSummary = {
                documentNumber: docsToBeWithdrawn[i].docNumber,
                success: true,
                message: ''
            };

            if(res?.apiStatus === "SUCCESS") {
                summaryList.push(summaryObj);
            } else {
                summaryObj.success = false;
                summaryObj.message = res?.errorMessage;
                summaryList.push(summaryObj);
            }
        };
        /* eslint-enable no-await-in-loop */
        setResponseSummary(summaryList);
        setShowSummary(true);
        setLoading(false);
    }

    return (
        <div>
            <Modal isOpen={show} style={customStyles} className="Owner-Change-Modal">
                <PlusIconSvg
                    className="close-btn"
                    onClick={() => {
                        handleShow(false);
                        setShowSummary(false);
                        setResponseSummary([]);
                    }}
                />
                <h3>Documents to be withdrawn:</h3>
                <div className="Docs">
                    {docsEligibleForWithdraw.length ? (
                        <ol>
                            {docsEligibleForWithdraw.map((doc: IOwnerChange) => (
                                <li key={doc.docNumber} style={{ marginBottom: '5px' }}>
                                    {doc.docNumber}
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <h3>No eligible docs found!!</h3>
                    )}
                </div>
                <label htmlFor="user-search">*Total: {docsEligibleForWithdraw.length}</label>
                {showSummary || loading ? (
                    <div className="summary-container">
                        <h3>Response:</h3>
                        {loading ? (
                            <div className="loader">
                                <Loader type="Oval" color="#000" height={30} width={30} />
                            </div>
                        ) : (
                            <div className="withdraw-summary">
                                <ol>
                                    {responseSummary.map((response: IWithdrawSummary) => (
                                        <li
                                            key={response.documentNumber}
                                            style={{ marginBottom: '5px' }}
                                        >
                                            <div className="success-msg">
                                                <div>{response.documentNumber}:</div>
                                                {response.success ? (
                                                    <h4>
                                                        <TickIcon />
                                                        withdrawn successfully
                                                    </h4>
                                                ) : (
                                                    <h4>
                                                        <InvalidSvg />
                                                        failed: {response.message}
                                                    </h4>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="button-note-container">
                        <h5 className="Note">*Only under review documents can be withdrawn</h5>
                        <Button
                            onClick={() => handleWithdraw(docsEligibleForWithdraw)}
                            disabled={docsEligibleForWithdraw.length <= 0}
                            isLoading={false}
                            className="submit-button"
                            type="submit"
                        >
                            Confirm
                        </Button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default WithdrawModal;
