/* eslint-disable no-restricted-syntax */
import { IAPIResponseStatus } from '../interfaces';
import { FilterRow, IReports, IReportsHistory, IReportsList } from '../interfaces/reports';
import { getUserFullName } from '../utils';
import {
    deleteRequest,
    getRequest,
    getRequestWithResponse,
    postRequest,
    putRequest,
} from '../utils/api-request';
import { convertEpochToDate } from '../utils/date';

// eslint-disable-next-line max-lines-per-function
const getAllReports = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          reportsList: IReports[];
          total: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`reports/dco?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const reportsList = [];
            const dcos = response?.response?.dcos;
            for (const dco of dcos) {
                let ChangeAdminDecisionTime = null;
                if (
                    dco.ChangeAdministratorDecisionTime &&
                    typeof dco.ChangeAdministratorDecisionTime === 'string'
                ) {
                    if (dco.ChangeAdministratorDecisionTime.length > 13) {
                        const cleanedDateString = dco.ChangeAdministratorDecisionTime.replace(
                            /"/g,
                            ''
                        );
                        ChangeAdminDecisionTime = new Date(cleanedDateString);
                    } else {
                        ChangeAdminDecisionTime = convertEpochToDate(
                            Number(dco.ChangeAdministratorDecisionTime) / 1000,
                        );
                    }
                }
                reportsList.push({
                    documentId: dco.documentId ?? 0,
                    documentNumber: dco.documentNumber ?? 'Not Assigned',
                    ownerId: dco.ownerId ?? 0,
                    creator: dco.creator,
                    documentState: dco.documentState ?? 'Not Assigned',
                    publishedOn: dco.publishedOn ? convertEpochToDate(dco.publishedOn) : null,
                    createdAt: dco.createdAt ? convertEpochToDate(dco.createdAt) : new Date(),
                    title: dco.title,
                    submittedOn: dco.submittedOn ? convertEpochToDate(dco.submittedOn) : null,
                    approvalDurationDays: dco.approvalDurationDays,
                    ChangeAdministrator: dco.ChangeAdministrator,
                    submitCount: dco.submitCount,
                    AdminResponseTimeHours: dco.AdminResponseTimeHours,
                    ChangeAdministratorDecisionTime: ChangeAdminDecisionTime,
                    approverName: dco.approverName,
                    approverState: dco.approverState,
                    approverDecisionTime: dco.approverDecisionTime
                        ? convertEpochToDate(Number(dco.approverDecisionTime) / 1000)
                        : null,
                    approverResponseTimeDays: dco.approverResponseTimeDays,
                });
            }
            return {
                apiStatus: response.apiStatus,
                reportsList,
                total: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, reportsList: [], total: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const createCustomReport = async (
    data: any,
): Promise<{ apiStatus: IAPIResponseStatus; response: any; errorMessage?: string } | undefined> => {
    try {
        const res = await postRequest('reports/custom/', { data });
        if (res?.apiStatus === 'SUCCESS') {
            const { response, apiStatus } = res;
            return {
                apiStatus,
                response,
            };
        }
        if (res?.apiStatus === 'FAILURE') {
            return {
                apiStatus: res.apiStatus,
                response: null,
                errorMessage: res.response.message,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getAllCustomReports = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          customReports: IReportsList[];
          total: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`reports/custom-reports?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const customReports = [];
            const reports = response?.response?.reports;
            for (const report of reports) {
                customReports.push({
                    title: report.title,
                    description: report.description,
                    creator: report?.ownerId
                        ? getUserFullName(report.owner.firstName, report.owner.lastName)
                        : '',
                    id: report.id,
                    type: report.type,
                    createdAt: report.createdAt ? convertEpochToDate(report.createdAt) : new Date(),
                    visibility: report.visibility,
                });
            }
            return {
                apiStatus: response.apiStatus,
                customReports,
                total: response.response.count,
            };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, customReports: [], total: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getAllStandardReports = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          standardReports: IReportsList[];
          total: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`reports/standard-reports?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const standardReports = [];
            const reports = response?.response?.reports;
            for (const report of reports) {
                standardReports.push({
                    title: report.title,
                    description: report.description,
                    creator: 'Admin',
                    id: report.id,
                    type: report.type,
                    createdAt: report.createdAt ? convertEpochToDate(report.createdAt) : new Date(),
                });
            }
            return {
                apiStatus: response.apiStatus,
                standardReports,
                total: response.response.count,
            };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, standardReports: [], total: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getCustomerReportById = async (
    reportId: number,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          customReport: IReportsList;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`reports/custom/${reportId}`);
        if (response?.apiStatus === 'SUCCESS') {
            const customReport: any = {};
            const report = response.response;
            customReport.id = report.id;
            customReport.creator = report.creator;
            customReport.title = report.title;
            customReport.description = report.description;
            customReport.filters = report.filters;
            return {
                apiStatus: response.apiStatus,
                customReport,
            };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, customReport: {} as IReportsList };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const updateCustomReport = async (
    reportId: number,
    title: string,
    description: string,
    filters: FilterRow[],
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest(`reports/custom/${reportId}`, {
            reportId,
            title,
            description,
            filters,
        });
        if (response?.apiStatus) {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const deleteCustomReport = async (reportId: number): Promise<IAPIResponseStatus | undefined> => {
    try {
        const res = await deleteRequest(`reports/custom/${reportId}`);
        if (res?.apiStatus === 'SUCCESS') {
            return 'SUCCESS';
        }

        if (res?.apiStatus === 'FAILURE') {
            return 'FAILURE';
        }

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const requestExcelGeneration = async (
    params: any,
): Promise<{ apiStatus: IAPIResponseStatus; response: any; errorMessage?: string } | undefined> => {
    try {
        const res = await postRequest('reports/generate-excel', params);
        if (res?.apiStatus === 'SUCCESS') {
            const { response, apiStatus } = res;
            return {
                apiStatus,
                response,
            };
        }
        if (res?.apiStatus === 'FAILURE') {
            return {
                apiStatus: res.apiStatus,
                response: null,
                errorMessage: res.response.message,
            };
        }
        return undefined;
    } catch {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getReportHistory = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          reportHistory: IReportsHistory[];
          total: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`reports/report-history?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const reportHistory = [];
            const reports = response?.response?.reports;
            for (const report of reports) {
                reportHistory.push({
                    id: report.id,
                    reportId: report.reportId,
                    runBy: report?.userId
                        ? getUserFullName(report.owner.firstName, report.owner.lastName)
                        : '',
                    runTime: report.runTime ? convertEpochToDate(report.runTime) : new Date(),
                    numberOfRecords: report.numberOfRecords,
                    key: report.key,
                });
            }
            return {
                apiStatus: response.apiStatus,
                reportHistory,
                total: response.response.totalCount,
            };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, reportHistory: [], total: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const downloadReportHistory = async (id: number, reportName: string | null) => {
    try {
        // const res = await getRequestWithResponse(`reports/download-report/?id=${id}`);
        const res = await getRequestWithResponse(`reports/download-report/?id=${id}`, {
            responseType: 'blob',
        });
        if (res?.apiStatus === 'SUCCESS') {
            if (typeof res.response === 'object' && res.response instanceof Blob) {
                const blob = res.response;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', reportName || '');
                document.body.appendChild(link);
                link.click();
                return res.apiStatus;
            }
            // Handle the case where the response is not a Blob
            console.error('Unexpected response type:', typeof res.response);
            return 'FAILURE';
        }
        if (res?.apiStatus === 'FAILURE') {
            return res.apiStatus;
        }
        return undefined;
    } catch {
        return undefined;
    }
};

export {
    getAllReports,
    createCustomReport,
    getAllCustomReports,
    getAllStandardReports,
    getCustomerReportById,
    updateCustomReport,
    deleteCustomReport,
    requestExcelGeneration,
    getReportHistory,
    downloadReportHistory,
};
