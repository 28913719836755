import qs from 'querystring';

import {
    IAPIResponseStatus,
    IInactiveApproverActions,
    IInactiveApproverDocuments,
    IInactiveApproverAdminDocuments,
    IInactiveDocuments,
    IOptionValue,
} from '../interfaces';
import { getUserFullName } from '../utils';
import { getRequest, postRequest } from '../utils/api-request';

// eslint-disable-next-line max-lines-per-function
const getDocumentWithInactiveApprovers = async (
    owners: number[],
    inActiveApproversId: number[],
    limit = 10,
    offset = 0,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          documentWithInactiveApprovers: IInactiveApproverDocuments[];
          totalCount: number;
      }
    | undefined
> => {
    let queryString = `limit=${limit}&offset=${offset}`;
    if (owners) {
        queryString += `&owners=${JSON.stringify(owners)}`;
    }
    if (inActiveApproversId) {
        queryString += `&inactive_actors=${JSON.stringify(inActiveApproversId)}`;
    }
    const documentRes = await getRequest(`stuck-documents?${queryString}`);
    if (documentRes?.apiStatus === 'SUCCESS') {
        const documentWithInactiveApprovers: IInactiveApproverDocuments[] = [];

        const totalCount = documentRes.response.count;
        const documents = documentRes.response.rows;
        // eslint-disable-next-line no-restricted-syntax
        for (const document of documents) {
            documentWithInactiveApprovers.push({
                docNumber: document.document.documentNumber,
                documentId: document.document.id,
                documentType: document.document.documentNumber.split('-')[0],
                inActiveApprover: getUserFullName(
                    document.actor.firstName,
                    document.actor.lastName,
                ),
                inActiveApproverId: document.actor.id,
                ownerId: document.document.ownerId,
            });
        }
        return {
            apiStatus: 'SUCCESS',
            documentWithInactiveApprovers,
            totalCount,
        };
    }
    return undefined;
};

const getTasksApprovers = async (
    ownerIds: number[],
    query: string,
): Promise<{ apiStatus: IAPIResponseStatus; approvers: IOptionValue[] } | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObj: any = {
        owners: JSON.stringify(ownerIds),
    };
    if (query) {
        queryObj.query = query;
    }
    const approversRes = await getRequest(
        `task-approval/taskapproval-actors?${qs.stringify(queryObj)}`,
    );
    if (approversRes?.apiStatus === 'SUCCESS') {
        const approvers: IOptionValue[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const approver of approversRes.response) {
            approvers.push({
                label: getUserFullName(approver.firstName, approver.lastName),
                value: approver.id,
            });
        }

        return { apiStatus: approversRes.apiStatus, approvers };
    }
    return undefined;
};

const getInactiveApprovers = async (
    ownerIds: number[],
    query: string,
): Promise<{ apiStatus: IAPIResponseStatus; approvers: IOptionValue[] } | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObj: any = {
        owners: JSON.stringify(ownerIds),
    };
    if (query) {
        queryObj.query = query;
    }
    const approversRes = await getRequest(
        `stuck-documents/inactive-actors?${qs.stringify(queryObj)}`,
    );
    if (approversRes?.apiStatus === 'SUCCESS') {
        const approvers: IOptionValue[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const approver of approversRes.response) {
            approvers.push({
                label: getUserFullName(approver.firstName, approver.lastName),
                value: approver.id,
            });
        }

        return { apiStatus: approversRes.apiStatus, approvers };
    }
    return undefined;
};

// eslint-disable-next-line max-lines-per-function
const getDocumentWithInactiveApproversForAdmin = async (
    owners: number[],
    inActiveApproversId: number[],
    limit = 10,
    offset = 0,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          documentWithInactiveApprovers: IInactiveApproverAdminDocuments[];
          totalCount: number;
      }
    | undefined
> => {
    let queryString = `limit=${limit}&offset=${offset}`;
    if (owners) {
        queryString += `&owners=${JSON.stringify(owners)}`;
    }
    if (inActiveApproversId) {
        queryString += `&inactive_actors=${JSON.stringify(inActiveApproversId)}`;
    }
    const documentRes = await getRequest(`stuck-documents?${queryString}`);
    if (documentRes?.apiStatus === 'SUCCESS') {
        const documentWithInactiveApprovers: IInactiveApproverAdminDocuments[] = [];

        const totalCount = documentRes.response.count;
        const documents = documentRes.response.rows;
        // eslint-disable-next-line no-restricted-syntax
        for (const document of documents) {
            documentWithInactiveApprovers.push({
                docNumber: document.document.documentNumber,
                documentId: document.document.id,
                documentType: document.document.documentNumber.split('-')[0],
                inActiveApprover: getUserFullName(
                    document.actor.firstName,
                    document.actor.lastName,
                ),
                inActiveApproverId: document.actor.id,
                owner: getUserFullName(
                    document.document.owner.firstName,
                    document.document.owner.lastName,
                ),
                ownerId: document.document.ownerId,
                active:document.active,
            });
        }
        return {
            apiStatus: 'SUCCESS',
            documentWithInactiveApprovers,
            totalCount,
        };
    }
    return undefined;
};

// eslint-disable-next-line max-lines-per-function
const getApprovalTasksForAdmin = async (
    owners: number[],
    inActiveApproversId: number[],
    documentNumber: string,
    limit = 10,
    offset = 0,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          documentWithInactiveApprovers: IInactiveApproverAdminDocuments[];
          totalCount: number;
      }
    | undefined
> => {
    let queryString = `limit=${limit}&offset=${offset}`;
    if (owners) {
        queryString += `&owners=${JSON.stringify(owners)}`;
    }
    if (inActiveApproversId) {
        queryString += `&actors=${JSON.stringify(inActiveApproversId)}`;
    }
    if(documentNumber){
        queryString += `&documentNumber=${documentNumber}`;
    }
    const documentRes = await getRequest(`task-approval?${queryString}`);

    if (documentRes?.apiStatus === 'SUCCESS') {
        const documentWithInactiveApprovers: IInactiveApproverAdminDocuments[] = [];

        const totalCount = documentRes.response.count;
        const documents = documentRes.response.rows;
        // eslint-disable-next-line no-restricted-syntax
        for (const document of documents) {
            documentWithInactiveApprovers.push({
                docNumber: document.document.documentNumber,
                documentId: document.document.id,
                documentType: document.document.documentNumber.split('-')[0],
                inActiveApprover: getUserFullName(
                    document.actor.firstName,
                    document.actor.lastName,
                ),
                inActiveApproverId: document.actor.id,
                owner: getUserFullName(
                    document.document.owner.firstName,
                    document.document.owner.lastName,
                ),
                ownerId: document.document.ownerId,
                active:document.active,
            });
        }
        return {
            apiStatus: 'SUCCESS',
            documentWithInactiveApprovers,
            totalCount,
        };
    }
    return undefined;
};
const inactiveApproversActions = async (
    action: IInactiveApproverActions,
    documents: IInactiveDocuments[],
    newApproverId?: number,
// eslint-disable-next-line @typescript-eslint/ban-types
): Promise<{ apiStatus: IAPIResponseStatus,errors:[{documentId:number,errorReason:string,resolution:string,documentNumber:string}] } | undefined> => {
    try {
        const body: {
            action: IInactiveApproverActions;
            documents: IInactiveDocuments[];
            newApproverId?: number;
        } = { action, documents };
        if (action === 'REASSIGN') {
            if (newApproverId) {
                body.newApproverId = newApproverId;
            } else {
                throw Error('approverId is missing');
            }
        }
        const res = await postRequest(`stuck-documents/resolution`, body);
        if (res) {
            const errorList= res.response.filter((item: { resolution: string; })=>item.resolution==="error")
           
            
            return { apiStatus: res?.apiStatus,errors:errorList };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};
const taskApproversActions = async (
    action: IInactiveApproverActions,
    documents: IInactiveDocuments[],
    newApproverId?: number,
// eslint-disable-next-line @typescript-eslint/ban-types
): Promise<{ apiStatus: IAPIResponseStatus,errors:[{documentId:number,errorReason:string,resolution:string,documentNumber:string}] } | undefined> => {
    try {
        const body: {
            action: IInactiveApproverActions;
            documents: IInactiveDocuments[];
            newApproverId?: number;
        } = { action, documents };
        if (action === 'REASSIGN') {
            if (newApproverId) {
                body.newApproverId = newApproverId;
            } else {
                throw Error('approverId is missing');
            }
        }
        const res = await postRequest(`task-approval/resolution`, body);
        if (res) {
            const errorList= res.response.filter((item: { resolution: string; })=>item.resolution==="error")
           
            
            return { apiStatus: res?.apiStatus,errors:errorList };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};
export {
    getDocumentWithInactiveApprovers,
    inactiveApproversActions,
    getDocumentWithInactiveApproversForAdmin,
    getInactiveApprovers,
    getTasksApprovers,
    getApprovalTasksForAdmin,
    taskApproversActions
};
