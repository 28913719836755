/* eslint-disable no-restricted-syntax */
import { IAPIResponseStatus } from "../interfaces";
import { IContractList } from "../interfaces/contracts";
import { getRequest } from "../utils/api-request";
import { getUserFullName } from '../utils';
import { convertEpochToDate } from '../utils/date';

// eslint-disable-next-line max-lines-per-function
const getAllContracts = async (queryParams: string): Promise< {
    apiStatus: IAPIResponseStatus;
    contractList: IContractList[];
    totalContracts: number;
} | undefined > => {
    try {
        const response = await getRequest(`document-types/clm?${queryParams}`);
        if(response?.apiStatus === 'SUCCESS'){
            const contractList = [];
            const documents = response?.response?.documents;
            for(const document of documents) {
                const contract = document.document;
                contractList.push({
                    contractId: contract.id?? 0,
                    contractNumber: contract.documentNumber?? "Not Assigned",
                    contractType: contract.documentNumber?.split('-')[0]?? "Not Assigned",
                    category: document.data?.contractType?.[0] ?? "Not Assigned",
                    partyName: document.data?.partyName?? "Not Assigned",
                    ownerId: contract.ownerId?? 0,
                    owner: contract.owner ? getUserFullName(contract.owner.firstName || '' , contract.owner.lastName || '') : "Not Assigned",
                    status: contract.state?? "Not Assigned",
                    updatedAt: contract.updatedAt?convertEpochToDate(contract.updatedAt):new Date(),
                })
            }
            return {
                apiStatus: response.apiStatus,
                contractList,
                totalContracts: response.response.count,
            }
            // eslint-disable-next-line no-else-return
        }  else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, contractList: [], totalContracts: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined
    }
}

export default getAllContracts;