/* eslint-disable max-lines-per-function */
import { IAttachmentConfig } from '../interfaces/attachment';
import { toCamelCase } from '../utils';

export type IDocTypeAttachmentConfigAction =
    | { type: 'new' }
    | { type: 'delete'; key: number }
    | { type: 'update'; value: IAttachmentConfig[] }
    | { type: 'display-name'; key: number; value: string; changeInternalName: boolean }
    | { type: 'attachment-type'; key: number; value: string }
    | { type: 'allowed-types'; key: number; value: string[] }
    | { type: 'max-size'; key: number; value: number }
    | { type: 'is-mandatory'; key: number; value: boolean }
    | { type: 'allow-multiple'; key: number; value: boolean }
    | { type: 'allow-drag-drop'; key: number; value: boolean }
    | { type: 'allow-file-type-attachment'; key: number; value: boolean };

export const initialFieldData: IAttachmentConfig = {
    isMandatory: false,
    allowMultiple: false,
    allowedFileTypes: [],
    attachmentType: '',
    displayName: '',
    maxSize: 10,
    allowDragDrop: false,
    allowFileTypeAttachment: false
};

export const docTypeAttachmentConfigReducer = (
    state: IAttachmentConfig[],
    action: IDocTypeAttachmentConfigAction,
): IAttachmentConfig[] => {
    switch (action.type) {
        case 'new':
            return [...state, { ...initialFieldData }];
        case 'delete':
            return [...state.slice(0, action.key), ...state.slice(action.key + 1)];
        case 'update': {
            const tempState: IAttachmentConfig[] = [];
            return tempState.concat(action.value);
        }
        case 'allow-multiple':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], allowMultiple: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'allowed-types':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], allowedFileTypes: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'attachment-type':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], attachmentType: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'display-name':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    displayName: action.value,
                    attachmentType: action.changeInternalName
                        ? toCamelCase(action.value)
                        : state[action.key].attachmentType,
                },
                ...state.slice(action.key + 1),
            ];
        case 'is-mandatory':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], isMandatory: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'max-size':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], maxSize: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'allow-drag-drop':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], allowDragDrop: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'allow-file-type-attachment':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], allowFileTypeAttachment: action.value },
                ...state.slice(action.key + 1),
            ]
        default:
            return state;
    }
};
