/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';

import './taskApproversActions.scss';
import {
    IInactiveApproverActions,
    IInactiveAProverLoading,
    IUserOptionValue,
} from '../../../interfaces';
import Button from '../../button/Button';
import { userSearch } from '../../../API/users';
import CONSTANTS from '../../../constants';
import STATIC_CONTENT from '../../../constants/StaticContent';

const animatedComponents = makeAnimated();
const { INACTIVE_APPROVERS } = STATIC_CONTENT;

interface TaskApproverActionButtonsProps {
    replaceDisabled: boolean;
    removeDisabled: boolean;
    replaceLoading: boolean;
    removeLoading: boolean;
    onButtonClickAction: (type: 'REMOVE' | 'REASSIGN') => void;
    className?: string;
}

export const TaskApproverActionButtons: React.FC<TaskApproverActionButtonsProps> = ({
    onButtonClickAction,
    removeDisabled,
    replaceDisabled,
    replaceLoading,
    removeLoading,
    className = '',
}) => (
    <div className={`inactive-approvers-buttons-group ${className}`}>
        <Button
            className="btn--blue"
            disabled={replaceDisabled}
            isLoading={replaceLoading}
            onClick={() => onButtonClickAction('REASSIGN')}
            type="button"
        >
            {INACTIVE_APPROVERS.BUTTONS.REPLACE}
        </Button>
        <Button
            className="btn--red"
            disabled={removeDisabled}
            isLoading={removeLoading}
            onClick={() => onButtonClickAction('REMOVE')}
            type="button"
        >
            {INACTIVE_APPROVERS.BUTTONS.REMOVE}
        </Button>
    </div>
);

interface Props {
    isDisabled: boolean;
    changedApprover: IUserOptionValue | null;
    hideUsers: number[];
    loadingState: IInactiveAProverLoading | null;
    updateChangedApprover: (user: IUserOptionValue) => void;
    onButtonClickAction: (type: IInactiveApproverActions) => void;
}

// eslint-disable-next-line max-lines-per-function
const TaskApproversActions: React.FC<Props> = ({
    isDisabled,
    changedApprover,
    hideUsers,
    loadingState,
    onButtonClickAction,
    updateChangedApprover,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users.filter(
                        (u) => !hideUsers.includes(u.value as number),
                    );
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <div className="inactive-approvers-actions">
            <div className="select-approver">
                <label htmlFor="user-search" className="select-approver__label">
                    Select Approver
                </label>
                <AsyncSelect
                    name="user-search"
                    id="user-search"
                    components={animatedComponents}
                    isLoading={isLoading}
                    isMulti={false}
                    placeholder="Search user..."
                    className="react-select select-approver__select"
                    classNamePrefix="select"
                    loadOptions={promiseOptions}
                    getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                    value={changedApprover}
                    isDisabled={isDisabled}
                    noOptionsMessage={() =>
                        searchText.length >= 3
                            ? 'No results found'
                            : 'Start typing minimum 3 characters'
                    }
                    onChange={(option) => {
                        updateChangedApprover(option as IUserOptionValue);
                    }}
                    isClearable
                />
            </div>
            <TaskApproverActionButtons
                onButtonClickAction={onButtonClickAction}
                removeDisabled={isDisabled || !!loadingState}
                replaceDisabled={isDisabled || !!loadingState}
                removeLoading={loadingState?.type === 'REMOVE' && !loadingState.id}
                replaceLoading={loadingState?.type === 'REASSIGN' && !loadingState.id}
            />
        </div>
    );
};

export default TaskApproversActions;
