import { IExternalApprover, IUserOptionValue } from '../interfaces';
import { IApprovalDocStage, IDocApprovers, IExternalApprovers } from '../interfaces/document';

export type IDocumentExternalApproversAction =
    | { type: 'update'; value: IExternalApprover[] }
    | {
          type: 'update-user';
          stageIndex: number;
          approverIndex: number;
          value: IExternalApprover;
      }
    | { type: 'push'; stageIndex: number; value: IExternalApprover }
    | { type: 'delete'; stageIndex: number; index: number }

export type IDocumentApproversAction =
    | { type: 'update'; value: IApprovalDocStage[] }
    | {
          type: 'update-user';
          stageIndex: number;
          approverIndex: number;
          value: IUserOptionValue;
      }
    // | {
    //     type: 'update-deadline';
    //     stageIndex: number;
    //     approverIndex: number;
    //     adhocIndex: number;
    //     value: number;
    // }
    | {
        type: 'update-stage-deadline';
        stageIndex: number;
        value: number;
    }
      | {
        type: 'update-adhoc-user';
        stageIndex: number;
        approverIndex: number;
        adhocIndex: number;
        value: IUserOptionValue;
    }
    | { type: 'push'; stageIndex: number; value: IDocApprovers }
    | { type: 'delete'; stageIndex: number; index: number }
    | { type: 'delete-adhoc'; stageIndex: number; adhocIndex:number; approverIndex: number };

// eslint-disable-next-line max-lines-per-function
export const documentApproversReducer = (
    state: IApprovalDocStage[],
    action: IDocumentApproversAction,
): IApprovalDocStage[] => {
    switch (action.type) {
        case 'update': {
            const tempState: IApprovalDocStage[] = [];
            return tempState.concat(action.value);
        }
        case 'update-user': {
            const { approvers, scopeType } = state[action.stageIndex];
            if (scopeType === 'roleBased') {
                approvers[action.approverIndex].roleId = action.value.roleId;
            }
            approvers[action.approverIndex].employee = action.value;
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        // case 'update-deadline': {
        //     const { approvers, scopeType } = state[action.stageIndex];
        //     const { adHoc } = approvers[action.approverIndex];
        //     const newDeadline = [action.value];
        //     if(action.adhocIndex !== -1 && adHoc?.length) {
        //         // adhoc deadline to be updated
        //         adHoc[action.adhocIndex].reminders = newDeadline;
        //     } else {
        //         // regular approver deadline update
        //         approvers[action.approverIndex].reminders = newDeadline;
        //     }
            
        //     return [
        //         ...state.slice(0, action.stageIndex),
        //         { ...state[action.stageIndex], approvers },
        //         ...state.slice(action.stageIndex + 1),
        //     ];
        // }
        case 'update-stage-deadline': {
            const stageData = state[action.stageIndex];
            const reminders = [action.value];
            // console.log(`*** before update: ${JSON.stringify(stageData)} \n ***reminders: ${reminders}`)
            const newSt =  [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], reminders},
                ...state.slice(action.stageIndex + 1)
            ]

            // console.log(`*** after update: ${JSON.stringify(newSt)}`);
            return newSt;
        }
        case 'update-adhoc-user': {
            const { approvers, scopeType } = state[action.stageIndex];
            const {adHoc} = approvers[action.approverIndex];
            if(adHoc){
                adHoc[action.adhocIndex].employee = action.value
            }
            approvers[action.approverIndex].adHoc = adHoc;
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        case 'push': {
            const existingApprovers = [...state[action.stageIndex].approvers];
            // if (existingApprovers.some(approver => approver.employee.value === action.value.employee.value)) {
            //     return state;
            // }
            const updatedApprovers = [...existingApprovers,action.value];
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers:updatedApprovers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        case 'delete': {
            const { approvers } = state[action.stageIndex];
            approvers.splice(action.index, 1);
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        case 'delete-adhoc': {
            const { approvers } = state[action.stageIndex];
            const {adHoc} = approvers[action.approverIndex];
            if(adHoc){
                adHoc.splice(action.adhocIndex,1);
            }
            approvers[action.approverIndex].adHoc = adHoc;
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        default:
            return state;
        }
    };
    
    export const externalApproversReducer = (
        state: IExternalApprover[],
        action: IDocumentExternalApproversAction,
      ): IExternalApprover[] => {
    
        switch (action.type) {
            case 'update': {
                const tempState: IExternalApprover[] = [];
                return tempState.concat(action.value);
            }
          case 'update-user':
            return [
              ...state.slice(0, action.approverIndex),
              action.value,
              ...state.slice(action.approverIndex + 1),
            ];
       
          case 'push':
            return [...state, action.value];
       
          case 'delete':
            return [
              ...state.slice(0, action.index),
              ...state.slice(action.index + 1),
            ];
       
          default:
            return state;
    }
  };
