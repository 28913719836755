/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Column, useTable, usePagination, useSortBy } from 'react-table';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { SortIcon } from '../../assets/images';
import { IContractList } from '../../interfaces/contracts';
import { StatusColumn, NoDocumentsFound, DateColumn } from '../General';
import { SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';

const DocNumberDocument = (row: Row<IContractList>) => {
    const history = useHistory();
    const {
        original: { contractNumber, contractId },
    } = row;

    const onClick = () => {
        history.push({
            pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
            search: `?doc_id=${contractId}`,
        });
    };

    // Define the URL for the link
    const documentDetailsURL = `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${contractId}&prev_page_type=CONTRACT_DASHBOARD`;

    return (
        // Use an anchor tag for the link
        <a href={documentDetailsURL} className="link-style" onClick={onClick} onKeyPress={onClick}>
            <SkeletonText text={contractNumber} width={200} />
        </a>
    );
};

const columnMapping: IStringObject = {
    publishedOn: 'updatedAt',
};

const DEFAULT_SORT: ISort = {
    id: 'updatedAt',
    desc: true,
};

const COLUMNS: Column<IContractList>[] = [
    {
        Header: 'Contract Number',
        accessor: 'contractNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 75,
        disableSortBy: true,
    },
    {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 75,
        disableSortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 75,
        disableSortBy: true,
    }
];

interface Props {
    data: IContractList[];
    pagination: IPaginationData;
    fetchDocuments: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

const ContractsDashboardTable: React.FC<Props> = ({ data, pagination, fetchDocuments }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IContractList>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableSortBy: false,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        fetchDocuments(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id],
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);

    return (
        <div className="contract-admin-dashboard-table">
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                                />
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && <NoDocumentsFound heading="No Contracts Found" />}
            </div>
        </div>
    );
};

export default ContractsDashboardTable;
