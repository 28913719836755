/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getPublishedDocumentTypes } from '../../../API/document-type';
import { IOptionValue } from '../../../interfaces';

interface Props {
    name: string;
    displayName: string;
    value: IOptionValue;
    updateFilters: (name: string, value: any, type: string) => void;
}

// eslint-disable-next-line max-lines-per-function
const DocTypeFilter: React.FC<Props> = ({ displayName, name, value, updateFilters }) => {
    const [docTypesLoading, setDocTypesLoading] = useState(false);
    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setDocTypesLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const res = await getPublishedDocumentTypes(inputValue);
            if (res?.apiStatus === 'SUCCESS') {
                const { docTypeCard } = res;
                const docTypes: IOptionValue[] = [
                    {
                        value: '',
                        label: 'All',
                    },
                ];
                // eslint-disable-next-line no-restricted-syntax
                for (const d of docTypeCard) {
                    if (name === 'documentTypeCode') {
                        docTypes.push({ label: d.name, value: d.code });
                    } else {
                        docTypes.push({ label: d.name, value: d.id });
                    }
                }
                resolve(docTypes);
                setDocTypesLoading(false);
            }
        });
    };

    return (
        <div>
            <label htmlFor={name}>{displayName}</label>
            <AsyncSelect
                name={name}
                id={name}
                isMulti={false}
                className="react-select"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                defaultOptions
                isLoading={docTypesLoading}
                value={value}
                onChange={(option) => {
                    if (option) updateFilters(name, option, 'DOC_TYPE');
                }}
            />
        </div>
    );
};

export default DocTypeFilter;
