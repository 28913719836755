import React from 'react';

import { useSunsetContext } from '../../contexts/sunset-provider';
import MySunsetDocsTab from './MySunsetDocsTab';
import MySunsetTasksTab from './MySunsetTasksTab';

const SunsetTable: React.FC = () => {
    const { activeTab } = useSunsetContext();
    return (
        <div className="dashboard-table">
            {activeTab === 'MY_SUNSET_DOCS' && <MySunsetDocsTab />}
            {activeTab === 'MY_SUNSET_TASKS' && <MySunsetTasksTab />}
        </div>
    );
};

export default SunsetTable;
