/* eslint-disable import/prefer-default-export */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import qs from 'qs';

import { IAPIResponseStatus, IStringObject } from '../interfaces';
import { ISunsetDocs, ISunsetTasks } from '../interfaces/sunset';
import { getRequest, postRequest } from '../utils/api-request';
import { convertEpochToDate } from '../utils/date';

interface ITaskVariables {
    name: string;
    value: string;
    operator: 'eq';
}

const getMySunsetDocs = async (
    queryParams: IStringObject,
): Promise<
    | { apiStatus: IAPIResponseStatus; mySunsetDocs: ISunsetDocs[]; totalDocuments: number }
    | undefined
> => {
    try {
        const response = await getRequest(
            `documents/my-documents/sunset?${qs.stringify(queryParams)}`,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const mySunsetDocs: ISunsetDocs[] = [];

            const documents = response.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const document of documents) {
                mySunsetDocs.push({
                    createdDate: convertEpochToDate(document.createdAt),
                    docId: document.id,
                    docNumber: document.documentNumber,
                    files: document.activeDocumentVersion.attachmentCount
                        ? document.activeDocumentVersion.attachmentCount
                        : 0,
                    title: document.title,
                    isConfidential: document.confidential,
                    activeStatus: document.activeDocumentVersion.activeVersionState,
                    lastPublishedStatus: document.approvedDocumentVersion.approvedDocumentState,
                    publishedOn: convertEpochToDate(document.activeDocumentVersion.publishedOn),
                });
            }

            return {
                apiStatus: response.apiStatus,
                mySunsetDocs,
                totalDocuments: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, mySunsetDocs: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getMySunsetTasks = async (
    queryParams: IStringObject,
    sorting: {
        sortBy: string;
        sortOrder: 'desc' | 'asc';
    },
    limit = 10,
    offset = 0,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          mySunsetTasks: ISunsetTasks[];
          totalDocuments: number;
      }
    | undefined
> => {
    try {
        const body: {
            [key: string]: any;
        } = {
            sorting: [sorting],
        };
        const taskVariables: ITaskVariables[] = [];

        const queryKeys = Object.keys(queryParams);
        for (const key of queryKeys) {
            if (queryParams[key]) {
                taskVariables.push({
                    name: key,
                    value: queryParams[key],
                    operator: 'eq',
                });
            }
        }
        if (taskVariables.length > 0) {
            body.taskVariables = taskVariables;
        }
        const response = await postRequest(
            `tasks/sunset-task-list-2/me?maxResults=${limit}&firstIndex=${offset}`,
            body,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const { tasks } = response.response;
            const totalDocuments = response.response.count ? response.response.count : 0;
            const mySunsetTasks: ISunsetTasks[] = [];
            for (const task of tasks) {
                mySunsetTasks.push({
                    taskId: task.taskId,
                    title: 'Test',
                    sunsetDate: new Date(task.startTime),
                    assigneeId: task.assigneeId,
                    docNumber: task.documentNumber,
                    docTypeCode: task.docTypeCode,
                    action: task.action,
                    docId: task.documentId,
                    activeVersionId: task.versionId,
                });
            }
            return {
                apiStatus: response.apiStatus,
                mySunsetTasks,
                totalDocuments,
            };
        }
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return {
                apiStatus: response.apiStatus,
                mySunsetTasks: [],
                totalDocuments: 0,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export { getMySunsetDocs, getMySunsetTasks };
