/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import Loader from 'react-loader-spinner';
import {
    createAttachment,
    deleteAttachment,
    downloadAttachment,
    updateAttachment,
} from '../../../API/attachments';
import {
    AttachmentIcon,
    BadgeIcon,
    DeleteSvg,
    EditSvg,
    InvalidSvg,
    PlusIconSvg,
    UploadIcon,
} from '../../../assets/images';

import { createDocument, updateDocument, updateDocumentVersionFieldsData, updateFileDocument } from '../../../API/document';
import { getDocTypes } from '../../../API/document-type';
import CONSTANTS from '../../../constants';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { useDocumentContext } from '../../../contexts/document-provider';
import useFileUpload from '../../../hooks/use-file-upload';
import {
    IAttachment,
    IAttachmentConfig,
    IAttachmentMeta,
    IFileTypeDoc,
    IFileUpload,
} from '../../../interfaces/attachment';
import { getExtension } from '../../../utils';
import { convertEpochToFormatDate, formatDateTime } from '../../../utils/date';
import ProgressBarComponent from '../../../widgets/progress-bar/ProgressBar';
import CopyText from '../../CopyText';
import Toggle from '../../toggle/Toggle';
import { DownloadAttachment } from '../view/ViewAttachments';
import AddToExistingFilesModal from './AddToExistingFilesModal';
import './EditAttachments.scss';
import { IFieldsData } from '../../../interfaces/document';
// import { Tooltip } from 'react-bootstrap';

interface IAttachmentProps {
    attachmentConfig: IAttachmentConfig;
    index: number;
    attachments: IAttachment[];
    fetchAttachments: () => Promise<void>;
    errorFields: string[];
    latestrelationArr: any[];
}
const { CREATE_DOCUMENTS } = STATIC_CONTENT;

const Attachment: React.FC<IAttachmentProps> = ({
    attachmentConfig,
    index,
    attachments,
    fetchAttachments,
    errorFields,
    latestrelationArr,
}: IAttachmentProps) => {
    const { uploadFile, onDrop } = useFileUpload();
    const [fileName, setFileName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showFilesSearchModal, setShowFilesSearchModal] = useState(false);
    const [modalFileName, setModalFileName] = useState('');
    const [attachmentDesc, setAttachmentDesc] = useState('');
    const [attachmentID, setAttachmentID] = useState(0);
    const [updateAttachmentLoading, setUpdateAttachmentLoading] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [removeID, setRemoveID] = useState(0);
    const {
        documentId,
        selectedDocVersion,
        setIsFileUploading,
        docVersionCheckedInFlag,
        checklist,
        documentDispatch,
        fieldsData,
        saveFieldsData,
    } = useDocumentContext();
    const [uploading, setUploading] = useState(false);
    const [attachmentMandatory, setattachmentMandatory] = useState(attachmentConfig.isMandatory);
    const defaultFileDetails = {
        title: '',
        description: '',
        isPrimary: false,
        attachment: {
            name: '',
            size: 0,
            file: new File([''], ''),
            mimeType: '',
            source: new URL('https://developer.mozilla.org'),
            extension: '',
        },
    };
    const [fileDetails, setFileDetails] = useState({
        title: '',
        description: '',
        isPrimary: false,
        attachment: {
            name: '',
            size: 0,
            file: new File([''], ''),
            mimeType: '',
            source: new URL('https://developer.mozilla.org'),
            extension: '',
        },
    });
    const defaultFileTypeData: IFileTypeDoc = {
        id: 0,
        parentDocId: 0,
        docVersionId: 0,
        attachmentId: 0,
        attachmentType: 'file',
        displayName: 'File',
        extension: '',
        mimeType: '',
        name: '',
        size: 0,
        fileNumber: '',
        title: '',
        description: '',
        isPrimary: false,
        uploadedOn: 0,
        releasedOn: null,
    };
    const [fileCreationLoading, setFileCreationLoading] = useState(false);
    const [selectedFileForEdit, setSelectedFileForEdit] =
        useState<IFileTypeDoc>(defaultFileTypeData);
    const [showEditFileModal, setShowEditFileModal] = useState(false);
    const [fileUpdationLoading, setFileUpdationLoading] = useState(false);
    const q1 = { question: 'Redline Document attached?', value: null };

    useEffect(() => {
        if (Array.isArray(checklist)) {
            const item1 = checklist.find((item) => item.question === q1.question);
            if (item1) {
                const { value } = item1;
                if (value === null || value === 'no') {
                    setattachmentMandatory(false);
                } else setattachmentMandatory(true);
            }
        }
    }, [checklist]);

    const uploadAttachment = async (file: File, metaData: IAttachmentMeta) => {
        setIsFileUploading(true);
        setUploading(true);
        const res = await createAttachment(
            documentId,
            selectedDocVersion.value as number,
            file,
            metaData,
        );
        if (res?.apiStatus === 'FAILURE') {
            toast(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_UPLOAD);
        }
        if (res?.apiStatus === 'SUCCESS') {
            await fetchAttachments();
            setFileName('');
            if (attachmentConfig.allowDragDrop) {
                setModalFileName(metaData.name);
                setTimeout(() => {
                    setShowModal(true);
                    setAttachmentDesc('');
                    setAttachmentID(0);
                }, 1000);
            }
        }
        setUploading(false);
        setIsFileUploading(false);
    };

    const uploadAttachmentCallback = async ({
        name,
        size,
        file,
        mimeType,
        source,
        extension,
    }: IFileUpload) => {
        // const regex = new RegExp(/^[ A-Za-z0-9_@.*()#&-]*$/);
        const regexForName = new RegExp(/^[ A-Za-z0-9_@*()#&-]*$/);
        const metaData: IAttachmentMeta = {
            attachmentType: attachmentConfig.attachmentType,
            displayName: attachmentConfig.displayName,
            extension,
            mimeType,
            name,
            size,
        };
        const nameWithoutExtension = name.replace(/\.[^.]*$/, '');
        if (regexForName.test(nameWithoutExtension)) {
            if (
                attachmentConfig.allowedFileTypes.length > 0 &&
                !attachmentConfig.allowedFileTypes
                    .map((a) => a.toLowerCase())
                    .includes(metaData.extension.toLowerCase())
            ) {
                toast.error(
                    CREATE_DOCUMENTS.TOAST.ERROR_MSG.FILE_NOT_SUPPORTED +
                    attachmentConfig.allowedFileTypes.join(', ').toLowerCase(),
                );
            } else if (size > 26214400) {
                toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.FILE_LARGER_THAN_25);
            } else {
                setFileName(name);
                if (attachmentConfig.allowMultiple && !attachmentConfig.allowFileTypeAttachment) {
                    await uploadAttachment(file, metaData);
                } else {
                    const [attachment] = attachments;
                    if (attachment) {
                        const res = await deleteAttachment(attachment.id);
                        if (res === 'SUCCESS') {
                            await uploadAttachment(file, metaData);
                        }
                        if (res === 'FAILURE') {
                            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
                        }
                    } else {
                        await uploadAttachment(file, metaData);
                    }
                }
            }
        } else {
            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.INVALID_FILENAME);
        }
    };

    const onClick = async (fileFlag = false) => {
        uploadFile(
            {
                accept: attachmentConfig.allowedFileTypes.map((a) => `.${a}`).toString(),
            },
            async ({ name, size, file, mimeType }: IFileUpload) => {
                const source = URL.createObjectURL(file) as unknown as URL;
                const extension = getExtension(name);
                const attachmentObj = {
                    name,
                    size,
                    file,
                    mimeType,
                    source,
                    extension,
                };
                setFileDetails({ ...fileDetails, attachment: attachmentObj });
                if (fileFlag) {
                    return;
                }
                await uploadAttachmentCallback({ name, size, file, mimeType, source, extension });
            },
        );
    };

    const removeAttachment = async (attachmentId: number) => {
        setUpdateAttachmentLoading(true);
        const res = await deleteAttachment(attachmentId);
        if (res === 'SUCCESS') {
            fetchAttachments();
        }
        if (res === 'FAILURE') {
            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
        setUpdateAttachmentLoading(false);
        setDeleteModal(false);
    };

    const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        onDrop(ev, async ({ name, size, file, mimeType }: IFileUpload) => {
            const source = URL.createObjectURL(file) as unknown as URL;
            const extension = getExtension(name);
            await uploadAttachmentCallback({ name, size, file, mimeType, source, extension });
        });
    };

    const attachDescSubmit = async () => {
        setUpdateAttachmentLoading(true);
        let attachmentIdValue;
        if (attachmentID) {
            attachmentIdValue = attachmentID;
        } else {
            const attachment = attachments.pop();
            attachmentIdValue = attachment?.id;
        }
        if (attachmentIdValue) {
            const res = await updateAttachment(attachmentIdValue, attachmentDesc);
            if (res === 'SUCCESS') {
                fetchAttachments();
            }
            if (res === 'FAILURE') {
                toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_UPDATE_ATTACHMENT);
            }
        }
        setUpdateAttachmentLoading(false);
        setShowModal(false);
        setAttachmentID(0);
        setModalFileName('');
        setAttachmentDesc('');
    };

    const addEditDescription = (a: IAttachment) => {
        setAttachmentID(a.id);
        setModalFileName(a.fileMeta.name);
        setShowModal(true);
        setAttachmentDesc(a.description);
    };

    const closeModal = () => {
        setShowModal(false);
        setAttachmentID(0);
        setModalFileName('');
        setAttachmentDesc('');
        setFileDetails(defaultFileDetails);
        setSelectedFileForEdit(defaultFileTypeData);
    };

    const closeFilesSearchModal = () => {
        setShowFilesSearchModal(false);
        // setAttachmentID(0);
        // setModalFileName('');
        // setAttachmentDesc('');
    };

    function handleNewFileAdd(e: any) {
        setShowModal(true);
    }

    function handleExistingFileAdd(e: any) {
        setShowFilesSearchModal(true);
        closeModal();
    }

    async function handleNewFileCreate(e: any) {
        const queryParams = {
            inputStr: 'File',
            sortBy: 'asc',
        };
        let fileDocTypeId = -1;
        setFileCreationLoading(true);
        const res = await getDocTypes(queryParams);
        if (res?.apiStatus === 'SUCCESS' && res?.docTypeCard.length) {
            fileDocTypeId = res?.docTypeCard[0]?.id;
        } else {
            toast.error('Something went wrong while fetching File type');
        }

        if (fileDocTypeId !== -1) {
            const createFileRes = await createDocument(fileDocTypeId, {
                parentDocId: documentId,
            });
            if (createFileRes?.apiStatus === 'SUCCESS') {
                const docUpdateRes = await updateDocument(
                    fileDetails.title,
                    fileDetails.description,
                    false,
                    false,
                    createFileRes.docId,
                    false,
                );
                const updatedFieldsData:IFieldsData[] = [{
                    name: 'description',
                    value: fileDetails.description
                }]
                const updateDocVersion = await updateDocumentVersionFieldsData(updatedFieldsData, documentId, createFileRes.docVersionId, false);

                if (docUpdateRes?.apiStatus === 'SUCCESS') {
                    if (fileDetails.attachment.size > 26214400) {
                        toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.FILE_LARGER_THAN_25);
                    } else {
                        const metaData: IFileTypeDoc = {
                            id: createFileRes.docId,
                            parentDocId: documentId,
                            docVersionId: createFileRes.docVersionId,
                            attachmentId: 0,
                            attachmentType: 'file', // it should match the internal name in attachment config of doc type
                            displayName: 'File', // display name in doc type attachment config
                            extension: fileDetails.attachment.extension,
                            mimeType: fileDetails.attachment.mimeType,
                            name: fileDetails.attachment.name,
                            size: fileDetails.attachment.size,
                            fileNumber: createFileRes?.docNumber as string,
                            title: fileDetails.title,
                            description: fileDetails.description,
                            isPrimary: fileDetails.isPrimary,
                            uploadedOn: Math.round(Date.now() / 1000),
                            releasedOn: null,
                        };
                        const createAttachmentRes = await createAttachment(
                            createFileRes.docId,
                            createFileRes.docVersionId,
                            fileDetails.attachment.file,
                            metaData,
                        );
                        if (createAttachmentRes?.apiStatus === 'SUCCESS') {
                            metaData.attachmentId = createAttachmentRes.id
                                ? createAttachmentRes.id
                                : 0;
                            documentDispatch({
                                type: 'file-list',
                                value: [metaData],
                                inputName: 'file',
                            });
                            const flag = metaData.isPrimary;
                            if (flag) {
                                // reset to false for rest
                                const selectedFileNumber = metaData.fileNumber;
                                documentDispatch({
                                    type: 'file-list-update-primary',
                                    inputName: 'file',
                                    fileNumber: selectedFileNumber,
                                });
                            }
                            setFileDetails(defaultFileDetails);
                            toast.success('File created successfully');
                        } else {
                            toast.error('File could not be created');
                        }
                    }
                }
            } else if (createFileRes?.apiStatus === 'FAILURE') {
                toast.error(createFileRes.errorMessage);
            }
        } else {
            toast.error('Something went wrong while creating new file');
        }
        setFileCreationLoading(false);
        setShowModal(false);
    }

    const [filesData] = fieldsData.filter((field, idx) => field.name === 'file');

    async function handleRemoveFileClick(fIdx: number) {
        documentDispatch({
            type: 'remove-file',
            fIdx,
        });
    }
    function handleEditFileClick(file: IFileTypeDoc) {
        setSelectedFileForEdit(file);
        setShowEditFileModal(true);
    }
    function closeEditFileModal() {
        setShowEditFileModal(false);
        setFileDetails(defaultFileDetails);
        setSelectedFileForEdit(defaultFileTypeData);
    }

    async function handleSaveEditedFile() {
        setFileUpdationLoading(true);
        const docId = selectedFileForEdit.id;
        if (docId) {
            const docUpdateRes = await updateFileDocument(
                selectedFileForEdit.title,
                selectedFileForEdit.description,
                false,
                false,
                docId,
                selectedFileForEdit.docVersionId,
                false,
            );

            const isAttachmentUpdated = fileDetails.attachment.name !== '';
            let newAttachmentId = selectedFileForEdit.attachmentId;
            if (selectedFileForEdit.id && selectedFileForEdit.attachmentId && isAttachmentUpdated) {
                if (docUpdateRes && !docUpdateRes.wasRevised) {
                    await removeAttachment(selectedFileForEdit.attachmentId);
                } else if(docUpdateRes && docUpdateRes.wasRevised && docUpdateRes?.newAttachments?.length) {
                    await removeAttachment(docUpdateRes?.newAttachments[0]);
                }
                const attachmentMetaData:IAttachmentMeta = {
                    name: isAttachmentUpdated ? fileDetails.attachment.name : selectedFileForEdit.name,
                    extension: isAttachmentUpdated ? fileDetails.attachment.extension : selectedFileForEdit.extension,
                    mimeType: isAttachmentUpdated ? fileDetails.attachment.mimeType : selectedFileForEdit.mimeType,
                    size: isAttachmentUpdated ? fileDetails.attachment.size : selectedFileForEdit.size,
                    attachmentType: 'file',
                    displayName: 'File'
                }

                const createAttachmentRes = await createAttachment(
                    selectedFileForEdit.id,
                    docUpdateRes?.newVersionId ? docUpdateRes?.newVersionId : selectedFileForEdit.docVersionId,
                    fileDetails.attachment.file,
                    attachmentMetaData,
                );

                if (createAttachmentRes?.apiStatus === 'SUCCESS' && createAttachmentRes.id) {
                    newAttachmentId = createAttachmentRes.id;
                } else {
                    toast.error('Something went wrong');
                    return;
                }
            }
            const metaData: IFileTypeDoc = {
                id: selectedFileForEdit.id,
                parentDocId: documentId,
                docVersionId: docUpdateRes?.newVersionId
                    ? docUpdateRes?.newVersionId
                    : selectedFileForEdit.docVersionId,
                attachmentId: (docUpdateRes?.newAttachments?.length && !isAttachmentUpdated) ? docUpdateRes?.newAttachments[0] : newAttachmentId,
                attachmentType: 'file', // it should match the internal name in attachment config of doc type
                displayName: 'File', // display name in doc type attachment config
                extension: isAttachmentUpdated
                    ? fileDetails.attachment.extension
                    : selectedFileForEdit.extension,
                mimeType: isAttachmentUpdated
                    ? fileDetails.attachment.mimeType
                    : selectedFileForEdit.mimeType,
                name: isAttachmentUpdated ? fileDetails.attachment.name : selectedFileForEdit.name,
                size: isAttachmentUpdated ? fileDetails.attachment.size : selectedFileForEdit.size,
                fileNumber: selectedFileForEdit?.fileNumber,
                title: selectedFileForEdit.title,
                description: selectedFileForEdit.description,
                isPrimary: selectedFileForEdit.isPrimary,
                uploadedOn: Math.round(Date.now() / 1000),
                releasedOn: null,
            };

            if (docUpdateRes?.apiStatus === 'SUCCESS') {
                metaData.uploadedOn = Math.round(Date.now() / 1000);
                metaData.releasedOn = null;
                documentDispatch({
                    type: 'file-list-update',
                    value: [metaData],
                    inputName: 'file',
                });
                toast.success('File updated successfully');
            }
        }
        setFileUpdationLoading(false);
        closeEditFileModal();
    }

    useEffect(() => {
        if (fileUpdationLoading) {
            saveFieldsData(false);
        }
    }, [fieldsData]);

    function handlePrimaryToggleWhileEditing() {
        const flag = selectedFileForEdit.isPrimary;
        setSelectedFileForEdit({
            ...selectedFileForEdit,
            isPrimary: !selectedFileForEdit.isPrimary,
        });
        if (!flag) {
            // reset to false for rest
            const selectedFileNumber = selectedFileForEdit.fileNumber;
            documentDispatch({
                type: 'file-list-update-primary',
                inputName: 'file',
                fileNumber: selectedFileNumber,
            });
        }
    }

    function handlePrimaryToggleWhileCreating() {
        const flag = fileDetails.isPrimary;
        setFileDetails({ ...fileDetails, isPrimary: !fileDetails.isPrimary });
        if (!flag) {
            // reset to false for rest
        }
    }

    return (
        <div key={index}>
            <label htmlFor="cover-letter" className={attachmentMandatory ? 'attachment-label mandatory-field' : ''}>
                {attachmentConfig.displayName}
            </label>
            {attachmentConfig.allowDragDrop && !attachmentConfig.allowFileTypeAttachment && (
                <div className="dco-drop-zone">
                    <div
                        className={`${errorFields.includes(attachmentConfig.attachmentType)
                            ? 'drop-zone-error'
                            : ''
                            } drop-zone`}
                        onDrop={handleDrop}
                        onDragOver={(ev) => ev.preventDefault()}
                    >
                        <div className="content-wrapper">
                            <UploadIcon />
                            <span>
                                <button
                                    type="button"
                                    onClick={() => onClick()}
                                    disabled={
                                        uploading ||
                                        latestrelationArr.some(
                                            (obj) => obj.state === 'UNDER_REVIEW',
                                        ) ||
                                        docVersionCheckedInFlag
                                    }
                                >
                                    Choose file
                                </button>{' '}
                                or drag here
                            </span>
                            <span className="note">
                                {CREATE_DOCUMENTS.NOTE}
                                {attachmentConfig?.allowedFileTypes.join(',').toLowerCase()}
                            </span>
                            <span className="note">{CREATE_DOCUMENTS.NOTE_END}</span>
                        </div>
                    </div>
                    {errorFields.includes(attachmentConfig.attachmentType) && (
                        <span className="error-message">
                            <InvalidSvg /> This field is mandatory
                        </span>
                    )}
                    <div className="attachment-list">
                        {attachments.length > 0 && (
                            <div className="attachment-item files-header">
                                <div className="attachment-name">
                                    <h5 style={{ color: 'black' }}>Name</h5>
                                </div>

                                <div className="attachment-name doc-description">
                                    <h5 style={{ color: 'black' }}>Description</h5>
                                </div>

                                <div className="attachment-name uploaded">
                                    <h5 style={{ color: 'black' }}>Uploaded on</h5>
                                </div>

                                <div className="attachment-name remove-icon">
                                    <h5 style={{ color: 'black' }}>Actions</h5>
                                </div>
                            </div>
                        )}
                        {attachments.map((a) => (
                            <div className="attachment-item" key={a.id}>
                                <div className="attachment-name">
                                    <div
                                        onClick={() => downloadAttachment(a.id, a.fileMeta.name)}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter' || event.key === ' ') {
                                                downloadAttachment(a.id, a.fileMeta.name);
                                            }
                                        }}
                                        title="Download Attachment"
                                    >
                                        <AttachmentIcon
                                            style={{ transform: 'rotate(0deg)' }}
                                            width="17"
                                        />
                                        <h5>{a.fileMeta.name}</h5>
                                    </div>
                                    <span className="file-size">{`(${(
                                        a.fileMeta.size /
                                        1024 ** 2
                                    ).toFixed(2)}MB)`}</span>
                                </div>
                                <span className="doc-description">
                                    {a.description ? a.description : ''}
                                </span>
                                <span className="uploaded">
                                    {a.createdAt
                                        ? `Uploaded on ${convertEpochToFormatDate(a.createdAt)}`
                                        : ''}
                                </span>
                                {!(
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                ) && (
                                        <div className="remove-icon">
                                            <EditSvg
                                                onClick={() => addEditDescription(a)}
                                                title={`${a.description ? 'Edit' : 'Add'} Description`}
                                                style={{ transform: 'rotate(0deg)' }}
                                                height="20"
                                                width="20"
                                            />
                                            <DeleteSvg
                                                className="delete-svg"
                                                onClick={() => {
                                                    setRemoveID(a.id);
                                                    setDeleteModal(true);
                                                }}
                                                title="Delete Attachment"
                                            />
                                        </div>
                                    )}
                            </div>
                        ))}
                        {fileName && (
                            <div className="attachment-item">
                                <h5>{fileName}</h5>
                                <ProgressBarComponent />
                            </div>
                        )}
                    </div>
                    <Modal
                        isOpen={showModal}
                        onRequestClose={closeModal}
                        className="add-description"
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="description-heading">
                            <h1 className="mandatory-field">{`${!attachmentDesc ? 'Add' : 'Edit'
                                } Description`}</h1>
                            {'\u00A0'}
                            {'\u00A0'}
                            {'\u00A0'}-{'\u00A0'}
                            {'\u00A0'}
                            <span className="file-size">{`(${modalFileName})`}</span>
                        </div>
                        <div className="description-section">
                            <label>Description</label>
                            <input
                                type="text"
                                className="description-value"
                                placeholder="Enter Description ..."
                                value={attachmentDesc}
                                onChange={(e) => setAttachmentDesc(e.target.value)}
                            />
                        </div>
                        <div className="action-buttons">
                            <button
                                type="submit"
                                className="primary-btn submit-button"
                                onClick={attachDescSubmit}
                                disabled={!attachmentDesc}
                            >
                                <span className="submit">Save</span>
                                {updateAttachmentLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={showDeleteModal}
                        onRequestClose={() => setDeleteModal(false)}
                        className="delete-attachment"
                    >
                        <PlusIconSvg className="close-btn" onClick={() => setDeleteModal(false)} />
                        <div className="heading">
                            <h1>Are you sure you want to Delete</h1>
                            <h4>Selected attachment would be lost</h4>
                        </div>
                        <div className="action-buttons">
                            <button type="submit" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="primary-btn submit-button"
                                onClick={() => removeAttachment(removeID)}
                            >
                                <span className="submit">Delete</span>
                                {updateAttachmentLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div>
                    </Modal>
                </div>
            )}
            {attachmentConfig.allowFileTypeAttachment && (
                <div className="dco-drop-zone">
                    {errorFields.includes(attachmentConfig.attachmentType) && (
                        <span className="error-message">
                            <InvalidSvg /> This field is mandatory
                        </span>
                    )}

                    <div className="attachment-list">
                        <div className="attachment-item files-header">
                            <div className="attachment-name badge">
                                <h5 style={{ color: 'black' }}>File Number</h5>
                            </div>
                            <div className="attachment-name download">
                                <h5 style={{ color: 'black' }}>Attachment</h5>
                            </div>
                            <div className="attachment-name download">
                                <h5 style={{ color: 'black' }}>Description</h5>
                            </div>
                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>Uploaded on</h5>
                            </div>

                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>Released on</h5>
                            </div>

                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>Actions</h5>
                            </div>
                        </div>
                        {filesData &&
                            filesData?.value?.map((currFile: IFileTypeDoc, fIdx: number) => {
                                const uploadDate = new Date(currFile.uploadedOn * 1000);
                                let releaseDate;
                                if (currFile.releasedOn && currFile.releasedOn !== 'N/A') {
                                    releaseDate = new Date(currFile.releasedOn * 1000);
                                }
                                return (
                                    <div className="attachment-item" key={currFile?.id}>
                                        <div className="attachment-name badge">
                                            <h5 style={{ color: 'black' }}>
                                                {currFile.fileNumber}
                                            </h5>
                                            {currFile.isPrimary && (
                                                <span
                                                    className="primary-file"
                                                >Primary</span>
                                            )}
                                        </div>
                                        <div className="attachment-name download">
                                            <h5 style={{ color: 'black' }}>{currFile.name}</h5>
                                        </div>

                                        <div className="attachment-name download">
                                            <h5 style={{ color: 'black' }}>
                                                {currFile.description || 'N/A'}
                                            </h5>
                                        </div>

                                        <div className="attachment-name">
                                            <h5 style={{ color: 'black' }}>
                                                {formatDateTime(uploadDate)}
                                            </h5>
                                        </div>

                                        <div className="attachment-name">
                                            <h5 style={{ color: 'black' }}>
                                                {releaseDate
                                                    ? formatDateTime(releaseDate)
                                                    : 'N/A'}
                                            </h5>
                                        </div>

                                        <div className="file-actions">
                                            <DownloadAttachment
                                                attachmentId={currFile.attachmentId}
                                                fileName={currFile.name}
                                            />
                                            <div
                                                className="copy-smartlink"
                                                data-tip="Copy Smartlink"
                                                data-for="tooltip1"
                                            >
                                                <CopyText
                                                    text=""
                                                    link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.fileDetails.WPUrl}?doc_id=${currFile.id}`}
                                                    differentIcon
                                                />
                                            </div>
                                            <ReactTooltip
                                                className="tooltip-docinfo"
                                                id="tooltip1"
                                                effect="solid"
                                                place="top"
                                            />
                                            {!docVersionCheckedInFlag && (
                                                <>
                                                    <EditSvg
                                                        onClick={() =>
                                                            handleEditFileClick(currFile)
                                                        }
                                                        data-tip="Edit Details"
                                                        data-for="tooltip2"
                                                    />
                                                    <ReactTooltip
                                                        className="tooltip-docinfo"
                                                        id="tooltip2"
                                                        effect="solid"
                                                        place="top"
                                                    />
                                                    <DeleteSvg
                                                        onClick={() => handleRemoveFileClick(fIdx)}
                                                        data-tip="Delete"
                                                        data-for="tooltip3"
                                                    />
                                                    <ReactTooltip
                                                        className="tooltip-docinfo"
                                                        id="tooltip3"
                                                        effect="solid"
                                                        place="top"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        {!filesData?.value.length ? (
                            <div className="no-attachments">
                                <h3>No Files Added</h3>
                            </div>
                        ) : null}
                        {!docVersionCheckedInFlag && (
                            <div className="add-attachment">
                                <button
                                    type="button"
                                    className="btn add-to-change"
                                    onClick={handleNewFileAdd}
                                >
                                    + New/Existing File
                                </button>
                            </div>
                        )}
                    </div>
                    <Modal
                        isOpen={showModal}
                        onRequestClose={closeModal}
                        className="add-description"
                        shouldCloseOnOverlayClick={false}
                    >
                        <PlusIconSvg className="close-btn" onClick={closeModal} />
                        <div className="description-heading">
                            <h1>Add File Details</h1>
                        </div>
                        {/* <div className="description-section">
                            <label>Title</label>
                            <input
                                type="text"
                                className="description-value"
                                placeholder="Enter Description ..."
                                value={fileDetails.title}
                                onChange={(e) => setFileDetails({ ...fileDetails, title: e.target.value })}
                            />
                        </div> */}
                        <div className="description-section">
                            <label>Description</label>
                            <input
                                type="text"
                                className="description-value"
                                placeholder="Enter Description ..."
                                value={fileDetails.description}
                                onChange={(e) =>
                                    setFileDetails({
                                        ...fileDetails,
                                        title: e.target.value,
                                        description: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="description-section file-attachment">
                            <label className="mandatory-field">Attachment</label>
                            <div className="upload-btn">
                                <button
                                    type="button"
                                    className={`${errorFields.includes(attachmentConfig.attachmentType)
                                        ? ' error-btn '
                                        : ''
                                        }`}
                                    onClick={() => onClick(true)}
                                    disabled={uploading}
                                >
                                    {fileDetails.attachment.name ? 'Replace' : 'Upload a file'}
                                </button>
                                <div className="attachment-list">
                                    {fileDetails.attachment.name && (
                                        <div className="attachment-item">
                                            <h5>{fileDetails.attachment.name}</h5>
                                            {/* <Loader
                                                type="Oval"
                                                color={CONSTANTS.BRAND_COLOR}
                                                height={14}
                                                width={14}
                                            /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="description-section">
                            <label>Primary</label>
                            <Toggle
                                isChecked={fileDetails.isPrimary}
                                onclick={handlePrimaryToggleWhileCreating}
                                isDisabled={false}
                            />
                        </div>
                        <div className="action-buttons">
                            <button
                                type="submit"
                                className="primary-btn submit-button"
                                onClick={handleNewFileCreate}
                                disabled={!fileDetails.attachment.name || fileCreationLoading}
                            >
                                <span className="submit">Save</span>
                                {fileCreationLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div>
                        <div className="add-existing">
                            <h1>Or add an existing file</h1>
                            <button
                                type="button"
                                className="btn add-to-change"
                                onClick={handleExistingFileAdd}
                            >
                                Add File
                            </button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={showEditFileModal}
                        onRequestClose={closeEditFileModal}
                        className="add-description"
                        shouldCloseOnOverlayClick={false}
                    >
                        <PlusIconSvg className="close-btn" onClick={closeEditFileModal} />
                        <div className="description-heading">
                            <h1 className="">Edit File Details</h1>
                        </div>
                        {/* <div className="description-section">
                            <label>Title</label>
                            <input
                                type="text"
                                className="description-value"
                                placeholder="Enter Description ..."
                                value={selectedFileForEdit.title}
                                onChange={(e) => setSelectedFileForEdit({ ...selectedFileForEdit, title: e.target.value })}
                            />
                        </div> */}
                        <div className="description-section">
                            <label>Description</label>
                            <input
                                type="text"
                                className="description-value"
                                placeholder="Enter Description ..."
                                value={selectedFileForEdit.description}
                                onChange={(e) =>
                                    setSelectedFileForEdit({
                                        ...selectedFileForEdit,
                                        title: e.target.value,
                                        description: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="description-section file-attachment">
                            <label className="mandatory-field">Attachment</label>
                            <div className="upload-btn">
                                <button
                                    type="button"
                                    className={`${errorFields.includes(attachmentConfig.attachmentType)
                                        ? ' error-btn '
                                        : ''
                                        }`}
                                    onClick={() => onClick(true)}
                                    disabled={uploading}
                                >
                                    Replace
                                </button>
                                <div className="attachment-list">
                                    <div className="attachment-item">
                                        <h5>
                                            {fileDetails.attachment.name
                                                ? fileDetails.attachment.name
                                                : selectedFileForEdit.name}
                                        </h5>
                                        {/* <Loader
                                            type="Oval"
                                            color={CONSTANTS.BRAND_COLOR}
                                            height={14}
                                            width={14}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="description-section">
                            <label>Primary</label>
                            <Toggle
                                isChecked={selectedFileForEdit.isPrimary}
                                onclick={handlePrimaryToggleWhileEditing}
                                isDisabled={false}
                            />
                        </div>
                        <div className="action-buttons">
                            <button
                                type="submit"
                                className="primary-btn submit-button"
                                onClick={handleSaveEditedFile}
                                disabled={!selectedFileForEdit.name || fileUpdationLoading}
                            >
                                <span className="submit">Save</span>
                                {fileUpdationLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div>
                    </Modal>
                    <AddToExistingFilesModal
                        show={showFilesSearchModal}
                        handleClose={closeFilesSearchModal}
                        childDocVersionId={9409}
                        latestrelationArr={[]}
                    />
                    <Modal
                        isOpen={showDeleteModal}
                        onRequestClose={() => setDeleteModal(false)}
                        className="delete-attachment"
                    >
                        <PlusIconSvg className="close-btn" onClick={() => setDeleteModal(false)} />
                        <div className="heading">
                            <h1>Are you sure you want to Delete</h1>
                            <h4>Selected attachment would be lost</h4>
                        </div>
                        <div className="action-buttons">
                            <button type="submit" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="primary-btn submit-button"
                                onClick={() => removeAttachment(removeID)}
                            >
                                <span className="submit">Delete</span>
                                {updateAttachmentLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div>
                    </Modal>
                </div>
            )}
            {!attachmentConfig.allowDragDrop && !attachmentConfig.allowFileTypeAttachment && (
                <>
                    <button
                        type="button"
                        className={`${errorFields.includes(attachmentConfig.attachmentType)
                            ? ' error-btn '
                            : ''
                            }`}
                        onClick={() => onClick()}
                        disabled={
                            uploading ||
                            latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                            docVersionCheckedInFlag
                        }
                    >
                        {!attachmentConfig.allowMultiple && attachments.length === 1
                            ? 'Replace'
                            : 'Upload a file'}
                    </button>
                    <div className="attachment-list">
                        {attachments.map((a) => (
                            <div className="attachment-item" key={a.id}>
                                <h5>{a.fileMeta.name}</h5>
                                {!(
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                ) && (
                                        <div>
                                            <PlusIconSvg onClick={() => removeAttachment(a.id)} />
                                        </div>
                                    )}
                            </div>
                        ))}
                        {fileName && (
                            <div className="attachment-item">
                                <h5>{fileName}</h5>
                                <Loader
                                    type="Oval"
                                    color={CONSTANTS.BRAND_COLOR}
                                    height={14}
                                    width={14}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const EditAttachments: React.FC = () => {
    const {
        attachmentConfig,
        attachments,
        fetchAttachments,
        isPRB,
        isTravelAuthorizationForm,
        error: {
            STEP2: { errorFields },
        },
        latestrelationArr,
    } = useDocumentContext();
    return (
        <div className="edit-attachments">
            {attachmentConfig.length > 0 &&
                attachmentConfig.map((attachment, index) => (
                    <Attachment
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        attachmentConfig={attachment}
                        index={index}
                        errorFields={errorFields}
                        fetchAttachments={fetchAttachments}
                        attachments={attachments.filter(
                            (a) => a.fileMeta.attachmentType === attachment.attachmentType,
                        )}
                        latestrelationArr={latestrelationArr}
                    />
                ))}
            {!(attachmentConfig[0].allowDragDrop && !attachmentConfig[0].allowFileTypeAttachment) && (<span className="note">{CREATE_DOCUMENTS.NOTE}{attachmentConfig[0]?.allowedFileTypes.join(",").toLowerCase()}</span>)}
            {!(attachmentConfig[0].allowDragDrop && !attachmentConfig[0].allowFileTypeAttachment) && (<span className='note'>{CREATE_DOCUMENTS.NOTE_END}</span>)}
            {isPRB && (
                <span className='note'>{CREATE_DOCUMENTS.PRB_FORM}</span>
            )}
            {isTravelAuthorizationForm && (
                <span className='note'>* Optional: please upload any supporting documents for this travel.</span>
            )}
        </div>
    );
};

export default EditAttachments;
