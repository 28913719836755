import qs from 'qs';
import React, { useState } from 'react';
import { getDocumentList } from '../../API/ownerChange';
import CONSTANTS from '../../constants';
import { IOwnerChangeAdminDocuments, IPaginationData } from '../../interfaces';
import DocumentAdminTable from './DocumentAdminTable';

// eslint-disable-next-line max-lines-per-function
const ListAllDocuments: React.FC = () => {
    const defaultDocuments = () => {
        const Documents: IOwnerChangeAdminDocuments[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            Documents.push({
                documentId: CONSTANTS.LOADING.NUMBER,
                docNumber: CONSTANTS.LOADING.TEXT,
                documentType: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                owner: CONSTANTS.LOADING.TEXT,
                status: 'NEW',
                updatedAt: CONSTANTS.LOADING.DATE,
            });
        }

        return Documents;
    };
    const [data, setData] = useState(defaultDocuments);
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 5,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const update = async (pageIndex?: number, pageSize?: number) => {
        const documentNumber: string | undefined = undefined;
        const OwnerIds: number | undefined = undefined;
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const params = {
            ownerId: OwnerIds,
            documentNumber,
            limit: size,
            offset: index * size,
        };
        const documents = await getDocumentList(qs.stringify(params));
        if (documents?.apiStatus === 'SUCCESS') {
            setData(documents.DocumentList);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: documents.totalDocuments,
            });
        }
    };

    return (
        <DocumentAdminTable
            data={data}
            pagination={pagination}
            fetchDocuments={(pageIndex: number, pageSize: number) => update(pageIndex, pageSize)}
        />
    );
};
export default ListAllDocuments;
