/* eslint-disable import/prefer-default-export */
import { getRequest, postRequest } from '../utils/api-request';
import { IntroJsMasterTable } from '../interfaces/introjs-mastertable';
import { IAPIResponseStatus } from '../interfaces';

const getUserTourDetails = async (
    tourId: number,
): Promise<{ apiStatus: IAPIResponseStatus; response: Response } | undefined> => {
    try {
        const res = await getRequest(`introjs-user-details/fetch?tourId=${tourId}`);
        return res;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const createUserTourDetails = async (
    tourId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await postRequest(`introjs-user-details/create?tourId=${tourId}`, {});
        if (res?.apiStatus) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getInrojsMasterTableDetails = async (
    tourId: number,
): Promise<{ apiStatus: IAPIResponseStatus; masterTableInfo: IntroJsMasterTable } | undefined> => {
    try {
        const res = await getRequest(
            `introjs-mastertable-details/getmaster?masterTourdId=${tourId}`,
        );
        if (res) {
            return { apiStatus: 'SUCCESS', masterTableInfo: res.response };
        }
        return res;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export { getUserTourDetails, createUserTourDetails, getInrojsMasterTableDetails };
