import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import qs from 'qs';

import './DashboardTable.scss';
import { toast } from 'react-toastify';
import { SortIcon } from '../../assets/images';
import { NoDocumentsFound } from '../../components/General';
import CONSTANTS from '../../constants';
import { MY_FAVORITE_COLUMN } from './TableColumns';
import Pagination from '../../components/pagination/Pagination';
import Filters from '../../components/filters/Filters';
import { myFavoriteConfig, myFavoritesDefault } from '../../components/filters/filter-config';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IMyFavorites } from '../../interfaces/dashboard';
import { getMyFavorites } from '../../API/dashboard';
import STATIC_CONTENT from '../../constants/StaticContent';

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

const DEFAULT_SORT: ISort = {
    id: 'createdDate',
    desc: true,
};

const columnMapping: IStringObject = {
    docNumber: 'documentNumber',
    createdDate: 'createdAt',
    authorName: 'ownerId',
    files: 'attachmentCount',
    status: 'state',
    title: 'title',
};

const defaultMyFavorites = () => {
    const myFavorites: IMyFavorites[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 9; i++) {
        myFavorites.push({
            docId: CONSTANTS.LOADING.NUMBER,
            docNumber: CONSTANTS.LOADING.TEXT,
            files: CONSTANTS.LOADING.NUMBER,
            title: CONSTANTS.LOADING.TEXT,
            createdDate: CONSTANTS.LOADING.DATE,
            status: 'NEW',
            authorId: CONSTANTS.LOADING.NUMBER,
            authorName: CONSTANTS.LOADING.TEXT,
            isConfidential:false
        });
    }

    return myFavorites;
};

// eslint-disable-next-line max-lines-per-function
const MyFavoritesTab: React.FC = () => {
    const columns = useMemo(() => MY_FAVORITE_COLUMN, []);
    const [filters, setFilters] = useState<IStringObject>({});
    const [myFavorites, setMyFavorites] = useState(defaultMyFavorites());
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setSortBy,
        gotoPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IMyFavorites>(
        {
            columns,
            data: myFavorites,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
            updateResults: () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                fetchFavorites();
            },
        },
        useSortBy,
        usePagination,
    );

    const fetchFavorites = async () => {
        const queryParams = {
            ...myFavoritesDefault,
            ...filters,
            limit: pageSize.toString(),
            offset: (pageIndex * pageSize).toString(),
            orderBy: columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'createdAt',
            sortOrder: sortBy[0].desc ? 'DESC' : 'ASC',
        };

        const response = await getMyFavorites(qs.stringify(queryParams));
        if (response?.apiStatus === 'SUCCESS') {
            setMyFavorites(response.myFavorites);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: response.totalDocuments,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    const resetFilters = () => {
        setFilters(myFavoritesDefault);
        gotoPage(DEFAULT_PAGINATION_DATA.pageIndex);
        setPageSize(DEFAULT_PAGINATION_DATA.pageSize);
        setSortBy([DEFAULT_SORT]);
    };

    const filterSearch = (queryParam: IStringObject) => {
        setFilters(queryParam);
        gotoPage(DEFAULT_PAGINATION_DATA.pageIndex);
        setPageSize(DEFAULT_PAGINATION_DATA.pageSize);
        setSortBy([DEFAULT_SORT]);
    };

    useEffect(() => {
        fetchFavorites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy, filters]);

    return (
        <div className="my-favorite-tab">
            <div className="filters-section">
                <Filters
                    filtersConfig={myFavoriteConfig}
                    filterSearch={filterSearch}
                    resetFilters={resetFilters}
                    documentCount={pagination.totalCount}
                    loading={false}
                />
            </div>
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                                />
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && (
                    <NoDocumentsFound heading={STATIC_CONTENT.DASHBOARD.NO_DOCUMENT.MY_FAVORITES} />
                )}
            </div>
        </div>
    );
};

export default MyFavoritesTab;
