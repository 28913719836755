import React from 'react'
import Toggle from '../../components/toggle/Toggle';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import './DocTypeNumbering.scss';

// eslint-disable-next-line max-lines-per-function
const DocTypeNumbering: React.FC = () => {
    const { docNumbering, docTypeErrors, docNumberDispatch, docTypeInfo } = useDocTypeContext();
    const isEmpInitialRequired =
        docNumbering.filter((e) => e.value === 'strategyUserInitials').length > 0;
    const [staticField] = docNumbering.filter((e) => e.type === 'static');
    return (
        <div className="doc-type-numbering">
            {docNumbering.length > 0 && staticField && (
                <>
                    <div className="input-fields">
                        <div>
                            <label htmlFor="doc-code">4 Letter Doc Code</label>
                            <input
                                className={`${docTypeErrors.code ? 'error' : ''}`}
                                type="text"
                                name="doc-code"
                                id="doc-code"
                                disabled={
                                    docTypeInfo.status === 'PUBLISHED' ||
                                    !['1.0', ''].includes(docTypeInfo.version)
                                }
                                value={staticField.value}
                                autoComplete="off"
                                onChange={(e) => {
                                    if (e.target.value.length <= 4)
                                        docNumberDispatch({
                                            type: 'static',
                                            value: e.target.value.toUpperCase(),
                                        });
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="employee-initial">Employee Initials</label>
                            <Toggle
                                isChecked={isEmpInitialRequired}
                                isDisabled={
                                    docTypeInfo.status === 'PUBLISHED' ||
                                    !['1.0', ''].includes(docTypeInfo.version)
                                }
                                onclick={() =>
                                    docNumberDispatch({
                                        type: 'strategyUserInitials',
                                        push: !isEmpInitialRequired,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="code">
                        Example:{'   '}
                        <code className="example">{`${
                            staticField.value ? `${staticField.value}-` : ''
                        }${isEmpInitialRequired ? 'JSmith-' : ''}0001`}</code>
                    </div>
                </>
            )}
        </div>
    );
};
export default DocTypeNumbering
