/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { PlusIconSvg } from '../../assets/images';
import { IButtonStatus } from '../../interfaces';
import { useDocumentContext } from '../../contexts/document-provider';
import { IDocumentActionTypes } from '../../interfaces/document';
import { formatDate } from '../../utils/date';
import './RepublishModal.scss';
import { documentAction } from '../../API/document';
import STATIC_CONTENT from '../../constants/StaticContent';

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    documentId: number;
}

const { DOCUMENT_ACTIONS } = STATIC_CONTENT;

const RepublishModal: React.FC<Props> = ({ isModalOpen, documentId, onModalClose }) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const { docInfo, setDocInfo, isSunsetEnabled, publishOnceApproved, docTypeInfo, isEndDateAsked } = useDocumentContext();
    const [sunsetPeriodError, setSunsetPeriodError] = useState<boolean>(false);
    const [effectivePeriodError, setEffectivePeriodError] = useState<boolean>(false);
    const { fetchDocument } = useDocumentContext();

    useEffect(() => {
        if (sunsetPeriodError) {
            setSunsetPeriodError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.sunsetPeriod]);

    useEffect(() => {
        if (effectivePeriodError) {
            setEffectivePeriodError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.effectivePeriod]);

    const effectiveFromDate = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    // className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveFrom ? 'Today' : formatDate(docInfo.effectiveFrom)}
                    minDate={new Date()}
                    onChange={(date: Date) => {
                        setDocInfo({ ...docInfo, effectiveFrom: date });
                    }}
                />
            </div>
        </div>
    );

    const effectiveFromOnceApproved = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>
            <div>
                <input
                    type="text"
                    placeholder="Once Approved"
                    className="effectivePeriodError"
                    disabled
                />
            </div>
        </div>
    );

    const effectivePeriod = (
        <div>
            <label htmlFor="sunset-period">Effective Period (in weeks)</label>
            <div>
                <input
                    type="number"
                    className={`${effectivePeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.effectivePeriod}
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            effectivePeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );

    const effectiveTillDate = (
        <div>
            <label htmlFor="effective_from">Effective Till Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    // className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveTill ? 'Today' : formatDate(docInfo.effectiveTill)}
                    minDate={new Date()}
                    onChange={(date: Date) => {
                        setDocInfo({ ...docInfo, effectiveTill: date });
                    }}
                />
            </div>
        </div>
    );

    const sunsetPeriod = (
        <div>
            <label htmlFor="sunset-period">Sunset Period (in weeks)</label>
            <div>
                <input
                    type="number"
                    className={`${sunsetPeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.sunsetPeriod}
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            sunsetPeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );
    const validate = (): boolean => {
        let isValid = true;
        if (!docInfo.effectivePeriod && isSunsetEnabled) {
            setEffectivePeriodError(true);
            isValid = false;
        }
        if (!docInfo.sunsetPeriod && isSunsetEnabled) {
            setSunsetPeriodError(true);
            isValid = false;
        }
        if (!isEndDateAsked && docInfo.sunsetPeriod > docInfo.effectivePeriod) {
            setSunsetPeriodError(true);
            isValid = false;
        }
        if(isEndDateAsked)
        {
            const effectiveFromTime = Math.floor( new Date(docInfo.effectiveFrom).getTime()/1000 );
            const effectiveTillTime = Math.floor( new Date(docInfo.effectiveTill).getTime()/1000 );
            if(docInfo.effectiveTill < docInfo.effectiveFrom)
            {
                setEffectivePeriodError(true);
                isValid = false;
            }
            else if(effectiveTillTime-effectiveFromTime < docInfo.sunsetPeriod * 604800)
            {
                setSunsetPeriodError(true);
                isValid = false;
            }
        }
        return isValid;
    };

    const onClick = async (type: IDocumentActionTypes) => {
        const isValid = validate();
        if (isValid) {
            setButtonStatus('LOADING');
            const res = await documentAction(documentId, type, docTypeInfo, undefined, undefined, {
                effectiveFromDate: docInfo.effectiveFrom,
                endDate: isEndDateAsked,
                effectiveTillDate: docInfo.effectiveTill,
                effectivePeriod: docInfo.effectivePeriod,
                sunsetPeriod: docInfo.sunsetPeriod,
            });
            if (res?.apiStatus === 'SUCCESS') {
                toast.success('Republished Succesfully');

                await fetchDocument();
            } else {
                toast.error('Something went wrong');
            }
            setButtonStatus('DEFAULT');
            onModalClose(false);
        }
    };

    return (
        <div className="action-modal">
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="action__modal"
            >
                <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                <div className="republish-modal">
                    <h4>Republish Document</h4>
                    {isSunsetEnabled && publishOnceApproved && docInfo.title && !isEndDateAsked && (
                        <>
                            {effectiveFromOnceApproved} {effectivePeriod} {sunsetPeriod}
                        </>
                    )}
                    {isSunsetEnabled && !publishOnceApproved && docInfo.title && !isEndDateAsked && (
                        <>
                            {effectiveFromDate}
                            {effectivePeriod}
                            {sunsetPeriod}
                        </>
                    )}
                    {isSunsetEnabled && publishOnceApproved && docInfo.title && isEndDateAsked && (
                        <>
                            {effectiveFromOnceApproved}{effectiveTillDate}{sunsetPeriod}
                        </>
                    )}
                    {isSunsetEnabled && !publishOnceApproved && docInfo.title && isEndDateAsked && (
                        <>
                            {effectiveFromDate}
                            {effectiveTillDate}
                            {sunsetPeriod}
                        </>
                    )}
                    {!isSunsetEnabled && !publishOnceApproved && docInfo.title && effectiveFromDate}
                    {}
                    <button
                        type="button"
                        disabled={buttonStatus === 'LOADING'}
                        onClick={() => onClick('REPUBLISH')}
                    >
                        {DOCUMENT_ACTIONS.BUTTON.REPUBLISH}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default RepublishModal;
