import React from 'react'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IField } from '../../../interfaces/document'
import './DatePicker.scss';
import { IDocumentAction } from '../../../reducers/document-reducer'

interface Props {
    field: IField;
    isDisabled: boolean;
    value: Date;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const DatePicker: React.FC<Props> = ({ documentDispatch, field, isDisabled, value, error }) => (
    <div className="react-datepicker">
        <ReactDatePicker
            className={`${error ? 'error' : ''}`}
            selected={value}
            disabled={isDisabled}
            onChange={(date: Date) =>
                documentDispatch &&
                documentDispatch({ type: 'date-picker', inputName: field.name, value: date })
            }
        />
    </div>
);

export default DatePicker
