/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import { getDataTypeUiMapping } from '../../API/data-types';
import { createField } from '../../API/fields';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import { IFieldDataNew } from '../../interfaces/field';
import { AddButton } from '../General';

import './DocTypeDocumentDetail.scss';
import ReactDND from '../ReactDND';
import DocTypeField from '../../widgets/doc-type-field/DocTypeField';
import { IDataTypeMapping } from '../../interfaces/document';
import STATIC_CONTENT from '../../constants/StaticContent';

const { DOCTYPE_DOCUMENT_DETAIL } = STATIC_CONTENT;

const DocTypeDocumentDetail: React.FC = () => {
    const {
        fields,
        docTypeDispatch,
        docTypeInfo,
        docTypeVersionError: {
            DOCUMENT_DETAILS: { cards },
        },
    } = useDocTypeContext();
    const [dataTypeList, setDataTypeList] = useState<IDataTypeMapping[]>([]);

    const initialFetch = async () => {
        const dataTypesRes = await getDataTypeUiMapping();
        if (dataTypesRes?.apiStatus === 'SUCCESS') {
            setDataTypeList(dataTypesRes.mapping);
        }
    };

    useEffect(() => {
        initialFetch();
    }, []);

    const moveCard = (dragIndex: number, hoverIndex: number) => {
        const dragCard = fields[dragIndex];
        docTypeDispatch({
            type: 'update',
            value: update(fields, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }),
        });
    };

    const onNewField = async () => {
        const fieldData: IFieldDataNew = { name: 'test', dataTypeId: 1, uiControlId: 1 };
        const response = await createField(fieldData);
        if (response) {
            docTypeDispatch({ type: 'new', value: response.fieldId });
        }
    };

    return (
        <div className="doc-type-document-details">
            {/* <DndProvider backend={HTML5Backend}> */}
                {fields.map((item, index) => (
                    <ReactDND key={item.id} index={index} id={item.id} moveCard={moveCard}>
                        <DocTypeField
                            errors={
                                cards[index] ? cards[index] : { cardError: false, fieldList: [] }
                            }
                            isDisabled={docTypeInfo.status !== 'DRAFT'}
                            fieldData={item}
                            index={index}
                            dataTypeMapping={dataTypeList}
                        />
                    </ReactDND>
                ))}
            {/* </DndProvider> */}
            {docTypeInfo.status === 'DRAFT' && (
                <div className="new-button">
                    <button type="button" onClick={() => onNewField()}>
                        <AddButton />
                        {DOCTYPE_DOCUMENT_DETAIL.BUTTON.ADD_NEW_FIELD}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DocTypeDocumentDetail;
