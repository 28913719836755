import React from 'react';

import './Markup.scss';
import { IField } from '../../../interfaces/document';

interface Props {
    field: IField;
}

const Markup: React.FC<Props> = ({ field }: Props) => (
    <div className="markup-field" dangerouslySetInnerHTML={{__html: field.markupText}} />
);

export default Markup;
