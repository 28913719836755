import React, { useState } from 'react';
import Modal from 'react-modal';
// import { toast } from 'react-toastify';
// import CONSTANTS from '../../constants';
// import STATIC_CONTENT from '../../constants/StaticContent';
import { ArrowIcon, DocumentIconSvg, PlusIconSvg } from '../../assets/images';
import CreateDocumentPage from '../../pages/create-document/CreateDocument';

// interface Props {
//     isModalOpen: boolean;
//     onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
// }

// const { ACTION_MODAL, DOC_COMMENTS } = STATIC_CONTENT;

const CreateDocinDcoModal:React.FC = () => (
        <div>
            <Modal 
                isOpen
                contentLabel="Example Modal"
            >
            <PlusIconSvg className="close-btn" onClick={() => window.history.back()} />
              <CreateDocumentPage/>
            </Modal>
        </div>
    );

export default CreateDocinDcoModal;