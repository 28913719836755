const getMarkdown = async (name: string): Promise<string | undefined> => {
    try {
        const markdownObj = await fetch(`${process.env.PUBLIC_URL}/${name}.md`);
        const markdown = await markdownObj.text();

        return markdown;
    } catch (err) {
        return undefined;
    }
};

export default getMarkdown;