/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react'

import './Text.scss'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const Text: React.FC<Props> = ({ field, isDisabled, documentDispatch, value, error }: Props) => {
    const maxLimit = field.maxLength;
    const [CharCount, setCount] = useState(maxLimit);
    return (
        <div>
            <input
                type="text"
                className={`input-text ${error ? 'error' : ''}`}
                name={field.name}
                disabled={isDisabled}
                value={value}
                onChange={(e) => {
                    setCount(maxLimit - e.target.value.length);
                    documentDispatch &&
                        documentDispatch({ type: 'text', inputName: field.name, value: e.target.value });
                }}
                autoComplete="off"
                maxLength={maxLimit !== 0 ? maxLimit : undefined}
            />{maxLimit !== 0 && <span style={{ 'fontSize': 10 }}>* remaining characters {CharCount}</span>}
        </div>
    );
};

export default Text
