import { IDocTypeNumbering } from '../interfaces/document-type';

export const initialDocNumberingData: IDocTypeNumbering[] = [
    {
        type: 'static',
        value: '',
    },
    {
        type: 'dynamic',
        value: 'strategyUserInitials',
    },
];

export type IDocNumberingAction =
    | { type: 'static'; value: string }
    | { type: 'strategyUserInitials'; push: boolean }
    | { type: 'update'; value: IDocTypeNumbering[] };

export const docNumberingReducer = (
    state: IDocTypeNumbering[],
    action: IDocNumberingAction,
): IDocTypeNumbering[] => {
    switch (action.type) {
        case 'update': {
            const tempState: IDocTypeNumbering[] = [];
            return tempState.concat(action.value);
        }
        case 'static': {
            const index = state.findIndex((e) => e.type === 'static');
            return [
                ...state.slice(0, index),
                { ...state[index], value: action.value },
                ...state.slice(index + 1),
            ];
        }
        case 'strategyUserInitials': {
            if (action.push) {
                return [
                    ...state,
                    {
                        type: 'dynamic',
                        value: 'strategyUserInitials',
                    },
                ];
            }
            const index = state.findIndex((e) => e.value === 'strategyUserInitials');
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }
        default:
            return state;
    }
};
