/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { getMonth, getYear } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { ArrowIcon } from '../../../assets/images';

interface Props {
    name: string;
    displayName: string;
    fromDate: Date | null;
    toDate: Date | null;
    updateFilters: (name: string, value: any, type: string) => void;
    customselected?: boolean;
}

const getYearsForDropdown = (date = new Date()): number[] => {
    const year = date.getFullYear();
    const yearArray = [];
    let i = 0;
    do {
        yearArray.push(year - i);
        i += 1;
    } while (i < 5);

    return yearArray;
};

const DoubleDateFilter: React.FC<Props> = ({
    displayName,
    name,
    fromDate,
    toDate,
    updateFilters,
    customselected,
}) => {
    const years = getYearsForDropdown();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const onFromChange = (date: Date) => {
        updateFilters(`${name}Start`, date, 'DOUBLE_DATE');
    };

    const onToChange = (date: Date) => {
        updateFilters(`${name}End`, date, 'DOUBLE_DATE');
    };

    return (
        <div className="double-date-filter">
           {!customselected && <label htmlFor={name}>{displayName}</label>}
            { !customselected && (<div className="dates">
                <ReactDatePicker
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ArrowIcon
                                onClick={decreaseMonth}
                                className={`left-arrow ${
                                    prevMonthButtonDisabled ? 'disabled' : ''
                                }`}
                            />
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                    changeYear(value as unknown as number)
                                }
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <ArrowIcon
                                onClick={increaseMonth}
                                className={`right-arrow ${
                                    nextMonthButtonDisabled ? 'disabled' : ''
                                }`}
                            />
                        </div>
                    )}
                    className={name}
                    id={name}
                    selected={fromDate}
                    onChange={(date) => onFromChange(date as Date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="From"
                    maxDate={new Date()}
                    autoComplete="off"
                    disabled={customselected}
                />
                <span className="divider" />
                <ReactDatePicker
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ArrowIcon
                                onClick={decreaseMonth}
                                className={`left-arrow ${
                                    prevMonthButtonDisabled ? 'disabled' : ''
                                }`}
                            />
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                    changeYear(value as unknown as number)
                                }
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <ArrowIcon
                                onClick={increaseMonth}
                                className={`right-arrow ${
                                    nextMonthButtonDisabled ? 'disabled' : ''
                                }`}
                            />
                        </div>
                    )}
                    className={name}
                    id={name}
                    selected={toDate}
                    onChange={(date) => onToChange(date as Date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="To"
                    maxDate={new Date()}
                    autoComplete="off"
                    disabled={customselected}
                />
            </div>)}
        </div>
    );
};

export default DoubleDateFilter;
