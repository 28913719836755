import React, { createContext, useContext, useState, useRef } from 'react';

interface IEventsHeightProvider {
    heightRef: React.RefObject<HTMLDivElement> | null;
    height: number;
    updateHeight: () => void;
}

const EventsHeightContext = createContext<IEventsHeightProvider>({
    heightRef: null,
    height: 100,
    updateHeight: () => {},
});

const EventsHeightProvider: React.FC = (props: unknown) => {
    const heightRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const updateHeight = () => {
        if (heightRef.current) {
            if (heightRef.current.getBoundingClientRect().height !== height)
                setHeight(heightRef.current.getBoundingClientRect().height);
        }
    };

    return <EventsHeightContext.Provider value={{ height, heightRef, updateHeight }} {...props} />;
};

export const useEventsHeight = (): IEventsHeightProvider => useContext(EventsHeightContext);

export default EventsHeightProvider;
