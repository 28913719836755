import React from 'react';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import ReactTagsInput from '../../react-tags-input/ReactTagsInput';

import './TextBoxWithChip.scss';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const TextBoxWithChip: React.FC<Props> = ({
    field,
    value,
    documentDispatch,
    error,
    isDisabled,
}) => (
    <div className={`text-box-with-chip ${error ? 'error' : ''} ${isDisabled ? 'disabled' : ''}`}>
        {/* <TagsInput
            value={value}
            name={field.name}
            onChange={(tags) =>
                documentDispatch &&
                documentDispatch({
                    type: 'text-box-with-chip',
                    inputName: field.name,
                    value: tags,
                })
            }
        /> */}
        <ReactTagsInput
            tags={value}
            onChange={(tags) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                documentDispatch &&
                    documentDispatch({
                        type: 'text-box-with-chip',
                        inputName: field.name,
                        value: tags,
                    });
            }}
            disabled={isDisabled}
        />
    </div>
);

export default TextBoxWithChip;
