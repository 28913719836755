import React, { useEffect, useState } from 'react';

import { getRequest } from '../../utils/api-request';
import CONSTANTS from '../../constants';
import { SkeletonNumber } from '../Skeleton';

// eslint-disable-next-line max-lines-per-function
const DocumentStatusCount: React.FC = () => {
    const stateCount: { [key: string]: number } = {
        DRAFT: CONSTANTS.LOADING.NUMBER,
        UNDER_REVIEW: CONSTANTS.LOADING.NUMBER,
        REJECTED: CONSTANTS.LOADING.NUMBER,
        PUBLISHED: CONSTANTS.LOADING.NUMBER,
    };
    const [data, setData] = useState(stateCount);
    const [count, setCount] = useState(CONSTANTS.LOADING.NUMBER);

    const fetchData = async () => {
        try {
            const res = await getRequest(`documents/get-state-count`);
            if (res && res?.apiStatus === 'SUCCESS') {
                res.response.states.forEach((item: { state: string; state_count: number }) => {
                    if (item.state in stateCount) {
                        stateCount[item.state] = item.state_count;
                    }
                });
                setData(stateCount);
                setCount(res.response.totalCount);
            } else console.log('Fetching data failed with status', res?.apiStatus);
        } catch (error) {
            console.log('Error fetching data', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <div className="statusEach">
                <h3>TOTAL COUNT:</h3>
                <p>
                    <SkeletonNumber number={count} width={75} />
                </p>
            </div>
            {Object.entries(data).map(([key, value]) => (
                <div className="statusEach">
                    <h3>{`${key} DOCUMENTS:`}</h3>
                    <p>
                        <SkeletonNumber number={value} width={75} />
                    </p>
                </div>
            ))}
        </>
    );
};
export default DocumentStatusCount;
