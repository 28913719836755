/* eslint-disable max-lines-per-function */
import React, { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import './NotificationsSetting.scss';
import {
    getNotificationFilters,
    setNotificationFilters,
    getNotificationFilterable,
} from '../../API/notifications';
import { INotificationFilterable, INotificationGroup } from '../../interfaces/settings';
import {
    INotificationGroupAction,
    notificationGroupReducer,
} from '../../reducers/notification-setting-reducer';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';

interface INotificationModalProps {
    modalOpen: boolean;
    selectedEventsList: string[];
    dispatcher: React.Dispatch<INotificationGroupAction>;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedEvents: React.Dispatch<React.SetStateAction<string[]>>;
}
const { NOTIFICATIONS } = STATIC_CONTENT;

const NotificationEventModal: React.FC<INotificationModalProps> = ({
    modalOpen,
    selectedEventsList,
    onModalClose,
    dispatcher,
    setSelectedEvents,
}) => {
    const [events, setEvents] = useState<INotificationFilterable[]>([]);
    const [selectedList, setSelectedList] = useState<string[]>(selectedEventsList);

    const fetchNotificationsFilterable = async () => {
        const res = await getNotificationFilterable();
        if (res?.apiStatus === 'SUCCESS') {
            setEvents(res.notificationFilterables);
        }
    };

    const onChange = (id: string) => {
        if (selectedList.includes(id)) {
            setSelectedList(selectedList.filter((s) => s !== id));
        } else {
            setSelectedList([...selectedList, id]);
        }
    };

    const Save = () => {
        let filterGroup: INotificationGroup[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const selected of selectedList) {
            if (!selectedEventsList.includes(selected)) {
                const [filter] = events.filter((a) => a.event === selected);
                filterGroup = [...filterGroup, ...filter.notificationGroup];
            }
        }
        setSelectedEvents(selectedList);
        const deleted = selectedEventsList.filter((s) => !selectedList.includes(s));
        dispatcher({ type: 'APPEND', value: filterGroup, events: deleted });
        onModalClose(false);
    };

    useEffect(() => {
        fetchNotificationsFilterable();
    }, []);

    return (
        <Modal
            isOpen={modalOpen}
            contentLabel="submit Modal"
            className="notification-setting__modal"
            onRequestClose={() => onModalClose(false)}
        >
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div className="modal-body">
                <div className="heading">
                    <h1>{NOTIFICATIONS.LABEL.NOTIFICATION_TYPE}</h1>
                </div>
                <ul>
                    {events.length > 0 &&
                        events.map((event) => (
                            <li key={event.event}>
                                <input
                                    type="checkbox"
                                    checked={selectedList.includes(event.event)}
                                    onChange={() => onChange(event.event)}
                                />{' '}
                                {event.eventDescription}
                            </li>
                        ))}
                </ul>
                <div className="button-group">
                    <button type="button" onClick={() => onModalClose(false)}>
                        {NOTIFICATIONS.BUTTON.CANCEL}
                    </button>
                    <button type="button" className="primary-btn" onClick={() => Save()}>
                        {NOTIFICATIONS.BUTTON.DONE}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

interface INotificationRowProps {
    index: number;
    notification: INotificationGroup;
    dispatcher: React.Dispatch<INotificationGroupAction>;
}

// eslint-disable-next-line max-lines-per-function
const NotificationRow: React.FC<INotificationRowProps> = ({ index, notification, dispatcher }) => {
    const [mailConfig] = notification.notifications.filter((n) => n.type === 'Mail');
    const [inAppConfig] = notification.notifications.filter((n) => n.type === 'InApp');
    return (
        <tr key={index}>
            <td>{notification.description}</td>
            {mailConfig ? (
                <td>
                    {' '}
                    <input
                        name={`${index}-mail`}
                        checked={mailConfig.value}
                        type="checkbox"
                        onClick={() => {
                            dispatcher({
                                type: 'UPDATE_MAIL',
                                id: index,
                                value: !mailConfig.value,
                            });
                        }}
                    />
                </td>
            ) : (
                <td />
            )}
            {inAppConfig ? (
                <td>
                    {' '}
                    <input
                        name={`${index}-mail`}
                        checked={inAppConfig.value}
                        type="checkbox"
                        onChange={() => {
                            dispatcher({
                                type: 'UPDATE_IN_APP',
                                id: index,
                                value: !inAppConfig.value,
                            });
                        }}
                    />
                </td>
            ) : (
                <td />
            )}
        </tr>
    );
};

// eslint-disable-next-line max-lines-per-function
const NotificationsSetting: React.FC = () => {
    const [changed, setChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
    const [notificationGroup, notificationGroupDispatch] = useReducer(notificationGroupReducer, []);

    const fetchNotifications = async () => {
        const res = await getNotificationFilters();
        if (res?.apiStatus === 'SUCCESS') {
            notificationGroupDispatch({ type: 'FRESH', value: res.notificationFilters });
            setSelectedEvents(res.selectedEvents);
        }
        setChanged(false);
    };

    const saveChanges = async () => {
        const res = await setNotificationFilters(notificationGroup);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success('Saved Successfully');
            fetchNotifications();
        } else {
            toast.error('Unable to save changes');
        }
    };

    useEffect(() => {
        setChanged(true);
    }, [notificationGroup]);

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <div className="notification-setting">
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>{NOTIFICATIONS.LABEL.EVENT_TRIGGER}</th>
                            <th>{NOTIFICATIONS.LABEL.MAIL_NOTIFICATION}</th>
                            <th>{NOTIFICATIONS.LABEL.ALERT_NOTIFICATION}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificationGroup.length > 0 &&
                            notificationGroup.map((notification, index) => (
                                <NotificationRow
                                    // eslint-disable-next-line react/no-array-index-key
                                    index={index}
                                    notification={notification}
                                    dispatcher={notificationGroupDispatch}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
            <div className="buttons-group">
                <button type="button" onClick={() => setShowModal(true)} className="primary-btn">
                    {NOTIFICATIONS.BUTTON.ADD_FILTERS}
                </button>
                <button
                    type="button"
                    disabled={!changed}
                    className="primary-btn"
                    onClick={() => fetchNotifications()}
                >
                    {NOTIFICATIONS.BUTTON.DISCARD}
                </button>
                <button
                    type="button"
                    disabled={!changed}
                    className="primary-btn"
                    onClick={() => saveChanges()}
                >
                    {NOTIFICATIONS.BUTTON.SAVE}
                </button>
            </div>
            {showModal && (
                <NotificationEventModal
                    modalOpen={showModal}
                    selectedEventsList={selectedEvents}
                    onModalClose={setShowModal}
                    dispatcher={notificationGroupDispatch}
                    setSelectedEvents={setSelectedEvents}
                />
            )}
        </div>
    );
};

export default NotificationsSetting;
