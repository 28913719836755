/* eslint-disable no-restricted-syntax */
import { INotificationGroup } from '../interfaces/settings';

export type INotificationGroupAction =
    | { type: 'FRESH'; value: INotificationGroup[] }
    | { type: 'APPEND'; value: INotificationGroup[]; events: string[] }
    | { type: 'UPDATE_MAIL'; id: number; value: boolean }
    | { type: 'UPDATE_IN_APP'; id: number; value: boolean }
    | { type: 'DELETE'; events: string[] };

// eslint-disable-next-line max-lines-per-function
export const notificationGroupReducer = (
    state: INotificationGroup[],
    action: INotificationGroupAction,
): INotificationGroup[] => {
    switch (action.type) {
        case 'FRESH':
            return action.value;
        case 'APPEND': {
            const updatedState = state.filter((s) => !action.events.includes(s.event));
            return [...updatedState, ...action.value];
        }
        case 'UPDATE_MAIL': {
            const notificationGroup = state[action.id];
            for (const a of notificationGroup.notifications) {
                if (a.type === 'Mail') {
                    a.value = action.value;
                }
            }
            return [
                ...state.slice(0, action.id),
                { ...notificationGroup },
                ...state.slice(action.id + 1),
            ];
        }
        case 'UPDATE_IN_APP': {
            const notificationGroup = state[action.id];
            for (const a of notificationGroup.notifications) {
                if (a.type === 'InApp') {
                    a.value = action.value;
                }
            }
            return [
                ...state.slice(0, action.id),
                { ...notificationGroup },
                ...state.slice(action.id + 1),
            ];
        }
        case 'DELETE': {
            const updatedState = state.filter((s) => !action.events.includes(s.event));
            return updatedState;
        }
        default:
            return state;
    }
};
