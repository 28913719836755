/* eslint-disable max-lines-per-function */
import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import { IDocTypeCard } from '../../interfaces/document-type';
import './DocTypeView.scss';
import CONSTANTS from '../../constants';

interface Props {
    docTypeData: IDocTypeCard[];
}

const DocTypeGridView: React.FC<Props> = ({ docTypeData }) => (
    <div className="grid-view">
        {docTypeData.map((docType) => (
            <a
                href={`${CONSTANTS.RELATIVE_PATHS.documentTypeCreate.WPUrl}?doc_type_id=${docType.id}&doc_type_v_id=${docType.activeVersion}`}
                key={docType.id}
            >
                <div className="doc-type-card">
                    <div className="content-section">
                        <div className="content">
                            <h1>{docType.name}</h1>
                            <span>Version {docType.version}</span>
                            <div className={`status-pill ${docType.status.toLocaleLowerCase()}`}>
                                {docType.status}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <h5>
                            Last modified {formatDistanceToNowStrict(docType.lastUpdateDate)} ago by{' '}
                            {docType.updatedBy}
                        </h5>
                    </div>
                </div>
            </a>
        ))}
    </div>
);

export default DocTypeGridView;
