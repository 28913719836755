import React, { useEffect, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import DashboardProvider, { useDashboardContext } from '../../contexts/dashboard-provider';
import DashboardTable from '../../widgets/dashboard-table/DashboardTable';
import './Dashboard.scss';
import STATIC_CONTENT from '../../constants/StaticContent';
import CONSTANTS from '../../constants';
import InitialFeaturesTour from '../../components/introJs/InitialFeaturesTour';
import { DocumentIconSvg, MyApprovals, MyDocuments, MyFavourites } from '../../assets/images';

// eslint-disable-next-line max-lines-per-function
const DashboardTabs: React.FC = () => {
    const {
        activeTab,
        setActiveTab,
        documentsCount: {
            myReviews,
            myFavorites,
            myDocuments,
            myApprovals,
            latestDocuments,
            myGroupApprovals,
        },
        shouldGroupApprovalsRender,
    } = useDashboardContext();
    const { DASHBOARD } = STATIC_CONTENT;
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const [shouldGroupsRender, setShouldGroupsRender] = useState(true);
    
    useEffect(() => {
        // setShouldGroupsRender(true);
        async function fetchShouldGroupRender() {
            const res = await shouldGroupApprovalsRender();
            setShouldGroupsRender(res);
        }
        fetchShouldGroupRender();
    }, []);
    return (
        <>
            {activeTab ? (
                <div className="tab">
                    <InitialFeaturesTour
                        stepsEnabled={stepsEnabled}
                        setStepsEnabled={setStepsEnabled}
                        initialStep={initialStep}
                        setActiveTab={setActiveTab}
                    />
                    <button
                        id="MY_LATEST"
                        type="button"
                        className={`${activeTab === 'MY_LATEST' ? 'active' : ''}`}
                        onClick={() => setActiveTab('MY_LATEST')}
                    >
                        <DocumentIconSvg className="icon" />
                        <span className="name">{DASHBOARD.TABS.MY_LATEST}</span>
                        {latestDocuments > 0 && (
                            <span className="count">{`(${latestDocuments})`}</span>
                        )}
                    </button>
                    <button
                        type="button"
                        id="MY_APPROVALS"
                        className={activeTab === 'MY_APPROVALS' ? 'active' : ''}
                        onClick={() => setActiveTab('MY_APPROVALS')}
                    >
                        <MyApprovals className="icon tab-icons" />
                        <span className="name">{DASHBOARD.TABS.MY_APPROVALS}</span>
                        {myApprovals > 0 && <span className="count">{`(${myApprovals})`}</span>}
                    </button>
                    {shouldGroupsRender && (
                        <button
                            type="button"
                            id="MY_APPROVALS"
                            className={activeTab === 'MY_GROUP_APPROVALS' ? 'active' : ''}
                            onClick={() => setActiveTab('MY_GROUP_APPROVALS')}
                        >
                            <MyApprovals className="icon tab-icons" />
                            <span className="name">{DASHBOARD.TABS.MY_GROUP_APPROVALS}</span>
                            {myGroupApprovals > 0 && (
                                <span className="count">{`(${myGroupApprovals})`}</span>
                            )}
                        </button>
                    )}
                    <button
                        type="button"
                        id="MY_DOCUMENTS"
                        className={activeTab === 'MY_DOCUMENTS' ? 'active' : ''}
                        onClick={() => setActiveTab('MY_DOCUMENTS')}
                    >
                        <MyDocuments className="icon tab-icons" />
                        <span className="name">{DASHBOARD.TABS.MY_DOCUMENTS}</span>
                        {myDocuments > 0 && <span className="count">{`(${myDocuments})`}</span>}
                    </button>
                    <button
                        id="MY_FAVORITES"
                        type="button"
                        className={activeTab === 'MY_FAVORITES' ? 'active' : ''}
                        onClick={() => setActiveTab('MY_FAVORITES')}
                    >
                        <MyFavourites className="icon tab-icons" />
                        <span className="name">{DASHBOARD.TABS.MY_FAVORITES}</span>
                        {myFavorites > 0 && <span className="count">{`(${myFavorites})`}</span>}
                    </button>
                    <button
                        id="MY_REVIEWS"
                        type="button"
                        className={`reviewers ${activeTab === 'MY_REVIEWS' ? 'active' : ''}`}
                        onClick={() => setActiveTab('MY_REVIEWS')}
                    >
                        <DocumentIconSvg className="icon" />
                        <span className="name">{DASHBOARD.TABS.MY_REVIEWS}</span>
                        {myReviews > 0 && <span className="count">{`(${myReviews})`}</span>}
                    </button>
                </div>
            ) : (
                <div className="puff-loader">
                    <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
                </div>
            )}
        </>
    );
};

const DashboardPage: React.FC = () => (
    <div className="dashboard">
        <DashboardProvider>
            <DashboardTabs />
            <div className="section">
                <div className="doc-list">
                    <DashboardTable />
                </div>
            </div>
        </DashboardProvider>
    </div>
);

export default DashboardPage;
