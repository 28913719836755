/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React from 'react';

import './DocumentExport.scss';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import { NoPermission } from '../../components/General';
import PRBNomination from '../../components/docs-export/PRBNomination';

// eslint-disable-next-line max-lines-per-function
const DocumentExport: React.FC = () => {
    const {
        canViewDocumentDetails,
    } = useDocumentContext();
    
    return (
        <>
            {canViewDocumentDetails ? (
                <PRBNomination/>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const DocumentExportPage: React.FC = () => (
    <div className="document-details">
        <DocumentProvider>
            <DocumentExport />
        </DocumentProvider>
    </div>
);

export default DocumentExportPage;
