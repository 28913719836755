/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import './DmsGroup.scss';
import { toast } from 'react-toastify';
import DmsGroupTable from './DmsGroupsTable';
import CONSTANTS from '../../constants';
import { getGroups } from '../../API/groups';
import { IPaginationData } from '../../interfaces';
import { IGroupInfo } from '../../interfaces/groups';
import AddGroupModal from './GroupModal';
import { useRoleContext } from '../../contexts/group-provider';

interface IMyDMSGroup {
    name: string;
    alias: string;
    id: number;
}

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};
const defaultMyGroups = () => {
    const myGroups: IGroupInfo[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 9; i++) {
        myGroups.push({
            id: CONSTANTS.LOADING.NUMBER,
            alias: CONSTANTS.LOADING.TEXT,
            name: CONSTANTS.LOADING.TEXT,
        });
    }

    return myGroups;
};

const DmsGroup: React.FC = () => {
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [loadingState, setLoadingState] = useState<null>(null);
    const [myGroups, setMyGroups] = useState<IMyDMSGroup[]>(defaultMyGroups());
    const [showModal, setShowModal] = useState(false);
    const { resetInput } = useRoleContext();

    const update = async (pageIndex?: number, pageSize?: number, qp?: string) => {
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const queryParams = {
            limit: size.toString(),
            offset: (index * size).toString(),
        };
        const response = await getGroups(queryParams);
        if (response?.apiStatus === 'SUCCESS') {
            setMyGroups(response.myGroups);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: response.totalGroups,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406, {
                toastId: 'errorfetchGroups',
            });
        }
    };

    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout;
        return (...args: Params) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, timeout);
        };
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const size = pagination.pageSize;
        const index = pagination.pageIndex;
        const queryParams = {
            limit: size.toString(),
            offset: (index * size).toString(),
            qp: e.target.value,
        };
        const response = await getGroups(queryParams);
        if (response?.apiStatus === 'SUCCESS') {
            setMyGroups(response.myGroups);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: response.totalGroups,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    const optimised = useCallback(debounce(handleChange, 500), []);

    useEffect(() => {
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return (
        <div className="roles-settings">
            <div className="flex-box">
                <div className="search-wrapper">
                    <input
                        className="document-search__search-text doc-search"
                        name="search-text"
                        id="search-text"
                        placeholder="Search Group"
                        autoComplete="off"
                        onChange={optimised}
                    />
                </div>
                <button
                    type="button"
                    className="primary-btn"
                    onClick={() => {
                        resetInput();
                        setShowModal(true);
                    }}
                >
                    Create Group
                </button>
            </div>

            <DmsGroupTable
                data={myGroups}
                pagination={pagination}
                fetchGroups={(pageIndex, pageSize) => update(pageIndex, pageSize)}
                loadingState={loadingState}
            />
            {showModal && (
                <AddGroupModal isModalOpen={showModal} onModalClose={setShowModal} isEdit={false} />
            )}
        </div>
    );
};

export default DmsGroup;
