/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { PlusIconSvg, WarnIcon } from '../../assets/images';
import './CheckoutConfirmationModal.scss';
import { IVersionOwner } from '../../interfaces/user';

interface Props {
    isCheckoutModalOpen: boolean;
    onCheckoutModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    onCheckoutConfirm: () => void;
    checkInVersionOwner: IVersionOwner;
}

const CheckoutConfirmationModel: React.FC<Props> = ({
    isCheckoutModalOpen,
    onCheckoutModalClose,
    onCheckoutConfirm,
    checkInVersionOwner,
}) => (
    <Modal
        isOpen={isCheckoutModalOpen}
        onRequestClose={() => onCheckoutModalClose(false)}
        contentLabel="checkout confirmation modal"
        className="checkout__confirmation__modal"
    >
        <PlusIconSvg className="close-btn" onClick={() => onCheckoutModalClose(false)} />
        <div className="heading">
            <h1>Are you sure you want to checkout?</h1>
            <div className="check-out-info">
                <div className="warning">
                    <WarnIcon className="warn-icon" />
                    This document has been checked-out by:{' '}
                    {checkInVersionOwner?.employeeName}{' '}
                    <a
                        href={`mailto:${checkInVersionOwner?.email}`}
                        style={{ color: '#2079FF', textDecoration: 'none' }}
                    >
                        {checkInVersionOwner?.email}
                    </a>
                </div>




            </div>
        </div>
        <div className="buttons">
            <button type="button" onClick={() => onCheckoutModalClose(false)}>
                No
            </button>
            <button
                type="button"
                className="primary-btn"
                onClick={() => {
                    onCheckoutConfirm();
                    onCheckoutModalClose(false);
                }}
            >
                Yes
            </button>
        </div>
    </Modal>
);

export default CheckoutConfirmationModel;