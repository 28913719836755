/* eslint-disable react/no-danger */
/* eslint-disable max-lines-per-function */
import React from 'react';
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';
import './Notifications.scss';
import { INotifications } from '../../interfaces';
import { BellIconSvg, ClearSvg } from '../../assets/images';
import { formatDate } from '../../utils/date';

interface Props {
    notifications: INotifications[];
    handleClearFunction:(id: number) => void; 
}

const Notifications: React.FC<Props> = ({ notifications, handleClearFunction }) => (

<div className="notifications-list">
    <TransitionGroup className="notification-list-transition">
        {notifications.length > 0 &&
            notifications.map((notification) => (
            <CSSTransition
              key={notification.id}
              timeout={500}
              classNames="fade"
            >
                <div
                    key={notification.id}
                    className={`notification ${notification.isRead ? 'read' : 'new'}`}
                >
                    <div className="notification__icon">
                        <span>
                            <BellIconSvg />
                        </span>
                    </div>
                    <div className="notification__content">
                        <h4 dangerouslySetInnerHTML={{ __html: notification.message }} />
                        <p>{formatDate(notification.date)}</p>
                    </div>
                    <div className='notification__clear'>
                        <ClearSvg onClick={() => handleClearFunction(notification.id)} />
                    </div>
                </div>
                </CSSTransition>
               
            )
            )
            }
        {notifications.length === 0 && <div className="notification no">No Notifications</div>}
        </TransitionGroup>
    </div>);

export default Notifications;
