/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { UserIconSvg, EditSvg, DeleteSvg } from '../../assets/images';
import { useDocumentContext } from '../../contexts/document-provider';
import { IUserOptionValue } from '../../interfaces';
import { AddButton } from '../General';
import UserSearchModal from '../use-search-modal/userSearchModal';
import './Reviewers.scss';

interface Props {
    isEditable: boolean;
}

const Reviewers: React.FC<Props> = ({ isEditable }) => {
    const [showUserSearch, setShowUserSearch] = useState(false);
    const { reviewers, hideUsers, reviewersDispatch, setHideUsers } = useDocumentContext();
    const [modalData, setModalData] = useState({ index: -1, prevUserId: 0 });

    const userHandler = (selectedUsers: IUserOptionValue[], type: 'EDIT' | 'NEW') => {
        selectedUsers.forEach((selectedUser) => {
            if (type === 'EDIT') {
                reviewersDispatch({
                    type: 'update-user',
                    value: {
                        reviewerId: selectedUser.value as number,
                        reviewerName: selectedUser.label,
                        role: selectedUser.role,
                        roleId: selectedUser.roleId,
                        state: 'NEW',
                    },
                    index: modalData.index,
                });
                let hiddenUsers = hideUsers;
                if (modalData.prevUserId) {
                    hiddenUsers = hideUsers.filter((u) => u !== modalData.prevUserId);
                }
                hiddenUsers.push(selectedUser.value as number);
                setHideUsers(hiddenUsers);
            }

            if (type === 'NEW') {
                reviewersDispatch({
                    type: 'add-reviewer',
                    value: {
                        reviewerId: selectedUser.value as number,
                        reviewerName: selectedUser.label,
                        role: selectedUser.role,
                        roleId: selectedUser.roleId,
                        state: 'NEW',
                    },
                });
                hideUsers.push(selectedUser.value as number);
                setHideUsers(hideUsers);
            }
        });
        setShowUserSearch(false);
    };

    const onModalOpen = (index: number, prevUserId: number) => {
        setShowUserSearch(true);
        setModalData({ index, prevUserId });
    };

    const deleteHandler = (index: number, employeeId: number) => {
        reviewersDispatch({ type: 'delete', index });
        setHideUsers(hideUsers.filter((f) => f !== employeeId));
    };

    useEffect(() => {
        if (showUserSearch === false) {
            setModalData({ index: -1, prevUserId: 0 });
        }
    }, [showUserSearch]);

    return (
        <div className="reviewers-tab tab-data">
            {reviewers.length > 0 &&
                reviewers.map((reviewer, index) => (
                    <div className="reviewers-tab__item">
                        <div className={`icon-circle PARALLEL ${reviewer.state}`}>
                            <UserIconSvg className="hour-glass-icon" />
                            <span className="arrow-icon" />
                        </div>
                        <div className="info">
                            <h5>{reviewer.reviewerName}</h5>
                            <span>{reviewer.role}</span>
                            {isEditable && (
                                <div className="user-actions">
                                    <div className="btn">
                                        <EditSvg
                                            className="edit"
                                            onClick={() =>
                                                isEditable &&
                                                onModalOpen(index, reviewer.reviewerId)
                                            }
                                        />
                                    </div>
                                    <div className="btn">
                                        <DeleteSvg
                                            className="delete"
                                            onClick={() =>
                                                deleteHandler(index, reviewer.reviewerId)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            {isEditable && (
                <div
                    className="reviewers-tab__item add-reviewers"
                    onClick={() => onModalOpen(-1, 0)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => onModalOpen(-1, 0)}
                >
                    <div>
                        <AddButton />
                    </div>
                    <div> Add a Reviewer</div>
                </div>
            )}
            {showUserSearch && (
                <UserSearchModal
                    type={modalData.index === -1 ? 'NEW' : 'EDIT'}
                    hideUsers={hideUsers}
                    isModalOpen={showUserSearch}
                    onModalClose={setShowUserSearch}
                    userHandler={userHandler}
                />
            )}
        </div>
    );
};

export default Reviewers;
