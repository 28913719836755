import qs from 'qs';
import React, { useState } from 'react';
import getAllContracts from '../../API/contracts';
import CONSTANTS from '../../constants';
import { IPaginationData } from '../../interfaces';
import { IContractList } from '../../interfaces/contracts';
import ContractsDashboardTable from './ContractsDashboardTable';

// eslint-disable-next-line max-lines-per-function
const ListAllContracts: React.FC = () => {
    const defaultContracts = () => {
        const contractsList: IContractList[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            contractsList.push({
                contractId: CONSTANTS.LOADING.NUMBER,
                contractNumber: CONSTANTS.LOADING.TEXT,
                contractType: CONSTANTS.LOADING.TEXT,
                category: CONSTANTS.LOADING.TEXT,
                partyName: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                owner: CONSTANTS.LOADING.TEXT,
                status: 'NEW',
                updatedAt: CONSTANTS.LOADING.DATE,
            });
        }

        return contractsList;
    };
    const [data, setData] = useState(defaultContracts);
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 5,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    // eslint-disable-next-line max-lines-per-function
    const update = async (pageIndex?: number, pageSize?: number, order?: string, orderType?: string | undefined) => {
        setData(defaultContracts);
        const documentNumber: string | undefined = undefined;
        const OwnerIds: number | undefined = undefined;
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const sortBy = order || 'updatedAt';
        const sortOrder = orderType || 'DESC';
        const params = {
            ownerId: OwnerIds,
            documentNumber,
            limit: size,
            offset: index * size,
            sortBy,
            sortOrder,
            filters: '[]',
        };

        const contracts = await getAllContracts(qs.stringify(params));
        if (contracts?.apiStatus === 'SUCCESS') {
            setData(contracts.contractList);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: contracts.totalContracts,
            });
        }
    };

    return (
        <>
            <ContractsDashboardTable
                data={data}
                pagination={pagination}
                fetchDocuments={(
                    pageIndex: number, pageSize: number, order: string, orderType: string | undefined
                ) => update(pageIndex, pageSize, order, orderType)}
            />
        </>
    );
};
export default ListAllContracts;